import React,{ Component } from "react";
import './index.less';
import ReactEcharts from "echarts-for-react";
require('../../utils/macarons');

class pieChartEquipment extends Component{
  constructor(props) {
    super(props);
    let path = window.location.href;
    this.state={
        height:"",
        width:"",
        isInner:!!~path.indexOf("home"),
        options:{}
    };
  }
  state={
    height:"",
    width:"",
  };
  componentWillMount(){
    this.setState({"height":this.props.height,"width":this.props.width});
    this.getOption();
  }
  componentDidMount(){
      this.getOption();
  }

  componentWillReceiveProps(nextProps, nextState){

  }
  getOption(){
    let min = 768;
    let radius = ["50%","65%"];
    let max = 2400;
    let windowWidth = document.body.clientWidth;
    let size = parseInt(windowWidth);
    if(this.state.isInner){
      radius = ["30%","40%"];
    }
    if (windowWidth > max) {
      size = max;
    } else if (windowWidth < min) {
      size = min;
    }
    let textSize = (size / 96)*1.2;
    let subTextSize = (size / 96)*0.64;
    let lineTextSize = (size / 96)*0.6;
    textSize = textSize<10? 10:textSize;
    subTextSize = subTextSize<10? 10:subTextSize;
    lineTextSize = lineTextSize<10? 10:lineTextSize;
    let lineSize = (size / 96);
    this.state.options = {
      tooltip: {
        trigger: 'item',
        formatter: "{b}: {c} ({d}%)"
      },
      title: {
        text:this.props.all_count,
        subtext:"设备总数",
        left:'center',
        top:'40%',
        subtextStyle:{
          fontSize:subTextSize,
          align:'center',
          x:"center"
        },
        // padding:[24,0],
        textStyle:{
          color:'#fff',
          fontSize:textSize,
          align:'center'
        }
      },
      series: [
        {
          type:'pie',
          roseType : 'radius',
          radius: radius,
          hoverAnimation:false,
          avoidLabelOverlap: false,
          center: ['50%', '50%'],
          color:["#f6cd4e","#4ddbf7"],
          label: {
            color:["#f6cd4e","#4ddbf7"],
            normal: {
              formatter: '{b|{b}}\n{c|{c}}',
              rich: {
                b: {
                  fontSize: lineTextSize,
                  align: 'center',
                  padding: 4
                },
                hr: {
                  borderColor: '#12EABE',
                  width: '100%',
                  borderWidth: 2,
                  height: 0
                },
                d: {
                  fontSize: lineTextSize,
                  color: '#fff',
                  align: 'center',
                  padding: 4
                },
                c: {
                  fontSize: lineTextSize,
                  color: '#fff',
                  align: 'center',
                  padding: 4
                }
              }
            }
          },
          labelLine: {
            length:lineSize,
            length2:lineSize
          },
          data:[{value:this.props.offline_count,name:"设备离线"},{value:this.props.line_count,name:"设备在线"}],

        }
      ]
    };

  }
  render(){
    this.getOption();
    return (
      <ReactEcharts  option={this.state.options} style={{ width:this.state.width, height:this.state.height }}>
      </ReactEcharts>
    )
  }
}

export default pieChartEquipment;