import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Layout } from "antd";

import Header from './components/Header';
import NavLeft from './components/NavLeft';
import './style/common.less'
import $util from "@/utils/utils.js";

const {
    Content, Sider,
} = Layout;

class Admin extends Component {
    constructor(props) {
        super(props);
        let userName = $util.getCookie("name");
        this.state = {
            userName
        };
    }

    componentWillMount() {
        // const { userName } = this.state;
        // if (!userName) {
        //     this.props.history.push("/login")
        // }
    }
    // state = {
    //     collapsed: false,
    // };

    // onCollapse = (collapsed) => {
    //     console.log(collapsed);
    //     this.setState({ collapsed });
    // }

    render() {
        return (
            <Layout style={{ minHeight: '100vh' }}>
                <NavLeft  {...this.props} />
                <Layout>
                    <Header />
                    <Content style={{ margin: '0 16px', backgroundColor: "#FFFFFF", padding: 20, marginTop: 20 }}>
                        {this.props.children}
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

export default withRouter(Admin);