import React, { Component } from "react";
import { Row, Col } from "antd";
import './index.less';
import axios from '@/axios/index'
import $util from "@/utils/utils";
import { notification, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
const confirm = Modal.confirm;
class Header extends Component {
    constructor(props) {
        super(props);
        let userName = $util.getCookie("login_name");
        let is_public = $util.getCookie("is_public");
        let role = $util.getCookie("role_name");

        this.state = {
            userName,
            role,
            is_public
        }
    }
    componentWillMount() {

    }

    loginOut = () =>{
        let user = $util.getCookie("login_name")
        let mySelf = this;
        if(user=="江阴电信"){
            axios.ajaxGet({
                url: "/logout",
                data: {
                   
                }
            }, true).then((res) => {
               
                $util.delCookie("created_at");
                $util.delCookie("web_app_id");
                $util.delCookie("login_name");
                $util.delCookie("mobile_no");
                $util.delCookie("role_id");
                $util.delCookie("role_name");
                $util.delCookie("token");
                $util.delCookie("is_public");
                $util.delCookie("id");
                $util.delCookie("name");
                $util.delCookie("updated_at");
                $util.delCookie("web_user_id");
                $util.delCookie("permsession_module");
                $util.delCookie("permsession_module_name");
                $util.delCookie("status");
                $util.delCookie("status_name");
                notification["success"]({
                    message: '提示',
                    description: '退出成功！',
                });
                    window.location.href =  "/login?user=PTS";
 
                // mySelf.props.history.push("/login");
                
            }).catch((res) => {
                Modal.error({
                    title: "提示",
                    content: res.message
                })
            })
        }else{
            confirm({
                title: `是否要退出?`,
                onOk() {
                    axios.ajaxGet({
                        url: "/logout",
                        data: {
                           
                        }
                    }, true).then((res) => {
                       
                        $util.delCookie("created_at");
                        $util.delCookie("web_app_id");
                        $util.delCookie("login_name");
                        $util.delCookie("mobile_no");
                        $util.delCookie("role_id");
                        $util.delCookie("role_name");
                        $util.delCookie("token");
                        $util.delCookie("is_public");
                        $util.delCookie("id");
                        $util.delCookie("name");
                        $util.delCookie("updated_at");
                        $util.delCookie("web_user_id");
                        $util.delCookie("permsession_module");
                        $util.delCookie("permsession_module_name");
                        $util.delCookie("status");
                        $util.delCookie("status_name");
                        notification["success"]({
                            message: '提示',
                            description: '退出成功！',
                        });

                         window.location.href =  "/login";
                        
                        // mySelf.props.history.push("/login");
                        
                    }).catch((res) => {
                        Modal.error({
                            title: "提示",
                            content: res.message
                        })
                    })
                },
                onCancel() { },
                okText: '确认',
                cancelText: '取消',
            });
        }

    }

    render() {
        const { role,userName,is_public} = this.state;
        return (
            <div className="header">
                <Row className="header-top">
                    <Col span="24">
                        <span>欢迎{role}，{is_public==1?'public':userName}</span>
                        <a onClick={this.loginOut}>退出</a>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default withRouter(Header);