import React, { Component } from 'react';

import {
    Layout, Row, Col, Form, Input, Button,Modal
} from 'antd';
import axios from "@/axios";
import PropTypes from 'prop-types';

const { Content } = Layout;
const FormItem = Form.Item;

//烟感设备搜索条件
class SearchForm extends React.Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.getUserList(values);
            }
        });
    }

    render() {
        let { getFieldDecorator } = this.props.form;
        return (
            <Form layout="inline" onSubmit={this.handleSubmit}>
                <FormItem label="设备二维码" style={{width:"100%"}} >
                    {getFieldDecorator('device_qrcode', {
                        initialValue: ""
                    })(
                        <Input style={{ width: 300 }} maxLength="50" placeholder="" />
                    )}
                </FormItem>
                <FormItem label="烟感名称" style={{width:"100%"}}>
                    {getFieldDecorator('smoke_name', {
                        initialValue: ""
                    })(
                        <Input style={{ width: 300,marginLeft:13}} maxLength="50" placeholder="" />
                    )}
                </FormItem>
                <FormItem style={{ marginTop: 10, marginLeft: 10 }}>
                    <Button type="primary" htmlType="submit">添加</Button>
                </FormItem>
            </Form>
        )
    };
};

SearchForm.contextTypes = {
    store: PropTypes.object,
};
let SearchFormNotesComponent = Form.create()(SearchForm);

class Add extends Component {

    //组件挂在之后执行的生命周期函数
    componentDidMount() {
        //初始化搜索
        // this.getUserList();
    };

    //获取列表
    getUserList = (values) => {
        axios.ajaxPost({
            url: "/addSmokeDevice",
            data: values
        }, true).then((res) => {
            this.props.history.goBack()
        }).catch((res) => {
            Modal.error({
                title: "提示",
                content: res.message
            })
        })
    };

    render() {
        return (
            <Content>
                <Row className="margin-bottom-15">
                    <Button
                        style={{ float: "left", marginRight: 20 }}
                        onClick={() => this.props.history.goBack()}
                        type="primary"
                        icon="left"
                    />
                    <Col span={4}>
                        <h2 className="content-title"><strong>添加烟感设备</strong></h2>
                    </Col>
                </Row>
                <Row className="margin-bottom-15">
                    <Col span={24}>
                        <SearchFormNotesComponent
                            getUserList={this.getUserList}
                            wrappedComponentRef={(form) => this.SearchForm = form}
                        >
                        </SearchFormNotesComponent>
                    </Col>
                </Row>
            </Content>
        )
    }
}

Add.contextTypes = {
    store: PropTypes.object,
};

export default Add;