import React, { Component, Fragment } from "react";import './index.less';

class rank extends Component{
  constructor(props){
    super(props);
  }

  componentDidMount(){
    console.log(this.props)
  }
  renderDom(){

  }
  render(){
    return (
      <div className={"rank_main"}>
        <div className={"rank_title rank_row"}>
          <div>排名</div>
          <div>社区名称</div>
          <div>设备数</div>
          <div>插座数</div>
          <div>充电数</div>
          {/*<div>使用率</div>*/}
          {/* <div>收入</div> */}
        </div>
        {
          this.props.listData.map((item,i)=>{
            if(i===0 || i===1 || i===2){
              return <div className={`rank_number rank_row rank_num${i+1}`} key={i}>
                <img className="rank_bg_img"  src={require(`../../assets/map_number/no${i+1}.png`)} alt=""/>
                <div></div>
                <div>{item.communitiy_name}</div>
                <div>{item.evse_number}</div>
                <div>{item.guns_number}</div>
                <div>{item.times}</div>
                {/*<div>{item.utilization_rate}</div>*/}
                {/* <div>{item.total_sum}</div> */}
                </div>
            }else{
              return <div className="rank_number rank_row" key={i}>
                <div>{i+1}</div>
                <div>{item.communitiy_name}</div>
                <div>{item.evse_number}</div>
                <div>{item.guns_number}</div>
                <div>{item.times}</div>
                {/*<div>{item.utilization_rate}</div>*/}
                {/* <div>{item.total_sum}</div> */}
              </div>
            }
          })
        }
      </div>
    )
  }
}

export default rank;