import React, { Component } from "react";
import Map from '../../components/map'
import Histogram from '../../components/histogram'
//import LineChart from '../../components/lineChart'
import PieChart from '../../components/pieChart'
import PieChartEquipment from '../../components/pieChartEquipment'
import MainTitle from '../../components/main_title'
import MsgPush from '../../components/msgPush'
//import CommunityRank from '../../components/communityRank'
import GaodeMap from '../../components/gaodeMap'

import axios from "@/axios";
import $util from "@/utils/utils.js";

import './index.less';
import LineChart from '../../components/lineChartOver';
import LineChartWithDot from "../../components/lineChart_with_dot";
import Echo from 'laravel-echo'

window.io = require('socket.io-client');
window.Echo = new Echo({
    broadcaster: 'socket.io',
    host:'https://prism.laichon.com:6001',
    auth: { headers: { 'Authorization': 'bearer ' + $util.getCookie("token") } }
});
let isQp = false;
class intelligentCommunity extends Component {
    constructor(props) {
        super(props);
        let path = this.props.match.path;
        let isInner = ~path.indexOf("home") ? true : false;
        this.state = {
            showElem:true,
            isInner:isInner,
            leftChart:{
                width: "25rem",
                height: "29vh"
            },
            barChart:{
                width: "25rem",
                height: "27vh"
            },
            pirChart:{
                width: "12rem",
                height: "15.3vh"
            },
            commonitor:[],
            chargeShow:3,
            totalPower:[],
            totalIncome:[],
            incomexAxis:[],
            totalPowerShow:3,
            totalPowerxAxis:[],
            overloadData: [],
            overloadAxis: [],
            shotData: [],
            shotAxis: [],
            costData:[],
            costAxis:[],
            payPieData:[],
            payLegendData:["支付宝","微信","其他"],
            payColor:["#1570e4","#0ee97b","#f0ca26"],
            chargePieData:[],
            chargeLegendData:[],
            alertData:{},
            chargeColor:["#ffb4ca","#22bffc","#12d297","#c155f8","#9597f0"],
            homeCountsData:{
                total_active_num:0,//日活
                total_newly_num:0,//新增用户
                total_city_number:0,//城市
                total_gun_number:0,//充电桩数量
                total_online_sum:0,//移动总收入
                total_income:0,//当日收入
                total_power:0,//总用电量
                total_pre_power:0,//昨日电量
                total_charge_times:0,
                online_total_evse_number: 0,//在线桩口数
                offline_total_evse_number: 0,//离线桩数量
                total_evse_number: 0,//枪口数
            },
            equipmentCost:{
                "all_count":0,//设备总数
                "line_count":0,//在线总数
                "offline_count":0,//离线总数
            },
            userData:{
                frequency: 0,
                device_gun_number: 0,
                community_num: 0,
                order_sum: 0,
                frequency_unit: '次',
                device_gun_number_unit: '个',
                community_num_unit: '个',
                order_sum_unit: '个',
            },
            timeInterval:"",
            refresh:"",
            evseData:{},
            rootName:$util.getCookie("login_name") !=="null" ? $util.getCookie("login_name"): "管理员",
            smoke_info:{},
            smoke_list:[],
            smoke_list_history:[]
        };
        let width = window.innerWidth;
        if (!!~path.indexOf("home")) {
            this.state.leftChart.width = "23rem";
            this.state.leftChart.height = "26.16vh";
            this.state.gaodeMapHeight = "36rem";
            this.state.barChart.width = '24rem';
            this.state.barChart.height = '24vh';
            this.state.pirChart.width = '11.5rem';
            this.state.pirChart.height = '15.3vh';
            if (1750 < width && width < 2250) {
                this.state.leftChart.width = "20rem";
                this.state.leftChart.height = "26.16vh";
                this.state.barChart.width = '20rem';
                this.state.barChart.height = '24vh';
                this.state.pirChart.width = '10rem';
            }
            if (1300 < width && width < 1750) {
                this.state.leftChart.width = "18rem";
                this.state.leftChart.height = "26.16vh";
                this.state.gaodeMapHeight = "34rem";
                this.state.barChart.width = '18rem';
                this.state.pirChart.width = '8.5rem';
            }
            if (width === 1750) {
                this.state.leftChart.width = "18rem";
                this.state.leftChart.height = "26.16vh";
                this.state.gaodeMapHeight = "34rem";
                this.state.barChart.width = '17rem';
                this.state.pirChart.width = '8.5rem';
            }
            if (width <= 1300) {
                this.state.pirChart.width = '7.5rem';
                this.state.barChart.width = '16rem';
                this.state.leftChart.width = "16rem";
                this.state.leftChart.height = "26.16vh";
                this.state.gaodeMapHeight = "30rem";
            }
            this.state.domId = "intelligentCommunityInner";
            this.state.gaodeMapId = "mapInner";
        } else {
            this.state.gaodeMapHeight = "39rem";
            this.state.leftChart.width = "25rem";
            this.state.leftChart.height = "29vh";
            this.state.domId = "intelligentCommunity";
            this.state.gaodeMapId = "mapOuter";
        }
    }

    //放大全屏
    launchFullscreen = (path) => {
        let element = document.documentElement;
        try {
            if (element.requestFullscreen) {
                isQp = true;
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                isQp = true;
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                isQp = true;
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
                isQp = true;
                element.msRequestFullscreen();
            }
        }catch (e) {

        }
        this.props.history.push(path)
        this.close();
    }
    // 退出全屏
    exitFullscreen = (path) => {
        try {
            if (isQp && document.exitFullscreen) {
                isQp = false;
                document.exitFullscreen();
            } else if ( isQp && document.mozCancelFullScreen) {
                isQp = false;
                document.mozCancelFullScreen();
            } else if (isQp && document.webkitExitFullscreen) {
                isQp = false;
                document.webkitExitFullscreen();
            }
        }catch (e) {

        }
        this.props.history.push(path)
        this.close();
    }

    render() {
        const { isInner } = this.state;
        const { showElem,smoke_info,smoke_list } = this.state;
        return (
            <div className={"operationalData"}  id={this.state.domId}>
                <div className={"map_top"}>
                    <div className={"map_top_left"}>
                        <span style={{ "marginRight": "2rem" }}>账号名：{this.state.rootName}</span>
                        <span id="timeClock"></span>
                    </div>
                    <div className={"map_top_mid"}>运营数据监控系统</div>
                    <div className={"map_top_right"}>

                        <div onClick={() => this.handleRouterDetail('setMap')}>站点地图</div>
                        { //有home代表在后台里面，这里不显示 先退出全屏 进入后台按钮
                            !isInner && <div onClick={() => this.exitFullscreen("/home/intelligentCommunity")}>进入后台</div>
                        }
                        { //有home代表在后台里面，这里显示放大全屏
                            isInner && <div onClick={()=>this.launchFullscreen('/intelligentCommunity')}>全屏展示</div>
                        }
                    </div>
                </div>
                <div className={"map_body"}>
                    <div>
                        <div className={"map_body_left"}>
                            <div className={"left_chart_bg"} style={{"paddingTop":"1rem"}}>
                                <span className={"chart_title_gold"}>设备状态</span>
                                <PieChartEquipment width={this.state.leftChart.width} height={this.state.leftChart.height} line_count={this.state.equipmentCost.line_count} offline_count={this.state.equipmentCost.offline_count} all_count={this.state.equipmentCost.all_count}  />
                            </div>
                        </div>
                        <div className={"map_body_left"}>
                            <div className={"left_chart_bg"}>
                                <span className={"chart_title_green"}>充电曲线</span>
                                <LineChart width={this.state.leftChart.width} height={this.state.leftChart.height} listData={this.state.costData} costAxis={this.state.costAxis} />
                            </div>
                        </div>
                        <div className={"map_body_left"}>
                            <MsgPush />
                        </div>
                    </div>
                    <div className={"map_titles"}>
                        <div className={"title_container"}>
                            <MainTitle color1={"#9c8638"} color3={"rgb(255, 221, 101)"} title="充电频次" value={this.state.userData.frequency} unit={this.state.userData.frequency_unit} />
                            <MainTitle color1={"#3196bc"} color3={"rgb(72, 205, 255)"} title="充电插座" value={this.state.userData.device_gun_number} unit={this.state.userData.device_gun_number_unit} />
                            <MainTitle color1={"#c45cf3"} color3={"rgb(196,92,243)"} title="覆盖社区" value={this.state.userData.community_num} unit={this.state.userData.community_num_unit} />
                            <MainTitle color1={"#0665b1"} color3={"rgb(57, 167, 255)"} title="收入总额" value={this.state.userData.order_sum} unit={this.state.userData.order_sum_unit} />
                        </div>
                        <div style={{display: showElem ? 'block' : 'none'}}>
                            <div className={"content flex_box"}>
                                <div className={"pop"} style={{display: smoke_info.status===2 ? 'block' : 'none'}}>
                                    <img className={"image_close"} onClick={() => this.closeWarning()} src={[require("../../assets/close.png")]} alt=""/>
                                    <p style={{"fontWeight":"bolder"}}>警报中</p>
                                    <p>系统检测到烟雾，请及时现场查看</p>
                                    <p className={"bor_bottom"}></p>
                                    <p>{smoke_info.address}</p>
                                    <p>{smoke_info.evse_no}</p>
                                </div>
                                <div className={"flex_box record bottom"} id="review_box" style={{display: smoke_info.status===2 ? 'block' : 'none'}}>
                                    <ul id="comment1" className={"review_box_ul"}>
                                        {
                                            this.state.smoke_list.map((item, index) => {
                                                return <li key = {index + item} className={item.status==2?"red":''}>警报： {item.address} 烟雾报警 {item.created_at} {item.status==2?'警报':'已解除'}</li>
                                            })
                                        }
                                    </ul>
                                    <ul id="comment2" className={"review_box_ul"}></ul>
                                </div>
                                {/* 历史报警显示固定显示 */}
                                <div className={"flex_box record font_weight"} id="review_box2" style={{display:this.state.smoke_list_history.length>0?'block':'none'}}>
                                    <ul id="comment3" className={"review_box_ul"}>
                                        {
                                            this.state.smoke_list_history.map((item, index) => {
                                                return <li key = {index + item} className={item.status==2?"red":''}>警报： {item.address} {item.smoke_name} {item.times}次 {item.updated_at.substring(0,10)} {item.status==2?'警报':'已处理'}</li>
                                            })
                                        }
                                    </ul>
                                    <ul id="comment4" className={"review_box_ul"}></ul>
                                </div>
                            </div>
                        </div>
                        <div id={this.state.gaodeMapId}>
                            <GaodeMap alertData={this.state.alertData}  height={this.state.gaodeMapHeight}  evseData={this.state.evseData} ref="myMap"/>
                        </div>
                    </div>
                    <div>
                        <div className={"map_body_right"}>
                            <div className={"left_chart_bg"}>
                                <span className={"chart_title_green"}>充电过载</span>
                                <LineChart width={this.state.leftChart.width} height={this.state.leftChart.height} listData={this.state.overloadData} costAxis={this.state.overloadAxis} />
                            </div>
                        </div>
                        <div className={"map_body_right"}>
                            <div className={"left_chart_bg"}>
                                <span className={"chart_title_purple"}>充电短路</span>
                                <LineChartWithDot width={this.state.leftChart.width} height={this.state.leftChart.height} listData={this.state.shotData} shotAxis={this.state.shotAxis} />
                            </div>
                        </div>

                        <div className={"map_body_right"}>
                            <div className={"histogram_chart_bg_small"}>
                                <span className={"chart_title_violet_right"}>营收分析</span>
                                <div className={"bar_chart_btn"}>
                                    <div onClick={this.chargeIndex.bind(this, 1)} className={this.state.chargeShow === 1 ? "chart_btn_active" : ""}>月收入</div>
                                    <div onClick={this.chargeIndex.bind(this, 2)} className={this.state.chargeShow === 2 ? "chart_btn_active" : ""}>周收入</div>
                                    <div onClick={this.chargeIndex.bind(this, 3)} className={this.state.chargeShow === 3 ? "chart_btn_active" : ""}>日收入</div>
                                </div>
                                <Histogram width={this.state.barChart.width} height={this.state.barChart.height} chargeData={this.state.totalIncome} chargeShow={this.state.chargeShow} incomexAxis={this.state.incomexAxis} />
                            </div>
                        </div>

                    </div>
                </div>
                <audio className={"audio_warn"} loop>
                    <source src={require("../../assets/audio/alert.mp3")} type="audio/ogg" />
                    <track kind="captions" />
                    您的浏览器不支持 audio 元素。
                </audio>
                <iframe className={"iframe"} style={{display:"none"}} src={require("../../assets/audio/null.mp3")}></iframe>
            </div>
        )
    }


    componentDidMount() {
        document.addEventListener("fullscreenchange", this.fullscreenchange, false);
//moz下监听 mozfullscreenchange 事件，判断document.mozFullScreen属性
        document.addEventListener("mozfullscreenchange", this.mozfullscreenchange, false);
//chrome下监听webkitfullscreenchange事件，判断 document.webkitIsFullScreen 属性
        document.addEventListener("webkitfullscreenchange", this.webkitfullscreenchange, false);
        let ua = navigator.userAgent;
        if(/firefox/i.test(ua)){
            this.mozfullscreenchange();
        }else if(/chrome/i.test(ua)){
            this.webkitfullscreenchange();
        }else{
            this.fullscreenchange();
        }
        //小区行数据

        //地图
        this.getEvseList();
        //头部数据
        this.getMainCost('day');
        //充电曲线
        this.getChargeCurve();
        //充电过载
        this.getOverloadCurve();
        //充电短路
        this.getShortCurve();

        this.getWarningInfo();
        //报警已处理列表
        this.getWarningCostList();


        this.setState({
            "timeInterval": setInterval(() => {
                this.showTime();
            }, 1000)
        })

        this.setState({
            "refresh": setInterval(() => {
                let i = this.state.chargeShow;
                if (i === 1) {//月
                    this.getMainCost('month');
                } else if (i === 2) {//周
                    this.getMainCost('week');
                } else if (i === 3) {//日
                    this.getMainCost('day');
                }
            }, 600000)
        })

        this.setState({
            "refresh": setInterval(() => {
                this.getEvseList()
            }, 300000)
        })
        window.Echo.private('prism.'+$util.getCookie("web_app_id"))
            .listen('ChargeCurveEvent', (e) => {
                //console.log(e)
                let temp = e.object;
                let costData = [];
                let costAxis = [];
                temp.forEach((item) => {
                    if(item.hour == 24){
                        costAxis.push("0h");
                    }else{
                        costAxis.push(item.hour + "h");
                    }
                    costData.push(item.charge_num)
                });
                costAxis.unshift(costAxis[23]);
                costAxis.pop();
                costData.unshift(costData[23]);
                costData.pop();
                this.setState({ "costAxis": costAxis });
                this.setState({ "costData": costData });
            }).listen('OverloadCurveEvent', (e) => {
                //console.log(e)
                let temp = e.object;
                let overloadData = [];
                let overloadAxis = [];
                temp.forEach((item) => {
                    if(item.hour == 24){
                        overloadAxis.push("0h");
                    }else{
                        overloadAxis.push(item.hour + "h");
                    }
                    overloadData.push(item.overload_num)
                });
                overloadAxis.unshift(overloadAxis[23]);
                overloadAxis.pop();
                overloadData.unshift(overloadData[23]);
                overloadData.pop();
                this.setState({ "overloadData": overloadData });
                this.setState({ "overloadAxis": overloadAxis });
            }).listen('ShortCurveEvent', (e) => {
                //console.log(e)
                let temp = e.object;
                let shotData = [];
                let shotAxis = [];
                temp.forEach((item) => {
                    if(item.hour == 24){
                        shotAxis.push("0h");
                    }else{
                        shotAxis.push(item.hour + "h");
                    }
                    shotData.push(item.shot_num)
                });
                shotAxis.unshift(shotAxis[23]);
                shotAxis.pop();
                shotData.unshift(shotData[23]);
                shotData.pop();
                this.setState({ "shotData": shotData, "shotAxis": shotAxis });
            }).listen('WarningEvent', (e) => {
                let data  = e.object;
                this.setState({ "smoke_info": data });
                let audio = document.getElementsByClassName('audio_warn')[0];
                //调用方法
                if(data.status==2 && audio !=undefined){
                    if(audio.paused){
                        audio.load();
                    }
                    audio.play();
                }

            }).listen('WarningListEvent', (e) => {
                //console.log(e)
                let data  = e.object;
                this.setState({ "smoke_list": data });
            })

    }
    // 警报弹窗
    roll(t) {
        var ul1 = document.getElementById("comment1");
        var ul2 = document.getElementById("comment2");
        var ul3 = document.getElementById("comment3");
        var ul4 = document.getElementById("comment4");
        var ulbox = document.getElementById("review_box");
        ul2.innerHTML = ul1.innerHTML;
        ul4.innerHTML = ul3.innerHTML;
        ulbox.scrollTop = 0; // 开始无滚动时设为0
        //console.log(this.timer)
        this.timer = setInterval(this.rollStart, t); // 设置定时器，参数t用在这为间隔时间（单位毫秒），参数t越小，滚动速度越快
    }
    close(){
        clearInterval(this.timer);
    }
    closeWarning(){
        let audio = document.getElementsByClassName('audio_warn')[0];
        if(audio != undefined && audio.played){
            audio.pause();
        }
        this.setState({ "smoke_info": {} });
        this.getWarningCostList();
    }
       // 开始滚动函数
    rollStart() {
         // 上面声明的DOM对象为局部对象需要再次声明
         var ul1 = document.getElementById("comment1");
         var ul2 = document.getElementById("comment3");
         var ulbox = document.getElementById("review_box");
         var ulbox2 = document.getElementById("review_box2");
         // 正常滚动不断给scrollTop的值+1,当滚动高度大于列表内容高度时恢复为0
         if (ulbox !== null && ul1 !== null && ulbox2 !== null && ul2 !== null) {
             if (ulbox.scrollTop >= ul1.scrollHeight) {
              ulbox.scrollTop = 0;
             } else {
              ulbox.scrollTop++;
             }
             if (ulbox2.scrollTop >= ul2.scrollHeight) {
              ulbox2.scrollTop = 0;
             } else {
              ulbox2.scrollTop++;
             }
         }
    }
    //营收分析
    chargeIndex(i) {
        this.setState({ "chargeShow": i });

        if (i === 1) {//月
            this.getMainCost('month');
        } else if (i === 2) {//周
            this.getMainCost('week');
        } else if (i === 3) {//日
            this.getMainCost('day');
        }

    }
    fullscreenchange(){
        isQp = (document.fullscreen);
    }
    mozfullscreenchange(){
        isQp = (document.mozFullScreen);
    }
    webkitfullscreenchange(){
        isQp = (document.webkitIsFullScreen);
    }

    //获取地图桩列表信息
    getEvseList() {
        axios.ajaxGet({
            url: "/getEvseList"
        }).then((res) => {
            if (res.code == 200) {
                this.setState({ "evseData": res.data });
                if(this.refs.myMap){
                    this.refs.myMap.clusterMarker();
                }

            }
        }, true)
    }
    getMainCost(date_type){

        axios.ajaxPost({
            url: "/getMainCost",
            data: {
                date_type: date_type
            }
        }).then((res) => {

            if (res.error_code == 0) {

                let data = res.data;
                let result = data.result.lists;

                //设备数
                let equipmentCost = {};
                equipmentCost.all_count = data.device_num;
                equipmentCost.line_count = data.online_conut_number;
                equipmentCost.offline_count = data.offline_count_number;
                this.setState({ "equipmentCost": equipmentCost });

                //头部信息
                let userData = {};
                //充电频次
                if(Number(data.frequency) >= 10000){
                    userData.frequency = parseFloat(data.frequency/10000).toFixed(2);
                    userData.frequency_unit = "万";
                }else{
                    userData.frequency = parseFloat(data.frequency).toFixed(0);
                    userData.frequency_unit = "次";
                }
                //插口数
                if(Number(data.device_gun_number) >= 10000){
                    userData.device_gun_number = parseFloat(data.device_gun_number/10000).toFixed(2);
                    userData.device_gun_number_unit = "万";
                }else{
                    userData.device_gun_number = parseFloat(data.device_gun_number).toFixed(0);
                    userData.device_gun_number_unit = "个";
                }
                //覆盖社区
                if(Number(data.community_num) >= 10000){
                    userData.community_num = parseFloat(data.community_num/10000).toFixed(2);
                    userData.community_num_unit = "万";
                }else{
                    userData.community_num = parseFloat(data.community_num).toFixed(0);
                    userData.community_num_unit = "个";
                }
                //收入总额
                if(Number(data.order_sum) >= 10000){
                    userData.order_sum = parseFloat(data.order_sum/10000).toFixed(2);
                    userData.order_sum_unit = "万";
                }else{
                    userData.order_sum = parseFloat(data.order_sum).toFixed(2);
                    userData.order_sum_unit = "元";
                }

                this.setState({ "userData": userData })

                //营收分析
                let totalIncome = [];
                let xAxis = [];
                result.forEach((item,index) => {
                    totalIncome.push(parseFloat(item).toFixed(2));
                    if (date_type === 'day') {
                        xAxis.push(index+1);
                    }
                });

                this.setState({ "incomexAxis": xAxis });
                this.setState({ "totalIncome": totalIncome });
            }
        }, true)
    }

    getWarningInfo(){
        axios.ajaxGet({
            url: "/getWarningInfo"
        }).then((res) => {
            //console.log(res.data)
            if (res.code == 200) {
                this.roll(200);
                this.setState({ "smoke_list": res.data });
            }
        }, true)
    }
    //烟感报警已处理列表
    getWarningCostList(){

        var user_id = $util.getCookie("web_app_id")
        //console.log(user_id)
        axios.ajaxPost({
            url: "/getWarningCostList",
            data: {
                user_id: user_id
            }
        }, true).then((res) => {
            if(res.code == 200){
                this.setState((state) => {
                    return { smoke_list_history: state.smoke_list_history.concat(res.data)};
                });
            }
        })
    }
    //充电曲线
    getChargeCurve() {
        axios.ajaxGet({
            url: "/getChargeCurve"
        }).then((res) => {
            //console.log(res)
            if (res.code == 200) {

                let costData = [];
                let costAxis = [];

                res.data.forEach((item) => {
                    if(item.hour == 24){
                        costAxis.push("0h");
                    }else{
                        costAxis.push(item.hour + "h");
                    }
                    costData.push(item.charge_num)
                });
                costAxis.unshift(costAxis[23]);
                costAxis.pop();
                costData.unshift(costData[23]);
                costData.pop();

                this.setState({ "costAxis": costAxis });
                this.setState({ "costData": costData });
            }
        }, true)
    }
    //充电过载
    getOverloadCurve() {
        //过载
        axios.ajaxGet({
            url: "/getOverloadCurve",
        }).then((res) => {
            if (res.code == 200) {
                let overloadData = [];
                let overloadAxis = [];
                res.data.forEach((item) => {
                    if(item.hour == 24){
                        overloadAxis.push("0h");
                    }else{
                        overloadAxis.push(item.hour + "h");
                    }
                    overloadData.push(item.overload_num)
                });
                overloadAxis.unshift(overloadAxis[23]);
                overloadAxis.pop();
                overloadData.unshift(overloadData[23]);
                overloadData.pop();
                this.setState({ "overloadData": overloadData });
                this.setState({ "overloadAxis": overloadAxis });
            }
        }, true).catch((err) => {
            console.log(err);
        });
    }
    //充电短路
    getShortCurve() {
        //短路
        axios.ajaxGet({
            url: "/getShortCurve",
        }).then((res) => {
            if (res.code == 200) {
                let shotData = [];
                let shotAxis = [];
                res.data.forEach((item) => {
                    if(item.hour == 24){
                        shotAxis.push("0h");
                    }else{
                        shotAxis.push(item.hour + "h");
                    }
                    shotData.push(item.shot_num)
                });
                shotAxis.unshift(shotAxis[23]);
                shotAxis.pop();
                shotData.unshift(shotData[23]);
                shotData.pop();
                this.setState({ "shotData": shotData, "shotAxis": shotAxis });
            }
        }, true).catch((err) => {
            console.log(err);
        });
    }


    handleRouterDetail = (path) => {
        const { isInner } = this.state;
        if(isInner){
            this.props.history.push({
                pathname: `/home/${path}`
            });
        }else{
            this.props.history.push({
                pathname: `/${path}`
            });
        }
        this.close();
    };

    showTime() {
        //创建Date对象
        var today = new Date();
        var year = today.getFullYear();
        var month = today.getMonth() + 1;
        var day = today.getDate();
        var hours = today.getHours();
        var minutes = today.getMinutes();
        var seconds = today.getSeconds();
        month = month < 10 ? "0" + month : month;
        day = day < 10 ? "0" + day : day;
        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        var str = hours + ":" + minutes + ":" + seconds + "   " + year + "-" + month + "-" + day;
        document.getElementById("timeClock").innerText = str;
    }

    componentWillUnmount() {
        clearInterval(this.state.timeInterval);
        clearInterval(this.state.refresh);
        document.removeEventListener("fullscreenchange", this.fullscreenchange, false);
//moz下监听 mozfullscreenchange 事件，判断document.mozFullScreen属性
        document.removeEventListener("mozfullscreenchange", this.mozfullscreenchange, false);
//chrome下监听webkitfullscreenchange事件，判断 document.webkitIsFullScreen 属性
        document.removeEventListener("webkitfullscreenchange", this.webkitfullscreenchange, false);
    }
}

export default intelligentCommunity;