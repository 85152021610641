import React,{ Component } from "react";
import './index.less';

class rank extends Component{
  state={
  };
  componentWillMount(){
  }
  componentDidMount(){
  }
  render(){
    return (
      <div className={"bottom_warn"}>
        <div className={"bottom_warn_container"}>
          <div className={"bottom_warn_title"}>充电过载报警</div>
          <div className={"bottom_warn_state"}>
            <div><span className={"warn_dot_green"}></span>正常</div>
            <div><span className={"warn_dot_gray"}></span>离线</div>
            <div><span className={"warn_dot_red"}></span>报警</div>
          </div>
        </div>
        <div className={"bottom_warn_container"}>
          <div className={"bottom_warn_title"}>主线路过载报警</div>
          <div className={"bottom_warn_state"}>
            <div><span className={"warn_border_green"}></span>正常</div>
            <div><span className={"warn_border_gray"}></span>离线</div>
            <div><span className={"warn_border_red"}></span>报警</div>
          </div>
        </div>
        <div className={"bottom_warn_container"}>
          <div className={"bottom_warn_title"}>充电短路报警</div>
          <div className={"bottom_warn_state"}>
            <div><span className={"warn_gradient_orange"}></span></div>
          </div>
        </div>
        <div className={"bottom_warn_container"}>
          <div className={"bottom_warn_title"}>烟感报警</div>
          <div className={"bottom_warn_state"}>
            <div><span className={"warn_gradient_yellow"}></span></div>
          </div>
        </div>
      </div>
    )
  }
}

export default rank;