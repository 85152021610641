import React, { Component } from 'react';

import {
    Layout, Divider, Row, Col, Form,
    notification, Table, Input, Button,
    DatePicker, Select, Modal
} from 'antd';
import { withRouter } from 'react-router-dom';
import axios from "@/axios";
import $pars from "@/pars/pars.js";
import $util from "@/utils/utils.js";
import moment from 'moment';
import PropTypes from 'prop-types';
const confirm = Modal.confirm;

const { Content } = Layout;
const Option = Select.Option;
const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

//烟感设备搜索条件
class SearchForm extends React.Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.getUserList(values.login_name);
            }
        });
    }

    render() {
        let { getFieldDecorator } = this.props.form;
        return (
            <Form layout="inline" onSubmit={this.handleSubmit}>
                <FormItem label="根据二维码" >
                    {getFieldDecorator('login_name', {
                        initialValue: ""
                    })(
                        <Input style={{ width: 300 }} maxLength="50" placeholder="" />
                    )}
                </FormItem>
                <FormItem style={{ float: "right" }}>
                    <Button type="primary" htmlType="submit">搜索</Button>
                </FormItem>
            </Form>
        )
    };
};

SearchForm.contextTypes = {
    store: PropTypes.object,
};
let SearchFormNotesComponent = Form.create()(SearchForm);

class Device extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [

            ],
            pagination: {
                limit: 10,
                page_now: 1,
                total: 0,
            },

        };
    };

    //组件挂在之后执行的生命周期函数
    componentDidMount() {
        //初始化搜索
        this.getUserList();
    };

    handleTableChange = (pagination, filters, sorter) => {
        let { current, pageSize } = pagination;
        let paginations = this.state.pagination;
        paginations.page_now = current;
        paginations.limit = pageSize;
        this.setState({
            paginations
        }, () => {
            this.getUserList();
        })
    };

    //获取列表
    getUserList = (values) => {
        let pagination = this.state.pagination;
        axios.ajaxPost({
            url: "/smokeDeviceList?page=" + pagination.page_now,
            data: {
                limit: pagination.limit,
                search_by:{
                    simple_sn: values ? values : ""
                }
            }
        }, true).then((res) => {
            let pagination = this.state.pagination;
            let { current_page, total } = res.meta;
            pagination.Page = current_page;
            pagination.total = total;

            this.setState({
                data: res.data,
                pagination
            })

        }).catch((res) => {
            Modal.error({
                title: "提示",
                content: res.message
            })
        })
    };

    getColumns = () => {
        let columns = [{
            title: '设备二维码',
            dataIndex: "device_qrcode",

        }, {
            title: '物联网卡卡号',
            dataIndex: 'sim_sn',
        }, {
            title: '烟感名称',
            dataIndex: 'smoke_name',
        }, {
            title: '设备型号全称',
            dataIndex: 'model_name',
        }, {
            title: '烟感设备状态',
            dataIndex: "device_status_name",
        }, {
            title: '操作',
            width: '170px',
            render: (text, record) => (
                <span>
                    <a onClick={() => this.handleRouterDetail(record, 'bindDevice',"bind")}>绑定</a>
                    <Divider type="vertical" />
                    <a onClick={() => this.handleRouterDetail(record, "bindDevice","unbind")}>解绑</a>
                    <Divider type="vertical" />
                    <a onClick={() => this.handleRouterAdd(record,'edit')}>编辑</a>
                    <Divider type="vertical"  style={{display: record.device_status == 2 ? '' : 'none'}}/>
                    <a style={{display: record.device_status == 2 ? '' : 'none'}} onClick={() => this.changeStatus(record)}>静音</a>
                </span>
            )
        }];
        return columns;
    };

    //跳转详情页
    handleRouterDetail = (record, path,type) => {
        record.type = type;
        this.props.history.push({
            pathname: `/device/${path}`,
            state: record,
        });
    };
    //静音操作
    changeStatus = (record) => {
        axios.ajaxPost({
            url: "/stopSmokeWarning",
            data:{
                device_qrcode: record.device_qrcode
            }
        }, true).then((res) => {
            if(res.code ==200){
                window.location.reload();
            }

        }).catch((res) => {
            Modal.error({
                title: "提示",
                content: "已处理"
            })
        })
    };
    //跳转添加页
    handleRouterAdd = (record, path) => {
        this.props.history.push({
            pathname: `/device/${path}`,
            state: record,
        });
    };

    render() {
        let { data, pagination } = this.state;
        const columns = this.getColumns();
        return (
            <Content>
                <Row className="margin-bottom-15">
                    <Col span={4}>
                        <h2 className="content-title"><strong>烟感设备</strong></h2>
                    </Col>
                    <Col span={4} className="add_btn">
                        <h3 className="content-title" onClick={() => this.handleRouterAdd("record",'add')}><strong>+添加烟感设备</strong></h3>
                    </Col>
                </Row>
                <Row className="margin-bottom-15">
                    <Col span={24}>
                        <SearchFormNotesComponent
                            getUserList={this.getUserList}
                            wrappedComponentRef={(form) => this.SearchForm = form}
                        >
                        </SearchFormNotesComponent>
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                    <Col span={24}>
                        <Table
                            rowKey={(record, index) => index}
                            columns={columns}
                            dataSource={data}
                            bordered
                            pagination={{
                                total: parseInt(pagination.total, 0),
                                current: parseInt(pagination.page_now, 0),
                                pageSize: parseInt(pagination.limit, 0),

                                pageSizeOptions: ['5', '10', '20', '50', '100'],
                                // onShowSizeChange: this.onPageChange,
                                // onChange:this.onPageChange,
                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
                                // showSizeChanger: true,
                                showQuickJumper: true,
                            }}
                            onChange={this.handleTableChange}
                        />
                    </Col>
                </Row>
            </Content>
        )
    }
}

Device.contextTypes = {
    store: PropTypes.object,
};

export default Device;