import React, { Component } from "react";
import "./index.less";
import axios from "@/axios";
import $util from "@/utils/utils.js";
import Echo from "laravel-echo";

window.io = require("socket.io-client");
window.Echo = new Echo({
  broadcaster: "socket.io",
  host: "https://prism.laichon.com:6001",
  auth: { headers: { Authorization: "bearer " + $util.getCookie("token") } },
});

class msgPush extends Component {
  state = {
    orderInfo: [],
    timeInterval: null,
  };
  componentWillMount() {
    this.getOrderInfo();
  }
  componentWillUnmount() {
    // clearInterval(this.state.timeInterval)
  }
  async componentDidMount() {
    // let that = this
    // if(this.state.orderInfo){
    //     window.setInterval(()=>{
    //         this.getOrderInfo()
    //     },10000);
    // }else{

    // }

    window.Echo.private("prism." + $util.getCookie("web_app_id")).listen(
      "OrderEvent",
      (e) => {
        let data = e.object;
        let temp = [];
        for (let i in data) {
          let pay_type = Number(data[i].pay_type);
          let pay_type_name = "";
          switch (pay_type) {
            case 1:
              pay_type_name = "支付宝";
              break;
            case 2:
              pay_type_name = "微信";
              break;
            case 3:
              pay_type_name = "余额";
              break;
            case 4:
              pay_type_name = "充电券";
              break;
            case 5:
              pay_type_name = "微信小程序";
              break;
            case 6:
              pay_type_name = "支付宝小程序";
              break;
            case 7:
              pay_type_name = "虚拟卡";
              break;
            case 8:
              pay_type_name = "五元出行";
              break;
            case 9:
              pay_type_name = "自动充满";
              break;
            case 10:
              pay_type_name = "在线卡";
              break;
            default:
              break;
          }
          let info =
            data[i].community_name +
            "用户通过" +
            pay_type_name +
            "支付一笔" +
            data[i].order_sum +
            "元的充电订单";
          if (data[i].pay_type == 9) {
            info =
              data[i].community_name +
              "用户通过" +
              pay_type_name +
              "支付一笔充电订单";
          }

          temp.push(info);
        }

        this.setState({ orderInfo: temp });
      }
    );
  }

  getOrderInfo() {
    axios
      .ajaxGet({
        url: "/getOrderList",
      })
      .then((res) => {
        if (res.code == 200) {
          let temp = [];
          if (res.data.length > 6) {
            for (let i = 0; i < 6; i++) {
              let pay_type = Number(res.data[i].pay_type);
              let pay_type_name = "";
              switch (pay_type) {
                case 1:
                  pay_type_name = "支付宝";
                  break;
                case 2:
                  pay_type_name = "微信";
                  break;
                case 3:
                  pay_type_name = "余额";
                  break;
                case 4:
                  pay_type_name = "充电券";
                  break;
                case 5:
                  pay_type_name = "微信小程序";
                  break;
                case 6:
                  pay_type_name = "支付宝小程序";
                  break;
                case 7:
                  pay_type_name = "虚拟卡";
                  break;
                case 8:
                  pay_type_name = "五元出行";
                  break;
                case 9:
                  pay_type_name = "自动充满";
                  break;
                case 10:
                  pay_type_name = "在线卡";
                  break;
                default:
                  break;
              }

              let info =
                res.data[i].community_name +
                "用户通过" +
                pay_type_name +
                "支付一笔" +
                res.data[i].order_sum +
                "元的充电订单";
              if (res.data[i].pay_type == 9) {
                info =
                  res.data[i].community_name +
                  "用户通过" +
                  pay_type_name +
                  "支付一笔充电订单";
              }

              temp.push(info);
            }
          } else {
            for (let i = 0; i < res.data.length; i++) {
              let pay_type = Number(res.data[i].pay_type);
              let pay_type_name = "";
              switch (pay_type) {
                case 1:
                  pay_type_name = "支付宝";
                  break;
                case 2:
                  pay_type_name = "微信";
                  break;
                case 3:
                  pay_type_name = "余额";
                  break;
                case 4:
                  pay_type_name = "充电券";
                  break;
                case 5:
                  pay_type_name = "微信小程序";
                  break;
                case 6:
                  pay_type_name = "支付宝小程序";
                  break;
                case 7:
                  pay_type_name = "虚拟卡";
                  break;
                case 8:
                  pay_type_name = "五元出行";
                  break;
                case 9:
                  pay_type_name = "自动充满";
                  break;
                case 10:
                  pay_type_name = "在线卡";
                  break;
                default:
                  break;
              }

              let info =
                res.data[i].community_name +
                "用户通过" +
                pay_type_name +
                "支付一笔" +
                res.data[i].order_sum +
                "元的充电订单";
              if (res.data[i].pay_type == 9) {
                info =
                  res.data[i].community_name +
                  "用户通过" +
                  pay_type_name +
                  "支付一笔充电订单";
              }

              temp.push(info);
            }
          }
          this.setState({ orderInfo: temp });
        }
      }, true)
      .catch(function(err) {
        console.log(err);
      });
  }
  render() {
    return (
      <div className={"msgPush_main"}>
        {this.state.orderInfo.map((value, index) => {
          return (
            <div key={index}>
              <img src={require("../../assets/phone.png")} alt="" />
              {value}
            </div>
          );
        })}
      </div>
    );
  }
}

export default msgPush;
