import qs from 'qs'
import axios from 'axios'
import { Modal } from 'antd';
import $util from '@/utils/utils.js';

axios.defaults.baseURL = "/api"; //设置路径
axios.defaults.withCredentials = true;//设置跨域
let token = $util.getCookie("token");
/**
 * 请求拦截器，请求发送之前做些事情
 */
axios.interceptors.request.use(
  config => {
    config.method === 'post'
      ? config.data = { ...config.data }
      : config.params = { ...config.params };
    config.headers['Content-Type'] = 'application/json';

    return config;
  },
  error => {
    console.error(error);
    return Promise.reject(error)
  }
);
/**
 * 响应拦截器，请求返回异常统一处理
 */
axios.interceptors.response.use(
  response => {
    (process.env.NODE_ENV === "development") && (console.log(response));
    return response
  },
  error => {
    console.log(error)
    switch (error.response.status) {
      case 403:
        break;
      case 404:
        break;
      case 500:
        break;
      case 501:
        break;
      default:
        break;
    }
    return Promise.reject(error)
  }
);
class Axios {
  static ajaxGet(options, isHeader = true) {
    return new Promise((resolve, reject) => {
      axios({
        url: options.url,
        method: 'get',
        timeout: 30000,
        data: (options.data && options.data.params) || '',
        headers: isHeader ? { "Authorization": "bearer " + token } : null
      }).then((response) => {

        if (response.status == '200') {
          let res = response.data;
          if (res.code == 0 || res.code == 200) {
            resolve(res);
          }else if (res.staus_code == 901) { //无权限访问该接口，请先登陆！
            $util.delCookie("created_at");
            $util.delCookie("id");
            $util.delCookie("name");
            $util.delCookie("mobile_no");
            $util.delCookie("role_id");
            $util.delCookie("role_id_name");
            $util.delCookie("token");
            $util.delCookie("updated_at");
            $util.delCookie("web_user_id");
            $util.delCookie("permsession_module");
            $util.delCookie("permsession_module_name");
            $util.delCookie("status");
            $util.delCookie("status_name");
            window.location.href =  "/login";
          } else {
            Modal.info({
              title: "提示",
              content: res.message
            })
          }
        } else {
          reject(response.data);
        }
      })
    });
  };

  static ajaxPost(options, isHeader = true) {
    return new Promise((resolve, reject) => {
      axios({
        url: options.url,
        method: 'post',
        timeout: 30000,
        data: options.data,
        headers: isHeader ? { "Authorization": "bearer " + token } : null
      }, {}).then((response) => {
        // console.log(response)
        if (response.status == '200') {
          let res = response.data;
          if (res.code == 0 || res.code == 200 || res.error_code ==0) {
            resolve(res);
          } else if (res.staus_code == 901) { //无权限访问该接口，请先登陆！
            $util.delCookie("created_at");
            $util.delCookie("id");
            $util.delCookie("name");
            $util.delCookie("mobile_no");
            $util.delCookie("role_id");
            $util.delCookie("role_id_name");
            $util.delCookie("token");
            $util.delCookie("updated_at");
            $util.delCookie("web_user_id");
            $util.delCookie("permsession_module");
            $util.delCookie("permsession_module_name");
            $util.delCookie("status");
            $util.delCookie("status_name");
            window.location.href =  "/login";
          } else {
            Modal.info({
              title: "提示",
              content: res.message
            })
          }
        } else {
          reject(response.data);
        }
      })
    });
  }
}
export default Axios;



