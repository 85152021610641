import React from 'react';
import { notification } from 'antd';
import echarts from 'echarts';

export default {
    formateDate(time) {
        if (!time) return "";
        let date = new Date(time);
        return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    },
    /*
     * @Name 测试专用函数
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    lemonTest: function () {
        console.log('lemon test');
        const args = {
            message: '提示',
            description: 'I will never close automatically. I will be close automatically. I will never close automatically.',
            duration: 0,
        };
        notification.open(args);

    },

    /*
     * @Name 路由懒加载
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    // lazyLoadComponent: function (lazyModules) {
    //     return (location, cb) => {
    //         require.ensure([], require => {
    //             cb(null, lazyModules.default)
    //         })
    //     }
    // },

    /*
     * @Name 深度克隆
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    deepClone: function (obj) {
        let newobj
        if (typeof obj !== 'object' || !obj) {
            return obj;
        } else {
            newobj = obj.constructor === Array ? [] : {};
            for (let i in obj) {
                newobj[i] = typeof obj[i] === 'object' ? this.deepClone(obj[i]) : obj[i];
            }
        }
        return newobj;
    },

    /*
     * @Name 去掉字符串两边空格
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    trimSpace(str) {
        if (str === undefined) {
            return false;
        };

        str = String(str);
        var rule = /(^\s*)|(\s*$)/g;
        var res = str.replace(rule, "");
        return res;
    },

    /*
     * @Name 去掉字符串两边换行
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    trimWrap(str) {
        if (str === undefined) {
            return false;
        };

        str = String(str);
        var rule = /(^\n*)|(\n*$)/g;
        var res = str.replace(rule, "");
        return res;
    },

    /*
     * @Name 生成随机数
     * @Param 
     * @Return 
     * @Author 
     * @Readme 生成min~max的随机数
     */
    generateRandomNum: function (max, min) {
        if (!max || !min) {
            console.log("必须传入max和min")
            return false;
        };

        let num = Math.floor(Math.random() * (max - min + 1) + min);
        return num;
    },

    /*
     * @Name 普通的 GET AJAX
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    doGetAjax: function (url, successCallback, failedCallback) {
        let mySelf = this;
        // let location = window.location.href;
        if (!url) {
            notification['error']({
                message: '提示',
                description: '请求的URL不合法！',
                duration: 3,
            });
            return false;
        };

        let requestID = mySelf.generateRequestID();

        let pro = new Promise(function (resolve, reject) {
            // eslint-disable-next-line
            $.ajax({
                type: 'get',
                async: true,  //同步执行 默认是true异步
                url: url,
                xhrFields: {
                    withCredentials: true
                },
                beforeSend: function (XMLHttpRequest) {
                    XMLHttpRequest.setRequestHeader("x-request-id", requestID);
                },
                timeout: 30000,//设置超时时间
                dataType: 'json',
                success: function (res) {
                    //没有登录态
                    if (res.code === 30002) {
                        //  		let text = "登录超时，请重新登录！";
                        // notification['error']({
                        // 	message: '提示',
                        // 	description: text,
                        // 	duration: 3,
                        // });
                        mySelf.delLoginStatus();
                        //指到登录页面
                        // browserHistory.push('/login');
                    }
                    else if (res.code === 0) {
                        if (successCallback) {
                            successCallback(res);
                        }
                        resolve(res);
                    }
                    else {
                        let text = "interface error: " + res.message;
                        notification['error']({
                            message: 'prompt',
                            description: text,
                            duration: 3,
                        });
                        if (failedCallback) {
                            failedCallback(res);
                        }
                    }
                },
                error: function (XMLHttpRequest, status, errorThrown) {
                    // console.log(status);
                    let text = "ajax failed！interface error status: " + status + ";content: " + errorThrown;
                    notification['error']({
                        message: 'prompt',
                        description: text,
                        duration: 3,
                    });
                    if (failedCallback) {
                        failedCallback();
                    }
                    reject(errorThrown)
                }
            });
        });
        return pro;
    },

    /*
     * @Name 普通的 POST AJAX
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    doPostAjax: function (url, data, successCallback, failedCallback) {
        let mySelf = this;
        if (!url) {
            notification['error']({
                message: '提示',
                description: '请求的URL不合法！',
                duration: 3,
            });
            return false;
        }
        let requestID = mySelf.generateRequestID();

        var pro = new Promise(function (resolve, reject) {
            // eslint-disable-next-line
            $.ajax({
                type: 'post',
                async: true,
                url: url,
                xhrFields: {
                    withCredentials: true
                },
                beforeSend: function (XMLHttpRequest) {
                    XMLHttpRequest.setRequestHeader("x-request-id", requestID);
                },
                data: data,
                timeout: 30000,
                dataType: 'json',
                success: function (res) {
                    //没有登录态
                    if (res.code === 30002) {
                        // let text = "登录超时，请重新登录！";
                        // notification['error']({
                        //     message: '提示',
                        //     description: text,
                        //     duration: 3,
                        // });
                        mySelf.delLoginStatus();
                        //指到登录页面
                        // browserHistory.push('/login');
                    }
                    else if (res.code === 0) {
                        if (successCallback) {
                            successCallback(res);
                        }
                        resolve(res);
                    }
                    else {
                        let text = "interface error: " + res.message;
                        notification['error']({
                            message: 'prompt',
                            description: text,
                            duration: 3,
                        });
                        if (failedCallback) {
                            failedCallback(res);
                        }
                    }
                },
                error: function (XMLHttpRequest, status, errorThrown) {
                    // console.log(status);
                    let text = "ajax failed！interface error status: " + status + ";content: " + errorThrown;
                    notification['error']({
                        message: 'prompt',
                        description: text,
                        duration: 3,
                    });
                    if (failedCallback) {
                        failedCallback();
                    }
                    reject(errorThrown);
                }
            });
        });
        return pro;
    },
    /*
     * @Name 写cookie
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    setCookie: function (c_name, value, expiremMinutes, domain, path) {
        // console.log(path);
        var exdate = new Date();
        exdate.setTime(exdate.getTime() + expiremMinutes * 60 * 60 * 1000 * 24 * 30);
        document.cookie = c_name + "=" + escape(value) + ((expiremMinutes) ? ";expires=" + exdate.toGMTString() : "") + ((domain) ? ";domain=" + domain : "") + ";path=" + ((path) ? path : "/") + ';';
    },

    /*
     * @Name 读cookie
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    getCookie: function (c_name) {
        if (document.cookie.length > 0) {
            var c_start = document.cookie.indexOf(c_name + "=");
            if (c_start !== -1) {
                c_start = c_start + c_name.length + 1;
                var c_end = document.cookie.indexOf(";", c_start);
                if (c_end === -1)
                    c_end = document.cookie.length
                return unescape(document.cookie.substring(c_start, c_end))
            }
        }
        return ""
    },

    /*
     * @Name 删除cookie
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    delCookie: function (c_name, domain, path) {
        var exp = new Date();
        exp.setTime(exp.getTime() - 1);
        // console.log(exp)
        // console.log(exp.toGMTString())
        var cval = this.getCookie(c_name);
        if (cval !== null) {
            document.cookie = c_name + "=" + escape(cval) + ";expires=" + exp.toGMTString() + ((domain) ? ";domain=" + domain : "") + ";path=" + ((path) ? path : "/") + ';';
        }
    },

    /*
     * @Name 写language cookie
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    setLanguageCookie: function (language) {
        let mySelf = this;
        mySelf.setCookie("language", language, 24 * 60);//1天
    },

    /*
     * @Name 伪造登录态
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    forgeLoginStatus: function () {
        let mySelf = this;
        //如果为本地环境 则模拟写入登录态cookie
        // console.log("伪造username");
        let pretendUserName = "lemon@qq.net";
        mySelf.setCookie("user", pretendUserName, 24 * 60);//1天
    },

    /*
     * @Name 删除前端存贮在cookie里的登录态
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    delLoginStatus: function () {
        let mySelf = this;
        mySelf.delCookie("user");
    },

    /*
     * @Name 写入localStorage
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    setLocalStorage: function (key, value) {
        window.localStorage.setItem(key, value);
    },

    /*
     * @Name 读取localStorage
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    getLocalStorage: function (key) {
        return window.localStorage.getItem(key)
    },

    /*
     * @Name 删除localStorage
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    delLocalStorage: function (key) {
        window.localStorage.removeItem(key)
    },

    /*
     * @Name 和PHP一样的时间戳格式化函数
    // @param  {string} format    格式
    // @param  {int}    timestamp 要格式化的时间 默认为当前时间 格式是秒的时间戳
     * @Return {string} 格式化的时间字符串
     * @Author 
     * @reference http://www.daxueit.com/article/4603.html
     * @Readme 
     * @e.g. $util.timeFormatter('Y-m-d H:i:s', new Date().getTime()/1000)
     */
    /* eslint-disable */
    timeFormatter: function (format, timestamp) {
        var a, jsdate = ((timestamp) ? new Date(timestamp * 1000) : new Date());
        var pad = function (n, c) {
            if ((n = n + "").length < c) {
                return new Array(++c - n.length).join("0") + n;
            } else {
                return n;
            }
        };
        var txt_weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        var txt_ordin = { 1: "st", 2: "nd", 3: "rd", 21: "st", 22: "nd", 23: "rd", 31: "st" };

        var txt_months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        var f = {
            // Day
            d: function () {
                return pad(f.j(), 2);
            },
            D: function () {
                var t = f.l(); return t.substr(0, 3);
            },
            j: function () {
                return jsdate.getDate();
            },
            l: function () {
                return txt_weekdays[f.w()];
            },
            N: function () {
                return f.w() + 1;
            },
            S: function () {
                return txt_ordin[f.j()] ? txt_ordin[f.j()] : 'th';
            },
            w: function () {
                return jsdate.getDay();
            },
            z: function () {
                return (jsdate - new Date(jsdate.getFullYear() + "/1/1")) / 864e5 >> 0;
            },

            // Week
            W: function () {
                var a = f.z(), b = 364 + f.L() - a;
                var nd2, nd = (new Date(jsdate.getFullYear() + "/1/1").getDay() || 7) - 1;

                if (b <= 2 && ((jsdate.getDay() || 7) - 1) <= 2 - b) {
                    return 1;
                } else {

                    if (a <= 2 && nd >= 4 && a >= (6 - nd)) {
                        nd2 = new Date(jsdate.getFullYear() - 1 + "/12/31");
                        return date("W", Math.round(nd2.getTime() / 1000));
                    } else {
                        return (1 + (nd <= 3 ? ((a + nd) / 7) : (a - (7 - nd)) / 7) >> 0);
                    }
                }
            },

            // Month
            F: function () {
                return txt_months[f.n()];
            },
            m: function () {
                return pad(f.n(), 2);
            },
            M: function () {
                var t = f.F(); return t.substr(0, 3);
            },
            n: function () {
                return jsdate.getMonth() + 1;
            },
            t: function () {
                var n;
                if ((n = jsdate.getMonth() + 1) == 2) {
                    return 28 + f.L();
                } else {
                    if (n & 1 && n < 8 || !(n & 1) && n > 7) {
                        return 31;
                    } else {
                        return 30;
                    }
                }
            },

            // Year
            L: function () {
                var y = f.Y();
                return (!(y & 3) && (y % 1e2 || !(y % 4e2))) ? 1 : 0;
            },
            //o not supported yet
            Y: function () {
                return jsdate.getFullYear();
            },
            y: function () {
                return (jsdate.getFullYear() + "").slice(2);
            },

            // Time
            a: function () {
                return jsdate.getHours() > 11 ? "pm" : "am";
            },
            A: function () {
                return f.a().toUpperCase();
            },
            B: function () {
                // peter paul koch:
                var off = (jsdate.getTimezoneOffset() + 60) * 60;
                var theSeconds = (jsdate.getHours() * 3600) +
                    (jsdate.getMinutes() * 60) +
                    jsdate.getSeconds() + off;
                var beat = Math.floor(theSeconds / 86.4);
                if (beat > 1000) beat -= 1000;
                if (beat < 0) beat += 1000;
                if ((String(beat)).length == 1) beat = "00" + beat;
                if ((String(beat)).length == 2) beat = "0" + beat;
                return beat;
            },
            g: function () {
                return jsdate.getHours() % 12 || 12;
            },
            G: function () {
                return jsdate.getHours();
            },
            h: function () {
                return pad(f.g(), 2);
            },
            H: function () {
                return pad(jsdate.getHours(), 2);
            },
            i: function () {
                return pad(jsdate.getMinutes(), 2);
            },
            s: function () {
                return pad(jsdate.getSeconds(), 2);
            },
            //u not supported yet

            // Timezone
            //e not supported yet
            //I not supported yet
            O: function () {
                var t = pad(Math.abs(jsdate.getTimezoneOffset() / 60 * 100), 4);
                if (jsdate.getTimezoneOffset() > 0) t = "-" + t; else t = "+" + t;
                return t;
            },
            P: function () {
                var O = f.O();
                return (O.substr(0, 3) + ":" + O.substr(3, 2));
            },
            //T not supported yet
            //Z not supported yet

            // Full Date/Time
            c: function () {
                return f.Y() + "-" + f.m() + "-" + f.d() + "T" + f.h() + ":" + f.i() + ":" + f.s() + f.P();
            },
            //r not supported yet
            U: function () {
                return Math.round(jsdate.getTime() / 1000);
            }
        };

        return format.replace(/[\\]?([a-zA-Z])/g, function (t, s) {
            var ret = null;
            if (t !== s) {
                // escaped
                ret = s;
            } else if (f[s]) {
                // a date function exists
                ret = f[s]();
            } else {
                // nothing special
                ret = s;
            }

            return ret;
        });
    },
    /* eslint-enable */

    /*
     * @Name JSON.parse
     * @Param 
     * @Return 
     * @Author 
     * @Readme 2018-01-03 15:19:53 => 1514963993000
     */
    stringTimeToUnixTimeStamp: function (timeStr) {
        if (!timeStr || typeof timeStr !== 'string') {
            return false;
        };

        // console.log(timeStr)
        let timeObj = timeStr.replace(/-/g, "/");
        timeObj = new Date(timeObj);
        let timeStamp = timeObj.getTime();
        // console.log(date.getTime());
        return timeStamp;
    },

    /*
     * @Name customRangeTimeFormat 返回一个数组 [指定天数差之前的日期, 今天]
     * @Param 
     * @Return 
     * @Author 
     * @Readme customRangeTimeFormat('Y-m-d',0);
     */
    customRangeTimeFormat(format, diffDays) {
        var mySelf = this;
        format = (format) ? format : 'Y-m-d H:i:s';
        diffDays = (diffDays) ? Number(diffDays) : 0;
        var returnDataList = [];
        var today = new Date();
        var endDayFormat = "";
        var startDayFormat = "";
        today.setHours(23, 59, 59);
        endDayFormat = mySelf.timeFormatter(format, today.getTime() / 1000);
        var todayDate = today.getDate();
        today.setDate(todayDate - diffDays);
        today.setHours(0, 0, 0);
        startDayFormat = mySelf.timeFormatter(format, today.getTime() / 1000);
        returnDataList = [startDayFormat, endDayFormat];
        return returnDataList
    },

    /*
     * @Name JSON.parse
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    /* eslint-disable */
    jsonParse: function (data, defaultValue) {
        var resData = "";
        var defaultValue = defaultValue ? defaultValue : false;
        try {
            var data = JSON.parse(data);
            resData = data;
        } catch (error) {
            resData = defaultValue;
            console.log(data + " JSON.parse失败，报错日志是: " + error);
        }
        return resData
    },
    /* eslint-enable */

    /*
     * @Name JSON.stringify
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    /* eslint-disable */
    jsonStringify: function (data, defaultValue) {
        var resData = "";
        var defaultValue = defaultValue ? defaultValue : false;
        try {
            var data = JSON.stringify(data);
            resData = data;
        } catch (error) {
            resData = defaultValue;
            console.log(data + " JSON.parse失败，报错日志是: " + error);
        }
        return resData
    },
    /* eslint-enable */

    /*
     * @Name 判断obj是非空对象
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    isNotEmptyObj(obj) {
        for (var i in obj) {
            if (obj.hasOwnProperty(i)) {
                // console.log(true) //不为空
                return true;
            }
        };
        // console.log(false) //为空
        return false;
    },

    /*
     * @Name 判断obj是空对象
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    isEmptyObj(obj) {
        for (var i in obj) {
            if (obj.hasOwnProperty(i)) {
                return false; //非空
            }
        };
        return true //空
    },

    /*
     * @Name 序列化url
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    /* eslint-disable */
    parseURL: function (url) {
        var a = document.createElement('a');
        a.href = url;
        return {
            source: url,
            protocol: a.protocol.replace(':', ''),
            host: a.hostname,
            port: a.port,
            query: a.search,
            params: (function () {
                var ret = {},
                    seg = a.search.replace(/^\?/, '').split('&'),
                    len = seg.length, i = 0, s;
                for (; i < len; i++) {
                    if (!seg[i]) { continue; }
                    s = seg[i].split('=');
                    ret[s[0]] = s[1];
                }
                return ret;
            })(),
            hashParams: (function () {
                var ret = {},
                    str = a.href;
                var num = str.indexOf("?");
                str = str.substr(num + 1);
                var arr = str.split("&");
                var len = arr.length;
                for (var i = 0; i < len; i++) {
                    if (!arr[i]) { continue; }
                    var s = arr[i].split('=');
                    ret[s[0]] = decodeURI(s[1]);
                }
                return ret;
            })(),
            file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],
            hash: a.hash.replace('#', ''),
            path: a.pathname.replace(/^([^\/])/, '/$1'),
            relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],
            segments: a.pathname.replace(/^\//, '').split('/')
        };
    },
    /* eslint-enable */

    /*
     * @Name 下载csv
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    /* eslint-disable */
    downloadCsv: function (linkId, header, content, fileName) {
        if (!linkId || !header || !content) {
            console.log("downloadCsv 参数 error");
            return false;
        }

        var linkId = linkId;
        var header = header;// ['ID','名字','职位','备注'];
        var content = content;// [['1','lemon','FE','啦啦啦'],['2','lara','str','啊啊啊'],['3','nolan','FF','噜噜噜']];
        var downloadLink = document.getElementById(linkId);
        var context = "";
        var context = header.join(',') + '\n'
        for (var i = 0; i < content.length; i++) {
            var item = content[i];
            item.forEach(function (item, index, list) {
                context = context + item + ','
            })
            context = context + '\n'
        }

        // console.log('---------------拼接的字符串---------------\n' + context)
        //var context = "col1," + "反反\r复复" + ",col3\nvalue1,value2,value3"
        context = encodeURIComponent(context)
        downloadLink.download = fileName + '.csv' // 下载的文件名称
        downloadLink.href = "data:text/csv;charset=utf-8,\ufeff" + context; //加上 \ufeff BOM 头
        downloadLink.addEventListener("click", function (e) { e.stopPropagation() }, false); // 阻止冒泡事件

        // setTimeout(function(){
        // console.log(downloadLink.href)
        downloadLink.click();
        // },5000);
        console.log('下载csv文件完成！')
    },
    /* eslint-enable */


    /*
     * @Name ant-table 模糊查询
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    fuzzySearch: function (queryKeyWord, originData) {
        let mySelf = this;

        if (queryKeyWord === undefined || !originData) {
            return false;
        };

        if (queryKeyWord === '') {
            return originData;
        };

        let newData = originData.filter(function (item, index, array) {
            // console.log(item.name)
            for (let key in item) {
                let value = item[key];
                let valueFormat = "";

                if (key === 'id') {
                    continue;
                };

                if (typeof value === 'number') {
                    valueFormat = value.toString();
                }
                else if (typeof value === 'object') {
                    valueFormat = mySelf.jsonStringify(value);
                }
                else if (typeof value === 'string') {
                    valueFormat = value;
                }
                else {
                    continue;
                }

                // console.log(typeof item[key]);
                // console.log(key + ':' + typeof item[key]);
                if (valueFormat.includes(queryKeyWord)) {
                    return item;
                }
            }
            return false
        });
        return newData;
    },

    /*
     * @Name 从Array中按某个字段模糊查询
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    fieldFuzzySearch: function (queryKeyWord, originData, fieldName) {
        if (queryKeyWord === undefined || !originData || fieldName === undefined) {
            return false;
        };

        if (queryKeyWord === '') {
            return originData;
        };

        let newData = originData.filter(function (item, index, array) {
            // console.log(item.name)
            let field = String(item[fieldName]);
            if (field.includes(queryKeyWord)) {
                return item;
            }
            return false
        });
        return newData;
    },


    /*
     * @Name ant-table 高亮模糊查询
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    highLightFuzzySearch: function (queryKeyWord, originData) {
        if (queryKeyWord === undefined || !originData) {
            return false;
        };

        let newData = originData.filter(function (item, index, array) {
            // let sign = false;
            for (let key in item) {
                // console.log(key + ":" + item[key])
                if (key === 'key') {
                    continue;
                };
                let value = item[key].toString();
                if (value.includes(queryKeyWord)) {
                    // sign = true;
                    item[key] =
                        <span>
                            {value.split(queryKeyWord).map((text, i) => (
                                i > 0 ? [<span className="font-color-orangered">{queryKeyWord}</span>, text] : text
                            ))}
                        </span>;
                    item[key + "_origin"] = value;
                    return item;
                };
                // return item;
            };

            // if (sign === true){
            //     return item;
            // };
            return false;
        });
        return newData;
    },

    /*
     * @Name 侧边栏滑入
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    showChartSidebar: function () {
        let chartViewSidebar = document.getElementsByClassName('chart-view-sidebar')[0];
        chartViewSidebar.style.transform = 'translate3d(0px, 0px, 0px)';
    },

    /*
     * @Name 侧边栏滑出
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    hideChartSidebar: function () {
        let chartViewSidebar = document.getElementsByClassName('chart-view-sidebar')[0];
        chartViewSidebar.style.transform = 'translate3d(810px, 0px, 0px)';
    },

    /*
     * @Name 渲染-折线图
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    renderLineChart: function (optionObject) {

        let containerId = optionObject.containerId;
        let showTitle = (optionObject.title === '') ? false : true;
        let title = optionObject.title || null;
        let titleTop = optionObject.titleTop || 'top';
        let titleLeft = optionObject.titleLeft || 'center';
        let titleRight = optionObject.titleRight;
        let top = optionObject.top || 30;
        let right = optionObject.right || 30;
        let bottom = optionObject.bottom || 30;
        let left = optionObject.left || 30;
        let legendTop = optionObject.legendTop;
        // let legendRight = optionObject.legendRight;
        let legendBottom = optionObject.legendBottom || 0;
        let legendLeft = optionObject.legendLeft || 'center';
        let showLegend = optionObject.showLegend || false;
        let showToolbox = optionObject.showToolbox || false;
        let xAxisData = optionObject.xAxisData || [];// ["2023/6/13","2023/6/14","2023/6/15","2023/6/16","2023/6/17","2023/6/18","2023/6/19","2023/6/20","2023/6/21","2023/6/22"];
        let dataList = optionObject.dataList || [];// [[2,55,243,246,253,249,252,251,257,262],[2,55,243,246,253,249,252,251,257,2]]
        let legendData = optionObject.legendData || [];// ['模拟数据','模拟数据2']
        let yAxisFormatter = optionObject.yAxisFormatter || null;
        let yAxisName = optionObject.yAxisName || null;
        let yAxisNameLocation = optionObject.yAxisNameLocation || 'end';
        // let yAxisFormatter = function(value) {
        //  return value / 10 + "k" ;
        // }
        let minInterval = optionObject.minInterval || 0;
        let tooltipFormatter = optionObject.tooltipFormatter || null;
        let showDataZoom = optionObject.showDataZoom || false;
        let dataZoomStart = optionObject.dataZoomStart || 0;
        let dataZoomEnd = optionObject.dataZoomEnd || 100;
        let series = [];
        // let optionToContent = optionObject.optionToContent || null

        if (!containerId) {
            console.log("renderLineChart报错！containerId不能为空！");
            return false;
        };

        dataList.forEach((item, index, array) => {
            let temp = {};
            temp = {
                name: legendData[index],
                type: 'line',
                smooth: false,
                data: item
            };
            series.push(temp);
        });

        // console.log(JSON.stringify(series))
        let option = {
            title: {
                show: showTitle,
                top: titleTop,
                left: titleLeft,
                right: titleRight,
                text: title,
                textStyle: {
                    fontSize: 14
                }
            },
            legend: {
                show: showLegend,
                orient: 'horizontal',
                data: legendData,
                top: legendTop,
                // right: legendRight,
                bottom: legendBottom,
                left: legendLeft
            },
            grid: {
                top: top,
                right: right,
                bottom: bottom,
                left: left
            },
            tooltip: {
                trigger: 'axis',
                formatter: tooltipFormatter
            },
            toolbox: {
                show: showToolbox,
                orient: 'horizontal',
                right: 30,
                top: 0,
                feature: {
                    // dataView: {readOnly: true, optionToContent:optionToContent},
                    restore: {},
                    saveAsImage: {
                        type: 'png',
                        name: 'echart截图',
                        title: '保存'
                    }
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: xAxisData,
                // axisTick: {
                //  interval: 0
                // }
                // axisLabel: {
                //     interval: 20,
                //     rotate: 30,
                // }
            },
            yAxis: {
                axisLabel: {
                    formatter: yAxisFormatter
                },
                type: 'value',
                boundaryGap: false,
                min: 0,
                minInterval: minInterval,
                name: yAxisName,
                nameLocation: yAxisNameLocation,
            },
            dataZoom: [{
                type: 'inside',
                start: dataZoomStart,
                end: dataZoomEnd
            }, {
                show: showDataZoom,
                start: dataZoomStart,
                end: dataZoomEnd,
                handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                handleSize: '80%',
                handleStyle: {
                    color: '#fff',
                    shadowBlur: 3,
                    shadowColor: 'rgba(0, 0, 0, 0.6)',
                    shadowOffsetX: 2,
                    shadowOffsetY: 2
                },
                bottom: 0
            }],
            series: series,
            // color: $pars.chartColorList,
            backgroundColor: "#FFF"
        };

        this.initChart(option, containerId);
    },

    /*
     * @Name 渲染-饼形图
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    renderPieChart: function (optionObject) {

        let containerId = optionObject.containerId;
        let radius = optionObject.radius || '60%';
        let center = optionObject.center || ['50%', '50%'];
        let showTitle = (optionObject.title === '') ? false : true;
        let title = optionObject.title || null;
        let data = optionObject.data || [];// [{name:'电信', value:100},{name:'铁通', value:200},{name:'移动', value:300},{name:'教育', value:400},{name:'网通', value:500},{name:'其他', value:600}];
        let legendData = optionObject.legendData || [];// ["运营商分布"]
        let showToolbox = optionObject.showToolbox || false;
        let showLegend = optionObject.showLegend || false;
        let tooltipFormatter = optionObject.tooltipFormatter || '{a} <br/>{b}: {c} ({d}%)';
        let seriesName = optionObject.seriesName || '数据';
        let seriesNameFormatter = optionObject.seriesNameFormatter || '{b}: {c} ({d}%)';
        // console.log(JSON.stringify(series))
        let option = {
            title: {
                show: showTitle,
                top: 15,
                left: 'center',
                text: title,
            },
            legend: {
                show: showLegend,
                orient: 'horizontal',
                data: legendData,
                x: 'center',
                y: 'bottom'
            },
            tooltip: {
                trigger: 'item',
                formatter: tooltipFormatter
            },
            toolbox: {
                show: showToolbox,
                feature: {
                    dataView: { readOnly: false },
                    restore: {},
                    saveAsImage: {}
                }
            },
            series: [
                {
                    name: seriesName,
                    label: {
                        normal: {
                            formatter: seriesNameFormatter
                        }
                    },
                    type: 'pie',
                    radius: radius,
                    center: center,
                    data: data,
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };

        this.initChart(option, containerId);
    },


    /*
     * @Name 渲染-环
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    renderRingChart: function (optionObject) {

        let containerId = optionObject.containerId;
        let center = optionObject.center || ['50%', '50%'];
        let showTitle = (optionObject.title === '') ? false : true;
        let title = optionObject.title || null;
        let data = optionObject.data || [];// [{name:'电信', value:100},{name:'铁通', value:200},{name:'移动', value:300},{name:'教育', value:400},{name:'网通', value:500},{name:'其他', value:600}];
        let legendData = optionObject.legendData || [];// ["运营商分布"]
        let showToolbox = optionObject.showToolbox || false;
        let showLegend = optionObject.showLegend || false;
        let tooltipFormatter = optionObject.tooltipFormatter || '{a} <br/>{b}: {c} ({d}%)';//{a}（系列名称），{b}（数据项名称），{c}（数值）, {d}（百分比）

        if (!containerId) {
            console.log("renderRingChart报错！containerId不能为空！");
            return false;
        };

        let option = {
            title: {
                show: showTitle,
                top: 10,
                left: 'center',
                text: title,
                textStyle: {
                    fontSize: 14
                }
            },
            tooltip: {
                trigger: 'item',
                formatter: tooltipFormatter//"{a} <br/>{b}: {c} ({d}%)"
            },
            legend: {
                show: showLegend,
                orient: 'horizontal',
                left: 'center',
                top: 'bottom',
                data: legendData
            },
            toolbox: {
                show: showToolbox,
                orient: 'horizontal',
                right: 0,
                top: 0,
                feature: {
                    dataView: { readOnly: true },
                    // restore: {},
                    saveAsImage: {
                        type: 'png',
                        name: 'echart截图',
                        title: '保存'
                    }
                }
            },
            series: [
                {
                    name: legendData[0],
                    type: 'pie',
                    selectedMode: false,//'single','multiple'
                    radius: ['50%', '65%'],
                    center: center,
                    label: {
                        normal: {
                            show: true,
                            position: 'outside'
                        }
                    },
                    labelLine: {
                        normal: {
                            show: true
                        }
                    },
                    data: data
                }
            ],
            // color: $pars.chartColorList
        };

        this.initChart(option, containerId);
    },

    /*
     * @Name 显示echart过载动画
     * @Param 
     * @Return 
     * @Author 
     * @Readme containerId是id的字符串格式 e.g mySelf.$util.chartShowLoading('current-chart-container');
     */
    chartShowLoading: function (containerId) {
        let myChart = echarts.getInstanceByDom(document.getElementById(containerId));
        if (myChart) {
            myChart.showLoading();
        }
    },

    /*
     * @Name 隐藏echart过载动画
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    chartHideLoading: function (containerId) {
        let myChart = echarts.getInstanceByDom(document.getElementById(containerId));
        if (myChart) {
            myChart.hideLoading();
        }
    },

    /*
     * @Name 初始化chart & 适配浏览器
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    initChart: function (option, containerId) {

        let domContainer = document.getElementById(containerId);
        let myChart = null;
        if (!domContainer) {
            // console.log(111111);
            return false;
        };

        myChart = echarts.getInstanceByDom(document.getElementById(containerId));
        if (myChart) {
            //如果echart实例存在 则销毁掉 
            // myChart.dispose();
            //如果echart实例存在 重新设置 懒加载
            myChart.setOption(option, true);
            myChart.hideLoading();
        }
        else {
            //如果echart实例不存在 则是首次渲染 则需要初始化
            myChart = echarts.init(document.getElementById(containerId));
            myChart.setOption(option);
            //窗口适配
            window.addEventListener("resize", function () {
                myChart.resize();
            }, false);
        }
    },

    /*
     * @Name 首字母小写
     * @Param 
     * @Return 
     * @Author
     * @Readme
     */
    firstLowerCase: function (str) {
        return str.replace(/\b(\w)(\w*)/g, function ($0, $1, $2) {
            return $1.toLowerCase() + $2;
        });
    },

    /*
    * @Name 首字母大写
    * @Param 
    * @Return 
    * @Author
    * @Readme
    */
    firstUpperCase: function (str) {
        return str.replace(/\b(\w)(\w*)/g, function ($0, $1, $2) {
            return $1.toUpperCase() + $2;
        });
    },

    /*
     * @Name Byte转化成KB
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    ByteToKB: function (traffic, isThousand) {
        traffic = traffic || 0;
        var k = (isThousand === true) ? 1000 : 1024;
        var res = traffic / k;
        // console.log(res);
        //return Math.ceil(res*100)/100;
        return parseFloat(res.toFixed(2));
    },

    /*
     * @Name Byte转化成MB
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    ByteToMB: function (traffic, isThousand) {
        traffic = traffic || 0;
        var k = (isThousand === true) ? 1000 : 1024;
        var res = traffic / (k * k);
        // console.log(res);
        //return Math.ceil(res*100)/100;
        return parseFloat(res.toFixed(2));
    },

    /*
     * @Name Byte转化成Gb
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    ByteToGB: function (traffic, isThousand) {
        traffic = traffic || 0;
        var k = (isThousand === true) ? 1000 : 1024;
        var res = traffic / (k * k * k);
        //return parseFloat(res.toFixed(2));
        return parseFloat(res.toFixed(2));
    },

    /*
     * @Name KB转化成MB
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    KByteToMB: function (traffic, isThousand) {
        traffic = traffic || 0;
        var k = (isThousand === true) ? 1000 : 1024;
        var res = traffic / (k);
        // console.log(res);
        //return Math.ceil(res*100)/100;
        return parseFloat(res.toFixed(2));
    },

    /*
     * @Name KB转化成GB
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    KByteToGB: function (traffic, isThousand) {
        traffic = traffic || 0;
        var k = (isThousand === true) ? 1000 : 1024;
        var res = traffic / (k * k);
        // console.log(res);
        //return Math.ceil(res*100)/100;
        return parseFloat(res.toFixed(2));
    },

    /*
     * @Name MB转化成GB
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    MBToGB: function (traffic, isThousand) {
        traffic = traffic || 0;
        var k = (isThousand === true) ? 1000 : 1024;
        var res = traffic / (k);
        // console.log(res);
        //return Math.ceil(res*100)/100;
        return parseFloat(res.toFixed(2));
    },

    /*
     * @Name MB转化成TB
     * @Param 
     * @Return 
     * @Author 
     * @Readme
     */
    MBToTB: function (traffic, isThousand) {
        traffic = traffic || 0;
        var k = (isThousand === true) ? 1000 : 1024;
        var res = traffic / (k * k);
        // console.log(res);
        //return Math.ceil(res*100)/100;
        return parseFloat(res.toFixed(2));
    },

    /*
     * @Name hd硬盘格式化 MB => GB 
     * @Param 
     * @Return 
     * @Author 
     * @Readme 大于1024MB的数值以GB为单位显示
     */
    hdFormat: function (value) {
        let mySelf = this;
        let valueFormat = value > 1024 ? mySelf.MBToGB(value) + 'GB' : value + 'MB';
        return valueFormat;
    },

    /*
     * @Name flow格式化 bps => Mbps
     * @Param 
     * @Return 
     * @Author 
     * @Readme 大于0.01Mbps的数值以Mbps为单位
     */
    flowFormat: function (value) {
        let mySelf = this;
        let valueFormat = value > 0.01 * 1024 * 1024 ? mySelf.ByteToMB(value) + 'Mbps' : value + 'bps';
        return valueFormat;
    },

    /*
     * @name: 过滤参数
     * @desc: 筛选出不为null的参数
     */
    filterInput: function (originObj, isFilterNullStr) {
        let newObj = {};
        for (let key in originObj) {
            let value = originObj[key];
            if (value === null) {
                continue;
            }
            if (isFilterNullStr && value === "") {
                continue;
            }
            newObj[key] = value;
        }
        return newObj;
    },

    /*
     *   @name: moment时间转为秒级时间戳
     *   @desc: moment类型的时间转换为时间戳
     */
    getTransferTimestamp: function (time) {
        // let date_format = "YYYY-MM-DD HH:mm:ss";
        // return time ? parseInt(moment(time,date_format).valueOf() / 1000, 10) - moment().utcOffset() : null;
        // return time ? parseInt(this.momentObjtoUTCTimeStamp(time), 10) : null;
        return time ? parseInt(String(time.valueOf()).slice(0, -3), 10) : null;
    },

    getUTCTime: function (time, format) {
        let date_format = format ? format : "YYYY-MM-DD";
        // return time ? parseInt(moment(time,date_format).valueOf() / 1000, 10) - moment().utcOffset() : null;
        // return time ? parseInt(this.momentObjtoUTCTimeStamp(time), 10) : null;
        return time ? time.utc().format(date_format) : null;
    },

    /*
     *   @name: "HH:mm:ss"转换成秒级时间戳
     */
    getTransferHMSToTimestamp: function (timeStr) {
        if (!timeStr) {
            return 0;
        }
        let timeArr = timeStr.split(':');
        let hour = timeArr[0];
        let minute = timeArr[1];
        let second = timeArr[2];

        let timestamp = parseInt(hour, 10) * 3600 + parseInt(minute, 10) * 60 + parseInt(second, 10)
        return timestamp;
    },

    /*
     *   @name: 秒级时间戳转换成“HH:mm:ss"
     */
    getTransferTimestampToHMS: function (timeStamp) {
        let hour = 0, mimute = 0, second = 0;
        hour = parseInt(Number(timeStamp) / 3600, 10);
        mimute = parseInt((Number(timeStamp) % 3600) / 60, 10);
        second = parseInt((Number(timeStamp) % 3600) % 60, 10);

        if (hour < 10) {
            hour = '0' + hour;
        }
        if (mimute < 10) {
            mimute = '0' + mimute;
        }
        if (second < 10) {
            second = '0' + second;
        }
        let time = hour + ":" + mimute + ":" + second;
        return time
    },
    /*
     * @Name 时间转换为utc时间
     * @Param
     * @Return
     * @Author 
     * @Readme
     */
    momentObjtoUTCTimeStamp: function (momentObj) {
        if (!momentObj) {
            console.log("非法入参");
            return false
        }
        let year = momentObj.year();
        let month = momentObj.month();
        let date = momentObj.date();
        let hour = momentObj.hour();
        let minute = momentObj.minute();
        let second = momentObj.second();
        let UTCTimeStamp = "";
        UTCTimeStamp = Date.UTC(year, month, date, hour, minute, second);
        //截取到秒
        UTCTimeStamp = String(UTCTimeStamp).slice(0, -3);
        // console.log(UTCTimeStamp);
        return UTCTimeStamp
    },

    /*
     * @Name base64加密
     * @Param
     * @Return
     * @Author
     * @Readme
     */
    base64Encode: function (str) {
        if (str === undefined) {
            // console.log("非法入参");
            return false
        }
        return window.btoa(unescape(encodeURIComponent(str)))
    },

    /*
     * @Name base64解密
     * @Param
     * @Return
     * @Author
     * @Readme
     */
    base64Decode: function (codeStr) {
        if (codeStr === undefined) {
            // console.log("非法入参");
            return false
        }
        return decodeURIComponent(escape(window.atob(codeStr)))
    },


    /*
     * @Name 千分位
     * @Param
     * @Return
     * @Author
     * @Readme 
     * 参考 https://segmentfault.com/a/1190000002884224
     * "1234567890.42545" 转化成 1,234,567,890.43
     */
    thousandStringFormat(num, digit) {
        digit = digit ? digit : 0;
        num = parseFloat(num);//转化成小数的形式
        //大于100的时候，再处理成千分位的格式
        if (num > 999) {
            num = num.toFixed(digit);//保留几位
            num = num.replace(/(^|\s)\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))//使用正则替换，每隔三个数加一个','  
        };
        num = num.toString();
        return num
    },

    /*
     * @Name 时间对象 =》对应的OTC时间对象 
     * @Param
     * @Return
     * @Author
     * @Readme 
     *
     */
    currentTimeObjToUTCTimeObj(timeObj) {
        if (!timeObj) {
            console.log("非法入参");
            return false
        };
        let year = timeObj.getUTCFullYear();
        let month = timeObj.getUTCMonth();
        let date = timeObj.getUTCDate();
        let hour = timeObj.getUTCHours();
        let minute = timeObj.getUTCMinutes();
        let second = timeObj.getUTCSeconds();
        let UTCTimeObj = new Date(year, month, date, hour, minute, second);
        return UTCTimeObj
    },

    /*
     * @Name
     * @Param
     * @Return
     * @Author
     * @Readme 
     *
     */
    currentTimeStringToUTCTimeRange(endTimeString, diffDays = 7) {
        diffDays = Number(diffDays);
        let endTimeObj = new Date(endTimeString);
        let endTimeUtCObj = this.currentTimeObjToUTCTimeObj(endTimeObj);
        let endTimeUTCStamp = String(endTimeUtCObj.getTime()).slice(0, -3);
        // console.log(endTimeUtCObj.getTime());
        // console.log(endTimeUTCString);
        //设置成7天前
        endTimeObj.setDate(endTimeObj.getDate() - diffDays);
        // console.log(endTimeObj);
        let startTimeUtCObj = this.currentTimeObjToUTCTimeObj(endTimeObj);
        let startTimeUTCStamp = String(startTimeUtCObj.getTime()).slice(0, -3);
        // console.log(startTimeUtCObj);
        // console.log(endTimeUTCStamp);
        let res = {
            startTimeUTCStamp: Number(startTimeUTCStamp),
            endTimeUTCStamp: Number(endTimeUTCStamp)
        };

        return res
    },

    /*
     * @Name 补全小数位数
     * @Param 
     * @Return 
     * @Author
     * @Readme number入参 precision精度
     */
    completeDecimalDigits: function (number, precision = 2) {
        // console.log(number)
        if (number === undefined || Number.isNaN(number) === true) {
            return number
        };

        number = Number(number);
        precision = Number(precision);

        // console.log(number)
        if (number === 0) {
            return 0;
        };

        let numberFormat = "";
        numberFormat = number.toFixed(precision);
        return numberFormat;
    },
    /*
     * @Name map转数组
     * @Param 
     * @Return 
     * @Author
     * @Readme number入参 precision精度
     */
    mapToAry: function (map) {
        let list = [];
        for (let key in map) {
            list.push([key, map[key]]);
        }
        return list;
    }
}
function Wresize({ min = 768, max = 2400 } = {}) {
    var windowWidth = document.body.clientWidth;
    var html = document.getElementsByTagName("html")[0];
    var size = parseInt(windowWidth);
    if (windowWidth > max) {
        size = max;
    } else if (windowWidth < min) {
        size = min;
    }
    html.style.fontSize = size / 96 + "px";
}
function fontSizeToRem() {
    window.addEventListener("resize", Wresize);
    Wresize()
}
export { Wresize, fontSizeToRem }
