import React, { Component } from "react";
import MenuConfig from "@/config/menuConfig";
import { Menu, Icon } from 'antd';
import { NavLink } from 'react-router-dom';

class NoMatch extends Component {
    constructor(props) {
        super(props);
    }
  
    render() {

        return (
            <div style={{display:"flex",justifyContent:"center"}}>
                <h1>404</h1>
            </div>
        )
    }
}

export default NoMatch;