import React,{ Component } from "react";
import './index.less';
import echarts from 'echarts'
import ReactEcharts from 'echarts-for-react';
require('../../utils/macarons');

class histogram extends Component{
  constructor(props) {
    super(props);
    this.state={
      height:this.props.height,
      width:this.props.width,
      xAxis: [],
      options:""
    };
  }
  state={
  };
  componentWillMount(){
    this.getOption();
  }
  componentDidMount(){
  }
  componentWillReceiveProps(nextProps, nextState){
  }
  getOption(){
    let xAxis = "";
    if(this.props.chargeShow === 1){//月
      xAxis=['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'];
    }else if(this.props.chargeShow === 2){//周
      xAxis= ["第一周","第二周","第三周","第四周","第五周"];
    }else{//日
      xAxis = this.props.incomexAxis;
    }
    this.state.options= {
      title : {
      },
      tooltip : {
        trigger: 'axis'
      },
      grid:{
        left:'15%'
      },
      toolbox: {
        show : false,
        feature : {
          mark : {show: false},
          dataView : {show: false, readOnly: false},
          magicType : {show: false, type: ['line', 'bar']},
          restore : {show: false},
          saveAsImage : {show: false}
        }
      },
      calculable : true,
      xAxis : [
        {
          type : 'category',
          data : xAxis,
          axisLine:{//轴线
            show:false
          },
          splitLine: {//分割线
            show: false
          },
          axisTick:{//刻度线
            show:false
          },
          offset:10
        }
      ],
      yAxis : [
        {
          type : 'value',
          axisLine:{
            show:false
          },
          splitLine: {
            show: true,
            lineStyle:{
              color:["#1d3454"]
            }
          },
          axisTick:{
            show:false
          }
        }
      ],
      series : [
        {
          name:this.props.name,
          type:'bar',
          data:this.props.chargeData,
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  {offset: 0, color: '#05fda7'},
                  {offset: 0.5, color: '#34e7db'},
                  {offset: 1, color: '#52d9fc'}
                ]
              )
            },
            emphasis: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  {offset: 0, color: '#05fda7'},
                  {offset: 0.7, color: '#34e7db'},
                  {offset: 1, color: '#52d9fc'}
                ]
              )
            }
          },
          barWidth : 10,//柱图宽度
          barMaxWidth:20,//最大宽度
        }
      ]
    };
  }
  render(){
    this.getOption();
    return (
      <ReactEcharts option={this.state.options}  theme={"macarons"} style={{ width:this.state.width, height:this.state.height }}>
  </ReactEcharts>
    )
  }
}

export default histogram;