import React,{ Component } from "react";
import './index.less';
import $util from "@/utils/utils";

class GaodeMap extends Component{
  constructor(props) {
    super(props);
    let province_code_name = $util.getCookie("province_code_name");
    this.state = {
      province_code_name: province_code_name,
      gaodeMap:null,
      warnStyle:[//1正常 2过载 3短路 4烟雾报警
      ],
      infoWindow:{

      },
      cluster:[],
      markers:[],
      errMarks:[]
    }
  }
  state={
  };
  componentWillMount(){
  }
  componentWillReceiveProps(nextProps, nextState){
    //todo 先清除标记
  }
  componentDidMount(){
    this.gaodeMap = new window.AMap.Map('container', {
      zoom:4,//级别
      zooms:[4,18],
      mapStyle:'amap://styles/2cc79d8a762740f6766df9fe4adaeade',
      center: [102.035718, 37.035403]//中心点坐标
    });
    this.gotoCity();
  }
  //根据cityname、adcode、citycode设置地图位置
  gotoCity () {
    let val = this.state.province_code_name; //可以是cityname、adcode、citycode
    if (val != 'null') {
      this.gaodeMap.setCity(val);
    }
    this.setBounds();
  }
  setBounds(){
    var bounds = this.gaodeMap.getBounds();
    // bounds.oc= {"P":57.205259,"Q":128.042553,"lng":128.042553,"lat":57.205259};
    // bounds.xc = {"P":13.662941,"Q":77.988842,"lng":77.988842,"lat":13.662941};
    // bounds.northeast = {"P":57.205259,"Q":128.042553,"lng":128.042553,"lat":57.205259};
    // bounds.southwest = {"P":13.662941,"Q":77.988842,"lng":77.988842,"lat":13.662941};
    // bounds.southwest = {"P":13.662941,"Q":77.988842,"lng":77.988842,"lat":13.662941};
    this.gaodeMap.setLimitBounds(bounds);
  }
  clearBounds(){
    this.gaodeMap.clearLimitBounds();
  }
  removeErrMark(){
    this.gaodeMap.remove(this.state.errMarks);
    this.setState({ "errMarks": []});
  }
  // markMaker(){
  //   this.removeErrMark();
  //   let infoWindow={};
  //   let marks = [];
  //   let keyOut = "",obj = {},warn_popup = false;
  //   for(let key in this.props.alertData){
  //     let center = [this.props.alertData[key].evse_lng,this.props.alertData[key].evse_lat];
  //     let options = {
  //       center:center,
  //       strokeOpacity:0.5,
  //       zIndex:10,
  //       bubble:true,
  //       cursor:'pointer',
  //       clickable: true
  //     };
  //     let imgUrl = require(`../../assets/mapMark${this.props.alertData[key].evse_error_type}.png`);//报警状态 1、正常 2、过载 3、短路 4、烟雾报警
  //     let size =12;
  //     if(this.props.alertData[key].evse_error_type == 3|| this.props.alertData[key].evse_error_type ==4){
  //       size =24;
  //     }
  //     let startIcon = new window.AMap.Icon({
  //       // 图标尺寸
  //       size: new window.AMap.Size(size, size),
  //       // 图标的取图地址
  //       image:imgUrl,
  //       // 图标所用图片大小
  //       imageSize: new window.AMap.Size(size, size)
  //     });
  //     let marker = new window.AMap.Marker({
  //       icon: startIcon,
  //       clickable:true,
  //       position: center
  //     });
  //     this.gaodeMap.add(marker);
  //     marker.on('click',(e)=>{
  //       infoWindow[key].open(this.gaodeMap, e.target.getPosition());
  //     });
  //     /*//marker.setMap(this.gaodeMap);
  //     window.AMap.event.addListener(marker, 'click',  (e)=> {
  //       this.state.infoWindow.open(this.gaodeMap, e.target.getPosition());
  //     });*/
  //     infoWindow[key] = new window.AMap.InfoWindow({
  //       isCustom: true,  //使用自定义窗体
  //       content: this.createInfoWindow(this.props.alertData[key].evse_name,this.props.alertData[key].evse_no,this.props.alertData[key].evse_error_type_name),
  //       offset: new window.AMap.Pixel(16, -45)
  //     });
  //
  //       if(this.props.alertData[key].evse_error_type == 4){
  //           warn_popup = true;
  //           keyOut = key;
  //           obj["P"] = this.props.alertData[key].evse_lat;
  //           obj["Q"] = this.props.alertData[key].evse_lng;
  //           obj["lat"] = this.props.alertData[key].evse_lat;
  //           obj["lng"] = this.props.alertData[key].evse_lng;
  //       }
  //     marks.push(marker);
  //   }
  //   if(warn_popup){
  //       infoWindow[keyOut].open(this.gaodeMap,obj);
  //   }else {
  //       this.closeInfoWindow()
  //   }
  //   this.setState({ "errMarks": marks});
  //   this.setState({ "infoWindow": infoWindow })
  // }
  createInfoWindow(titleName, deviceId,warn) {
    var info = document.createElement("div");
    info.className = "map_marker_bg";

    //可以通过下面的方式修改自定义窗体的宽高
    //info.style.width = "400px";
    // 定义顶部标题
    var top = document.createElement("div");
    var title = document.createElement("div");
    var no = document.createElement("div");
    var bottom = document.createElement("div");
    top.className = "info_top";
    title.className = "info_title";
    no.className = "no_font";
    title.innerText = titleName;
    no.innerText = "No."+deviceId;
    bottom.className = "info_bottom";
    bottom.innerText = warn;
    top.appendChild(title);
    top.appendChild(no);
    info.appendChild(top);
    info.appendChild(bottom);
    info.onclick = ()=>{this.closeInfoWindow()};
    return info;
  }
  clusterMarker() {
    let imgUrl = require(`../../assets/mapMark1.png`);
    let startIcon = new window.AMap.Icon({
      // 图标尺寸
      size: new window.AMap.Size(18, 18),
      // 图标的取图地址
      image:imgUrl,
      // 图标所用图片大小
      imageSize: new window.AMap.Size(18, 18)
    });
    let markers = [];
    let i = 0;
    for (let key in this.props.evseData) {
      let center = [this.props.evseData[key].evse_lng, this.props.evseData[key].evse_lat];
      markers.push(new window.AMap.Marker({
        position: center,
        icon:startIcon
      }))
        i++;
    }
    var sts = [{
      url: require(`../../assets/mapMark/m1.png`),
      size: new window.AMap.Size(53, 52),
      offset: new window.AMap.Pixel(-26.5, -26)
    }, {
      url: require(`../../assets/mapMark/m2.png`),
      size: new window.AMap.Size(56, 55),
      offset: new window.AMap.Pixel(-28, -27.5)
    }, {
      url: require(`../../assets/mapMark/m3.png`),
      size: new window.AMap.Size(66, 65),
      offset: new window.AMap.Pixel(-33, -32.5)
    }, {
      url: require(`../../assets/mapMark/m4.png`),
      size: new window.AMap.Size(78, 77),
      offset: new window.AMap.Pixel(-39, -38.5)
    }];
    if(this.state.clusterers){
      this.state.clusterers.clearMarkers();
    }
    this.setState({"clusterers":new window.AMap.MarkerClusterer(this.gaodeMap, markers,  {
        styles: sts,
        gridSize: 80
      })})
  }
//关闭信息窗体
  closeInfoWindow() {
    this.gaodeMap.clearInfoWindow();
  }
  render(){
    return (
      <div id={'container'} className={"gaode_map"} style={{"height":this.props.height,"width":this.props.width}}>
      </div>
    )
  }
}

export default GaodeMap;