import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import $util from "@/utils/utils";
import NoMatch from '@/components/NoMatch';
//私有路由，只有登录的用户才能访问
class PrivateRoute extends Component {
    componentWillMount() {
        let isAuthenticated = $util.getCookie("name") ? true : false;
        this.setState({ isAuthenticated: isAuthenticated })
        if (!isAuthenticated) {
            const { history } = this.props;
            console.log("PrivateRoute")
            history.replace("/login");
        }
    }
    render() {
        let { component: Component, path = "/", exact = true, strict = false ,isShow =false } = this.props;
        return this.state.isAuthenticated && isShow ? (
            <Route path={path} exact={exact} strict={strict} render={(props) => (<Component {...props} />)} />
        ) : ( <NoMatch />);
    }
}
PrivateRoute.propTypes = {
    path: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    strict: PropTypes.bool,
    component: PropTypes.func.isRequired
}
export default withRouter(PrivateRoute);