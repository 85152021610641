import React, { Component } from "react";
import axios from "@/axios";
import $pars from "@/pars/pars.js";
import $util from "@/utils/utils.js";

import {
    Layout, Row, Col, Select, Form, Button, Radio, Modal, notification, InputNumber
} from 'antd';

const { Content } = Layout;
const Option = Select.Option;

const popoverFormItemLayout = {
    labelCol: {
        sm: { span: 4, offset: 1 },
    },
    wrapperCol: {
        sm: { span: 7, offset: 1 }
    }
}

class HorizontalLoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preForm: null,
            id: "",
        }
    }

    componentDidMount() {
        this.getSystemInfo();
    }

    getSystemInfo = () => {
        axios.ajaxGet({
            url: "/getSystem",
        }, true).then((res) => {
            console.log(res)
            let preForm = {}, id;
            preForm.order_limit = res.data.order_limit;
            preForm.province_limit = res.data.province_limit;
            preForm.communitiy_limit = res.data.communitiy_limit;
            preForm.public_password = res.data.public_password;
            id = res.data.id;
            this.setState({
                preForm: preForm,
                id
            }, () => {
                this.props.form.setFieldsValue({
                    ...preForm
                });
            })
        }).catch((res) => {
            Modal.error({
                title: "提示",
                content: res.message
            })
        })
    }

    reset = () => {
        const { preForm } = this.state;
        this.props.form.setFieldsValue({
            ...preForm
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                this.updateSystem(values);
            }
        });
    }

    updateSystem = (values) => {
        const { order_limit, province_limit, communitiy_limit,public_password } = values;
        let id = this.state.id;
        axios.ajaxPost({
            url: "/updateSystem",
            data: {

                    order_limit,
                    province_limit,
                    communitiy_limit,
                    public_password,


            }
        }, true).then((res) => {
            let preForm = {};
            preForm.order_limit = values.order_limit;
            preForm.province_limit = values.province_limit;
            preForm.communitiy_limit = values.communitiy_limit;
            preForm.public_password = values.public_password;
            this.setState({
                preForm: preForm,
            }, () => {
                notification["success"]({
                    message: '提示',
                    description: '设置成功！',
                });
            })
        }).catch((res) => {
            Modal.error({
                title: "提示",
                content: res.message
            })
        })
    }

    render() {
        const {
            getFieldDecorator
        } = this.props.form;

        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Item
                    label="订单数"
                    {...popoverFormItemLayout}
                >
                    <Row>
                        {getFieldDecorator('order_limit', {
                            rules: [{ required: true, message: '请填写订单数!' }],
                        })(
                            <InputNumber max={6} min={0} style={{ width: 150 }} />
                        )}
                        <span style={{ marginLeft: 20 }}>条 (例：0-6)</span>
                    </Row>

                </Form.Item>
                <Form.Item
                    label="省份数"
                    {...popoverFormItemLayout}
                >
                    <Row>
                        {getFieldDecorator('province_limit', {
                            rules: [{ required: true, message: '请填写省份数!' }],

                        })(
                            <InputNumber max={5} min={0} style={{ width: 150 }} />
                        )}
                        <span style={{ marginLeft: 20 }}>条 (例：0-5)</span>
                    </Row>
                </Form.Item>
                <Form.Item
                    label="社区数"
                    {...popoverFormItemLayout}
                >
                    <Row>
                        {getFieldDecorator('communitiy_limit', {
                            rules: [{ required: true, message: '请填写社区数!' }],

                        })(
                            <InputNumber max={5} min={3} style={{ width: 150 }} />
                        )}
                        <span style={{ marginLeft: 20 }}>条 (例：3-5)</span>
                    </Row>
                </Form.Item>
                <Form.Item
                    label="共用账号密码"
                    {...popoverFormItemLayout}
                >
                    <Row>
                        {getFieldDecorator('public_password', {
                            rules: [{ required: true, message: '请填写共用账号密码!' }],

                        })(
                            <InputNumber  style={{ width: 150 }} />
                        )}
                    </Row>
                </Form.Item>
                <Form.Item {...popoverFormItemLayout} style={{ marginTop: 50, marginLeft: 250 }} >
                    <Button onClick={this.reset} style={{ marginRight: 20 }}>重置</Button>
                    <Button type="primary" htmlType="submit" >确认</Button>
                </Form.Item>
            </Form>
        );
    }
}
const WrappedHorizontalLoginForm = Form.create()(HorizontalLoginForm);


class IntelligentCommunity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabPosition: 1,
        }
    }

    componentDidMount() {

    }

    changeTabPosition = (tabPosition) => {
        this.setState({ tabPosition });
    }



    render() {
        const { tabPosition } = this.state;
        return (
            <Content>
                <Row className="margin-bottom-15">
                    <Col span={4}>
                        <h2 className="content-title"><strong>系统设置</strong></h2>
                    </Col>
                </Row>

                {
                    tabPosition === 1 ? <WrappedHorizontalLoginForm  {...this.props} />
                        : ''
                }
            </Content>
        )
    }
}

export default IntelligentCommunity;