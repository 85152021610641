import React, { Component } from "react";
import { Button } from 'antd';
import $util from "@/utils/utils";

class RouterButton extends Component {
  constructor(props) {
    super(props);

    let permsession_module_name = $util.getCookie("permsession_module") ? $util.getCookie("permsession_module").split(",") : "";
    let userName = $util.getCookie("login_name");
    this.state = {
      isOperate: permsession_module_name.includes("operate"),
      isSmart_Community: permsession_module_name.includes("smart_community"),
      userName: userName === "管理员" ? true : false
    }
  }
  state = {
  };
  render() {
    const { isOperate, isSmart_Community, userName } = this.state;
    return (
      <div>
        {isSmart_Community && <Button onClick={() => this.props.history.push('/operationalData')} style={{ "marginRight": "30px" }}>智慧城市大屏</Button>}
        {isOperate && userName && <Button onClick={() => this.props.history.push('/intelligentCommunity')} style={{ "marginRight": "30px" }}>运营监控大屏-管理员</Button>}
        {isOperate && !userName && <Button onClick={() => this.props.history.push('/IntelligentCommunityNormal')}>运营监控大屏-普通</Button>}
      </div>
    )
  }
}

export default RouterButton;