import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import $util from "@/utils/utils.js";


class App extends Component {
  constructor(props) {
    super(props);
    let userName = $util.getCookie("login_name");
    this.state = {
        userName
    };
}

componentWillMount() {
  console.log('APP')
    const { userName } = this.state;
    // if (!userName) {
    //     this.props.history.push("/login")
    // }
}
  render() {
    return (
      <div className="App">
        {this.props.children}
      </div>
    );
  }
}



export default withRouter(App);
