import React,{ Component } from "react";
import './index.less';
import {Wresize} from "../../utils/utils";
import ReactEcharts from 'echarts-for-react';
require('../../utils/macarons');

class eChart extends Component{
    constructor(props) {
        super(props);
        this.state={
            height:this.props.height,
            width:this.props.width,
            options:{}
        };
    }
    state={
    };
    componentWillMount(){
        this.getOption();
    }
    componentDidMount(){
    }
    componentWillReceiveProps(nextProps, nextState){
    }
    getOption(){
        this.state.options = {
            backgroundColor:'transparent',
            tooltip : {
                trigger: 'axis',
                show:false
            },
            grid:{
                left:'15%'
            },
            toolbox: {
                show : false,
                feature : {
                    mark : {show: true},
                    dataView : {show: true, readOnly: false},
                    magicType : {show: true, type: ['line', 'bar', 'stack', 'tiled']},
                    restore : {show: true},
                    saveAsImage : {show: true}
                }
            },
            calculable : true,
            xAxis : {
                data: this.props.costAxis,
            },
            yAxis : [
                {
                    minInterval: 1,
                    type : 'value',
                    splitLine: {
                        show: true,
                        lineStyle:{
                            color:["#1d3454"]
                        }
                    },
                    axisTick:{
                        show:false
                    },
                }
            ],
            series : [
                {
                    name:'充电曲线',
                    type:'bar',
                    stack: '充电次数',
                    symbol:"none",
                    data:this.props.listData
                }
            ]
        };

    }
    render(){
        this.getOption();
        return (
            <ReactEcharts option={this.state.options} theme={"macarons"} style={{ width:this.state.width, height:this.state.height }}>
            </ReactEcharts>
        )
    }
}

export default eChart;