import React, { Component, Fragment } from "react";
import './index.less';
import axios from "@/axios";
import $pars from "@/pars/pars.js";
import $util from "@/utils/utils.js";
import { Steps, Button, message, Form, Icon, Input, Modal, notification } from 'antd';
import { setInterval } from "timers";

const Step = Steps.Step;

const steps = [{
    title: '填写账号',
    content: 'First-content',
},
// {
//     title: '验证码',
//     content: 'Second-content',
// },
{
    title: '设置密码',
    content: 'Second-content',
}, {
    title: '完成',
    content: 'Last-content',
}];

let Timer = null;

class NormalLoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login_name: "admin",

        }
    };

    componentDidMount() {
        this.clickGraphCaptcha();
    };



    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                this.next(values);
            }
        });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('两次密码不一样，请重试');
        } else {
            callback();
        }
    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    }

    //切换图片验证码的ajax
    clickGraphCaptcha = () => {
        let captchaUrl = $pars.ajaxBaseUrl + "/api/getIdentifyingCode";
        this.refs.graphCaptchaImgPwd.src = captchaUrl;
    };

    next = (values) => {
        const { current, time } = this.props;
        if (current === 0) {
            axios.ajaxPost({
                url: "/sendMobileCode",
                data: {
                    login_name: values.login_name,
                    validation_code: values.validation_code,
                }
            }, false).then((res) => {
                notification["success"]({
                    message: '提示',
                    description: '短信验证码已经发送至您的手机，请查收！',
                });
                this.setState({
                    login_name: values.login_name
                }, () => {
                    this.props.addCurrent();
                })
            }).catch((res) => {
                Modal.error({
                    title: "提示",
                    content: res.message
                })
            })
        } else if (current === 1) {
            const { login_name } = this.state;
            axios.ajaxPost({
                url: "/resetPassword",
                data: {
                    login_name: login_name,
                    password: values.password,
                    validation_code: values.validationSms_code,
                }
            }, false).then((res) => {
                this.props.addCurrent();
                notification["success"]({
                    message: '提示',
                    description: '修改密码成功成功，5S之后自动跳转登录页面！',
                });
            }).catch((res) => {
                Modal.error({
                    title: "提示",
                    content: res.message
                })
            })
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        let { current, time } = this.props;
        return (
            <Form className="login-form">
                {
                    current === 0 &&
                    <Fragment>
                        <Form.Item className="inpbox">
                            {getFieldDecorator('login_name', {
                                rules: [{ required: true, message: '请输入账号' }],
                            })(
                                <Input width={150} autocomplete="off" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="请输入账号" />
                            )}
                        </Form.Item>
                        <Form.Item className="inpbox">
                            {getFieldDecorator('validation_code', {
                                rules: [{ required: true, message: '请输入验证码' }],
                            })(
                                <Input width={150} autocomplete="off" prefix={<Icon type="check-circle" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="请输入验证码" />
                            )}
                            <img
                                ref="graphCaptchaImgPwd"
                                className="captcha-img"
                                src=""
                                alt="验证码"
                                onClick={() => this.clickGraphCaptcha()}
                            />
                        </Form.Item>

                    </Fragment>
                }
                {
                    current === 1 &&
                    <Fragment>
                        <Form.Item className="inpbox">
                            {getFieldDecorator('validationSms_code', {
                                rules: [{ required: true, message: '请输入短信验证码' }],
                            })(
                                <Input width={150} maxLength={6} autocomplete="off" prefix={<Icon type="check-circle" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="请输入短信验证码" />
                            )}
                        </Form.Item>
                        <Form.Item className="inpbox">
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: '请输入密码' }, {
                                    validator: this.validateToNextPassword,
                                }],
                            })(
                                <Input width={254} autocomplete="off" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="请输入密码" />
                            )}
                        </Form.Item>
                        <Form.Item className="inpbox">
                            {getFieldDecorator('confirm', {
                                rules: [{ required: true, message: '请确认密码' }, {
                                    validator: this.compareToFirstPassword,
                                }],
                            })(
                                <Input width={254} autocomplete="off" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="请确认密码" />
                            )}
                        </Form.Item>
                    </Fragment>
                }
                {/* {
                    current === 2 &&
                    <Fragment>
                        <Form.Item className="inpbox">
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: '请输入密码' }],
                            })(
                                <Input width={254} prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="请输入密码" />
                            )}
                        </Form.Item>
                        <Form.Item className="inpbox">
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: '请输入密码' }],
                            })(
                                <Input width={254} prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="请输入密码" />
                            )}
                        </Form.Item>
                    </Fragment>
                } */}
                {
                    current === 2 &&
                    <div style={{ textAlign: "center" }}>
                        修改密码成功成功，{time}S之后自动跳转登录页面
                    </div>
                }
                {
                    current < steps.length - 1
                    && <Button type="primary" className="login-form-button" onClick={() => this.handleSubmit()}>下一步</Button>
                }
            </Form>

        );
    }
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);

class forgetpwd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            time: 5
        };
    }

    componentWillUnmount() {
        clearInterval(Timer);
    }

    addCurrent = () => {
        const { current, time } = this.state;
        let currentNext = current + 1;
        let timeNext = time;
        this.setState({ current: currentNext }, () => {
            if (currentNext === steps.length - 1) {
                Timer = setInterval(() => {
                    timeNext--;
                    this.setState({
                        time: timeNext
                    }, () => {
                        if (timeNext === 0) {
                            this.props.history.push("/home/operationalData");
                        }
                    })
                }, 1000)
            }
        });
    }

    render() {
        const { current, time } = this.state;
        return (
            <div className="body">
                <div className="container">
                    <img className="icon"
                        style={{ marginTop: 120, marginBottom: 80 }}
                        src={require("../../assets/newlogobyred.png")} alt="" />
                    <div className="forgetpwd">
                        <Steps current={current} style={{ marginBottom: 60 }} >
                            {steps.map(item => <Step key={item.title} title={item.title} />)}
                        </Steps>
                        <div className="steps-content">
                            <WrappedNormalLoginForm
                                wrappedComponentRef={(form) => this.form = form}
                                current={current}
                                addCurrent={this.addCurrent}
                                time={time}
                            />
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default forgetpwd;