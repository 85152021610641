import React, { Component } from "react";
import { HashRouter, BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import PrivateRoute from "@/router/PrivateRoute"
import App from './App';
import Login from '@/pages/login/index';
import ForgetPassword from '@/pages/forgetpwd/index';
import OperationalData from '@/pages/home/operationalData';
import setMap from '@/pages/setMap/setMap';
import Device from '@/pages/device/deviceList';
import Alarm from '@/pages/alarmLog/alarmList';
import Video from '@/pages/monitorVideo/monitorVideo';
import Add from '@/pages/device/add';
import Edit from '@/pages/device/edit';
import bindDevice from '@/pages/device/bindDevice';
import IntelligentCommunity from '@/pages/intelligentCommunity/index';
import IntelligentCommunityNormal from '@/pages/intelligentCommunityNormal/index';
import User from '@/pages/user/user';
import Check from '@/pages/user/check';
import SetRole from '@/pages/user/set';

import PictureSit from '@/pages/picture/pictureSit';
import PictureUpload from '@/pages/picture/pictureUpload';

import Setting from '@/pages/setting/setting';
import NoMatch from '@/components/NoMatch';

import chinaMap from '@/pages/map/index';
import $util from "@/utils/utils";
import Admin from './admin';

import RouterButton from '@/components/RouterButton';

class IRouter extends Component {
    constructor(props) {
        super(props);
        //"common,user,operate,smart_community,system"
        // let permsession_module_name = $util.getCookie("permsession_module_name") ? Object.keys(JSON.parse($util.getCookie("permsession_module_name"))) : "";
        let permsession_module_name = $util.getCookie("permsession_module") ? $util.getCookie("permsession_module").split(",") : "";
        // console.log(permsession_module_name);
        this.state = {
            isHome: permsession_module_name.includes("operate") || permsession_module_name.includes("smart_community"),
            isUser: permsession_module_name.includes("user"),
            isSeting: permsession_module_name.includes("system"),
            isDevice: permsession_module_name.includes("user"),
            isAlarm:permsession_module_name.includes("user"),
            isVideo:permsession_module_name.includes("user"),
            url:$util.getCookie("is_public")?'/home/operationalData':'/home/intelligentCommunity'
        }
    }

    componentDidMount() {

    }
    render() {
        const { isHome, isUser, isSeting ,isDevice,isAlarm,url} = this.state;
        return (
            <BrowserRouter>
                <App>
                    <Switch>
                        {/* 登录 */}
                        <Route path="/login" component={Login} />
                        {/* 忘记密码 */}
                        <Route path="/forgetpwd" component={ForgetPassword} />
                        {/* 运营数据大屏 */}
                        <PrivateRoute isShow={true} path="/operationalData" component={chinaMap} />
                        {/* 智慧社区大屏 */}
                        <PrivateRoute isShow={true} path="/intelligentCommunity" component={IntelligentCommunity} />
                        <PrivateRoute isShow={true} path="/setMap" component={setMap} />
                        <PrivateRoute isShow={true} path="/IntelligentCommunityNormal" component={IntelligentCommunityNormal} />
                        <Route path="/" render={() =>
                            <Admin>
                                <Switch>
                                    {/* 路由 */}
                                    {/* 首页运营数据 */}
                                    <PrivateRoute isShow={isHome} path="/home/test" component={RouterButton} />
                                    <PrivateRoute isShow={isHome} path="/home/operationalData" component={chinaMap} />
                                    <PrivateRoute isShow={isHome} path="/home/setMap" component={setMap} />
                                    {/* 首页智慧社区 */}
                                    <PrivateRoute isShow={isHome} path="/home/intelligentCommunity" component={IntelligentCommunity} />
                                    <PrivateRoute isShow={isHome} path="/home/IntelligentCommunityNormal" component={IntelligentCommunityNormal} />
                                    {/* 用户管理 查看 */}
                                    <PrivateRoute path="/user/check" isShow={isUser} component={Check} />
                                    {/* 用户管理 设置权限 */}
                                    <PrivateRoute path="/user/set" isShow={isUser} component={SetRole} />
                                    {/* 用户管理 用户一览 */}
                                    <PrivateRoute isShow={isUser} path="/user" component={User} />
                                    {/* 图片管理 图片设置 */}
                                    {/* <Route path="/picture/sit" component={PictureSit} /> */}
                                    {/* 图片管理 图片上传 */}
                                    {/* <Route path="/picture/upload" component={PictureUpload} /> */}
                                    {/* 系统设置 */}
                                    <PrivateRoute path="/setting" isShow={isSeting} component={Setting} />
                                    {/*绑定烟感设备*/}
                                    <PrivateRoute path="/device/add" isShow={isDevice} component={Add} />
                                    <PrivateRoute path="/device/edit" isShow={isDevice} component={Edit} />
                                    <PrivateRoute path="/device/bindDevice" isShow={isDevice} component={bindDevice} />
                                    <PrivateRoute path="/device" isShow={isDevice} component={Device} />
                                    <PrivateRoute path="/alarmLog" isShow={isAlarm} component={Alarm} />
                                    <PrivateRoute path="/video" isShow={isAlarm} component={Video} />
                                    {/* 默认显示 首页运营数据 */}
                                    <Redirect to={url}/>
                                </Switch>
                            </Admin>
                        } />
                        <Route path='*' component={NoMatch} />
                    </Switch>
                </App>
            </BrowserRouter>
        )
    }
}

export default IRouter;