import React, { Component } from 'react';

import {
    Layout, Divider, Row, Col, Form,
    notification, Table, Input, Button,
    DatePicker, Select, Modal
} from 'antd';
import axios from "@/axios";

import PropTypes from 'prop-types';
const confirm = Modal.confirm;

const { Content } = Layout;
const Option = Select.Option;
const FormItem = Form.Item;

//烟感设备搜索条件
class SearchForm extends React.Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.getWarningList(values,'first');
            }
        });
    }

    render() {
        let { getFieldDecorator } = this.props.form;
        return (
            <Form layout="inline" onSubmit={this.handleSubmit}>
                <FormItem label="社区名称" >
                    {getFieldDecorator('communitiy_name', {
                        initialValue: ""
                    })(
                        <Input style={{ width: 150 }} maxLength="50" placeholder="" />
                    )}
                </FormItem>
                <FormItem label="设备编号" >
                    {getFieldDecorator('evse_no', {
                        initialValue: ""
                    })(
                        <Input style={{ width: 150 }} maxLength="50" placeholder="" />
                    )}
                </FormItem>
                <FormItem label="报警状态" >
                    {getFieldDecorator('status', {
                        initialValue: ""
                    })(
                        <Select style={{ width: 130 }}>
                            <Option value={""}>全部</Option>
                            <Option value={1}>正常</Option>
                            <Option value={2}>报警</Option>

                        </Select>
                    )}
                </FormItem>

                <FormItem style={{ float: "right" }}>
                    <Button type="primary" htmlType="submit">搜索</Button>
                </FormItem>
            </Form>
        )
    };
};

SearchForm.contextTypes = {
    store: PropTypes.object,
};
let SearchFormNotesComponent = Form.create()(SearchForm);

class Alarm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [

            ],
            pagination: {
                limit: 10,
                page_now: 1,
                total: 0,
            },
            search_by: {
                communitiy_name:"",
                evse_no:"",
                status:"",
            }
        };
    };

    //组件挂在之后执行的生命周期函数
    componentDidMount() {
        //初始化搜索
        this.getWarningList();
    };

    handleTableChange = (pagination, filters, sorter) => {
        let { current, pageSize } = pagination;
        let paginations = this.state.pagination;
        paginations.page_now = current;
        paginations.limit = pageSize;
        this.setState({
            paginations
        }, () => {
            this.getWarningList();
        })
    };

    //获取列表
    getWarningList = (values,first) => {
        let pagination = this.state.pagination;
        if(first){
            pagination.page_now = 1;
        }

        let data = {};
        data.limit = pagination.limit;
        data.page = pagination.page_now;
        if (values) {
            data.communitiy_name = values.communitiy_name;
            data.evse_no = values.evse_no;
            data.status = values.status;

        }
        axios.ajaxPost({
            url: "/getWarningList?page=" + pagination.page_now,
            data: data
        }, true).then((res) => {
            console.log(res);
            let pagination = this.state.pagination;
            let { current_page, total } = res.meta;
            pagination.Page = current_page;
            pagination.total = total;

            this.setState({
                data: res.data,
                pagination
            })

        }).catch((res) => {
            Modal.error({
                title: "提示",
                content: res.message
            })
        })
    };

    getColumns = () => {
        let columns = [{
            title: '社区名称',
            dataIndex: "communitiy_name",

        },{
            title: '社区地址',
            dataIndex: "address",

        },{
            title: '烟感名称',
            dataIndex: "smoke_name",

        },{
            title: '设备编号',
            dataIndex: 'evse_no',
        }, {
            title: '报警类型',
            dataIndex: 'type_name',
        }, {
            title: '状态',
            dataIndex: 'status_name',
        }, {
            title: '报警时间',
            dataIndex: "created_at",
        }];
        return columns;
    };

    //跳转详情页
    handleRouterDetail = (record, path,type) => {
        record.type = type;
        this.props.history.push({
            pathname: `/device/${path}`,
            state: record,
        });
    };
    //静音操作
    changeStatus = (record) => {
        axios.ajaxPost({
            url: "/stopSmokeWarning",
            data:{
                simple_sn: record.simple_sn
            }
        }, true).then((res) => {
            window.location.reload();
        }).catch((res) => {
            Modal.error({
                title: "提示",
                content: res.message
            })
        })
    };
    //跳转添加页
    handleRouterAdd = (record, path) => {
        this.props.history.push({
            pathname: `/device/${path}`,
            state: record,
        });
    };

    render() {
        let { data, pagination } = this.state;
        const columns = this.getColumns();
        return (
            <Content>
                <Row className="margin-bottom-15">
                    <Col span={4}>
                        <h2 className="content-title"><strong>报警日志</strong></h2>
                    </Col>

                </Row>
                <Row className="margin-bottom-15">
                    <Col span={24}>
                        <SearchFormNotesComponent
                            getWarningList={this.getWarningList}
                            wrappedComponentRef={(form) => this.SearchForm = form}
                        >
                        </SearchFormNotesComponent>
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                    <Col span={24}>
                        <Table
                            rowKey={(record, index) => index}
                            columns={columns}
                            dataSource={data}
                            bordered
                            pagination={{
                                total: parseInt(pagination.total, 0),
                                current: parseInt(pagination.page_now, 0),
                                pageSize: parseInt(pagination.limit, 0),

                                pageSizeOptions: ['5', '10', '20', '50', '100'],
                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
                                showQuickJumper: true,
                            }}
                            onChange={this.handleTableChange}
                        />
                    </Col>
                </Row>
            </Content>
        )
    }
}

Alarm.contextTypes = {
    store: PropTypes.object,
};

export default Alarm;