import React, { Component } from "react";

import Histogram from '../../components/histogram'
import LineChart from '../../components/lineChart'
import PieChartEquipment from '../../components/pieChartEquipment'
import MainTitle from '../../components/main_title'
import MsgPush from '../../components/msgPush'
import CommunityRank from '../../components/communityRank'
import GaodeMap from '../../components/gaodeMap'
import $util from "@/utils/utils.js";
import axios from "@/axios";
import './index.less';

let isQp = false;
class intelligentCommunityNormal extends Component {
  constructor(props) {
    super(props);

    let path = this.props.match.path;
    let isInner = ~path.indexOf("home") ? true : false;
    
    this.state = {
      isInner:isInner,
      leftChart: {
        width: "25rem",
        height: "29vh"
      },
      barChart: {
        width: "25rem",
        height: "37vh"
      },
      pirChart: {
        width: "12rem",
        height: "15.3vh"
      },
      commonitor: [],
      chargeShow: 3,
      totalPower: [],
      totalIncome: [],
      incomexAxis: [],
      totalPowerShow: 3,
      totalPowerxAxis: [],
      costData: [],
      costAxis: [],
      homeCountsData: {
          all_count:0,//设备总数
          line_count:0,//在线总数
          offline_count:0,//离线总数
          total_active_num: 0,//日活
          total_newly_num: 0,//新增用户
          total_city_number: 0,//城市
          total_gun_number: 0,//充电桩数量
          total_online_sum: 0,//移动总收入
          total_income: 0,//当日收入
          total_power: 0,//总用电量
          total_pre_power: 0,//昨日电量
          online_total_evse_number: 0,//在线桩口数
          offline_total_evse_number: 0,//离线桩数量
          total_evse_number: 0,//枪口数
      },
      alertData: {},
      timeInterval: "",
      refresh:"",
      evseData: {},
      rootName:$util.getCookie("login_name") !=="null" ? $util.getCookie("login_name"): "管理员",
    };
    let width = window.innerWidth;
    if (!!~path.indexOf("home")) {
      this.state.leftChart.width = "23rem";
      this.state.leftChart.height = "26.16vh";
      this.state.gaodeMapHeight = "26rem";
      this.state.barChart.width = '23rem';
      this.state.barChart.height = '32vh';
      if (1750 < width && width < 2250) {
        this.state.leftChart.width = "20rem";
        this.state.leftChart.height = "26.16vh";
        this.state.barChart.width = '20rem';
        this.state.barChart.height = '32vh';
      }
      if (1300 < width && width < 1750) {
        this.state.leftChart.width = "17rem";
        this.state.leftChart.height = "26.16vh";
        this.state.gaodeMapHeight = "24rem";
        this.state.barChart.width = '20rem';
      }
      if (width === 1750) {
        this.state.leftChart.width = "17rem";
        this.state.leftChart.height = "26.16vh";
        this.state.gaodeMapHeight = "24rem";
        this.state.barChart.width = '19rem';
      }
      if (width <= 1300) {
        this.state.barChart.width = '19rem';
        this.state.leftChart.width = "16rem";
        this.state.leftChart.height = "26.16vh";
        this.state.gaodeMapHeight = "20rem";
      }
      this.state.domId = "intelligentCommunityNormalInner";
      this.state.gaodeMapId = "mapInner";
    } else {
      this.state.gaodeMapHeight = "29rem";
      this.state.leftChart.width = "25rem";
      this.state.leftChart.height = "29vh";
      this.state.domId = "intelligentCommunityNormal";
      this.state.gaodeMapId = "mapOuter";
    }
  }

  //放大全屏
  launchFullscreen = (path) => {
    let element = document.documentElement;
    try {
      if (element.requestFullscreen) {
        isQp = true;
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        isQp = true;
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        isQp = true;
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        isQp = true;
        element.msRequestFullscreen();
      }
    }catch (e) {

    }
    this.props.history.push(path)
  }
  // 退出全屏		
  exitFullscreen = (path) => {
    try {
      if (isQp && document.exitFullscreen) {
        isQp = false;
        document.exitFullscreen();
      } else if ( isQp && document.mozCancelFullScreen) {
        isQp = false;
        document.mozCancelFullScreen();
      } else if (isQp && document.webkitExitFullscreen) {
        isQp = false;
        document.webkitExitFullscreen();
      }
    }catch (e) {

    }
    this.props.history.push(path)
  }

  render() {
    const { isInner } = this.state;
    return (
      <div className={"operationalData"} id={this.state.domId}>
        <div className={"map_top"}>
          <div className={"map_top_left"}>
            <span style={{ "marginRight": "2rem" }}>账号名：{this.state.rootName}</span>
            <span id="timeClock"></span>
          </div>
          <div className={"map_top_mid"}>运营数据监控系统1</div>
          <div className={"map_top_right"}>

              <div onClick={()=> this.siteMapDetail()}>站点地图</div>

            { //有home代表在后台里面，这里不显示 先退出全屏 进入后台按钮
              !isInner && <div onClick={() => this.exitFullscreen("/home/intelligentCommunityNormal")}>进入后台</div>
            }
            { //有home代表在后台里面，这里显示放大全屏
              isInner && <div onClick={()=>this.launchFullscreen('/intelligentCommunityNormal')}>全屏</div>
            }
          </div>
        </div>
        <div className={"map_body"}>
          <div>
            <div className={"map_body_left"}>
              <div className={"left_chart_bg"}>
                <span className={"chart_title_gold"}  style={{"paddingTop":"1rem"}}>设备状态</span>
                <PieChartEquipment width={this.state.leftChart.width} height={this.state.leftChart.height} line_count={this.state.homeCountsData.line_count} offline_count={this.state.homeCountsData.offline_count} all_count={this.state.homeCountsData.all_count} />
              </div>
            </div>
            <div className={"map_body_left"}>
              <div className={"left_chart_bg"}>
                <span className={"chart_title_green"}>充电曲线</span>
                <LineChart width={this.state.leftChart.width} height={this.state.leftChart.height} listData={this.state.costData} costAxis={this.state.costAxis} />
              </div>
            </div>
            <div className={"map_body_left"}>
              <MsgPush />
            </div>
          </div>
          <div className={"map_titles"}>
            <div className={"title_container"}>
              <MainTitle color1={"#0665b1"} color2={"#073361"} title="移动总收入" value={this.state.homeCountsData.total_online_sum} unit="万元" />
              <MainTitle color1={"#0c8c7e"} color2={"#0a3e57"} title="当日收入" value={this.state.homeCountsData.total_income} unit="万元" />
              <MainTitle color1={"#9c8638"} color2={"#2c3c44"} title="总用电量" value={Number(this.state.homeCountsData.total_power).toFixed(2)} unit="万度" />
              <MainTitle color1={"#8e2041"} color2={"#282144"} title="昨日用电量" value={this.state.homeCountsData.total_pre_power} unit="万度" />
              <MainTitle color1={"#3196bc"} color2={"#103e63"} title="累计充电数" value={this.state.homeCountsData.total_charge_times} unit="万次" />
            </div>

            <div id={this.state.gaodeMapId}>
              <GaodeMap alertData={this.state.alertData} height={this.state.gaodeMapHeight} evseData={this.state.evseData} ref="myMap"/>
            </div>
            <CommunityRank listData={this.state.commonitor} />
          </div>
          <div>
            <div className={"map_body_right"}>
              <div className={"frames_container"}>
                <div className={"frames frame_purple"}>
                  <div className={"frames_num"}>{this.state.homeCountsData.total_city_number}<span>个</span></div>
                  <div className={"frames_name"}>城市</div>
                </div>
                <div className={"frames frame_cyan"}>
                  <div className={"frames_num"}>{this.state.homeCountsData.total_evse_number}<span>个</span></div>
                  <div className={"frames_name"}>充电站</div>
                </div>
                <div className={"frames frame_red"}>
                  <div className={"frames_num"}>{this.state.homeCountsData.total_gun_number}<span></span></div>
                  <div className={"frames_name"}>插座数量</div>
                </div>
              </div>
            </div>

            <div className={"map_body_right"}>
              <div className={"histogram_chart_bg"}>
                <span className={"chart_title_orange_right"}>月收入柱状图</span>
                <div className={"bar_chart_btn"}>
                  <div onClick={this.chargeIndex.bind(this, 1)} className={this.state.chargeShow === 1 ? "chart_btn_active" : ""}>月收入</div>
                  <div onClick={this.chargeIndex.bind(this, 2)} className={this.state.chargeShow === 2 ? "chart_btn_active" : ""}>周收入</div>
                  <div onClick={this.chargeIndex.bind(this, 3)} className={this.state.chargeShow === 3 ? "chart_btn_active" : ""}>日收入</div>
                </div>
                <Histogram width={this.state.barChart.width} height={this.state.barChart.height} chargeData={this.state.totalIncome} chargeShow={this.state.chargeShow} incomexAxis={this.state.incomexAxis} name="收入" />
              </div>
            </div>
            <div className={"map_body_right"}>
              <div className={"histogram_chart_bg"}>
                <span className={"chart_title_lightgreen_yellow"}>用电量柱状图</span>
                <div className={"bar_chart_btn"}>
                  <div onClick={this.totalPowerIndex.bind(this, 1)} className={this.state.totalPowerShow === 1 ? "chart_btn_active" : ""}>月用电</div>
                  <div onClick={this.totalPowerIndex.bind(this, 2)} className={this.state.totalPowerShow === 2 ? "chart_btn_active" : ""}>周用电</div>
                  <div onClick={this.totalPowerIndex.bind(this, 3)} className={this.state.totalPowerShow === 3 ? "chart_btn_active" : ""}>日用电</div>
                </div>
                <Histogram width={this.state.barChart.width} height={this.state.barChart.height} chargeData={this.state.totalPower} chargeShow={this.state.totalPowerShow} incomexAxis={this.state.totalPowerxAxis} name="用电" />
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
  componentDidMount() {
    document.addEventListener("fullscreenchange", this.fullscreenchange, false);
//moz下监听 mozfullscreenchange 事件，判断document.mozFullScreen属性
    document.addEventListener("mozfullscreenchange", this.mozfullscreenchange, false);
//chrome下监听webkitfullscreenchange事件，判断 document.webkitIsFullScreen 属性
    document.addEventListener("webkitfullscreenchange", this.webkitfullscreenchange, false);
    let ua = navigator.userAgent;
    if(/firefox/i.test(ua)){
      this.mozfullscreenchange();
    }else if(/chrome/i.test(ua)){
      this.webkitfullscreenchange();
    }else{
      this.fullscreenchange();
    }
    //小区行数据
    this.getCommonitor();
    //this.getHourCost();
    //this.getEvseCost();
    //this.getDevice();
    // this.getDayCost().then((res) => {
    //   if (res.code === 200) {
    //     let totalIncome = [];
    //     let totalPower = [];
    //     let xAxis = [];
    //     res.data.forEach((item) => {
    //       totalIncome.push(item.total_income);
    //       totalPower.push(parseFloat(item.total_power).toFixed(2));
    //       xAxis.push(item.day);
    //     });
    //     this.setState({ "totalIncome": totalIncome });
    //     this.setState({ "totalPower": totalPower });
    //     this.setState({ "totalPowerxAxis": xAxis });
    //     this.setState({ "incomexAxis": xAxis });
    //   }
    // }).catch((res) => {
    //   console.log(res);
    // });
    this.setState({
      "timeInterval": setInterval(() => {
        this.showTime();
      }, 1000)
    })
  }
  async totalPowerIndex(i) {
    this.setState({ "totalPowerShow": i });
    let result = "";
    if (i === 1) {//月
      result = await this.getMonthCost();
    } else if (i === 2) {//周
      result = await this.getWeekCost();
    } else if (i === 3) {//日
      result = await this.getDayCost();
    }
    if (result.code === 200) {
      let totalPower = [];
      let xAxis = [];
      result.data.forEach((item) => {
        totalPower.push(item.total_power);
        if (i === 3) {
          xAxis.push(item.day);
        }
      });
      this.setState({ "totalPowerxAxis": xAxis });
      this.setState({ "totalPower": totalPower });
    }
  }
  async chargeIndex(i) {
    this.setState({ "chargeShow": i });
    let result = "";
    if (i === 1) {//月
      result = await this.getMonthCost();
    } else if (i === 2) {//周
      result = await this.getWeekCost();
    } else if (i === 3) {//日
      result = await this.getDayCost();
    }
    if (result.code === 200) {
      let totalIncome = [];
      let xAxis = [];
      result.data.forEach((item) => {
        totalIncome.push(item.total_income);
        if (i === 3) {
          xAxis.push(item.day);
        }
      });
      this.setState({ "incomexAxis": xAxis });
      this.setState({ "totalIncome": totalIncome });
    }
  }
  fullscreenchange(){
    isQp = (document.fullscreen);
  }
  mozfullscreenchange(){
    isQp = (document.mozFullScreen);
  }
  webkitfullscreenchange(){
    isQp = (document.webkitIsFullScreen);
  }
  getMonthCost() {
    return axios.ajaxGet({
      url: "/getMonthCost"
    })
  }
  getWeekCost() {
    return axios.ajaxGet({
      url: "/getWeekCost"
    })
  }
  getDayCost() {
    return axios.ajaxGet({
      url: "/getDayCost"
    })
  }
  getCommonitor() {
    axios.ajaxGet({
      url: "/getCommonitor"
    }).then((res) => {
      if (res.code == 200) {
        this.setState({ "commonitor": res.message.slice(0, 5) })
      }
    }, true)
  }
  getHourCost() {
    axios.ajaxGet({
      url: "/getHourCost"
    }).then((res) => {
      if (res.code == 200) {
        let costData = [];
        let costAxis = [];
        res.data.forEach((item) => {
          costAxis.push(item.hour + "h");
          costData.push(item.total_charge_times)
        });
        this.setState({ "costAxis": costAxis });
        this.setState({ "costData": costData });
      }
    }, true)
  }
  //获取设备数据
  getEvseCost() {
    axios.ajaxGet({
      url: "/getEvseCost"
    }).then((res) => {
      if (res.code == 200) {
        this.setState({ "homeCountsData": res.data })
      }
    }, true)
  }
    //站点地图
    siteMapDetail = () => {
        const { isInner } = this.state;
        let path = "";
        if(!isInner){
            path = "setMap";
        }else{
            path = "home/setMap";
        }

        this.props.history.push({
            pathname: `/${path}`
        });
    };

  showTime() {
    //创建Date对象
    var today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth() + 1;
    var day = today.getDate();
    var hours = today.getHours();
    var minutes = today.getMinutes();
    var seconds = today.getSeconds();
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    var str = hours + ":" + minutes + ":" + seconds + "   " + year + "-" + month + "-" + day;
    document.getElementById("timeClock").innerText = str;
  }
  getDevice() {
    //报警数据
    axios.ajaxGet({
      url: "/getEvseAll",
    }).then((res) => {
      if (res.code == 200) {
        this.setState({ "evseData": res.data });
        this.refs.myMap.clusterMarker();
      }
    }, true).catch((err) => {
      console.log(err);
    });
  }
  componentWillUnmount() {
    clearInterval(this.state.timeInterval);
    document.removeEventListener("fullscreenchange", this.fullscreenchange, false);
//moz下监听 mozfullscreenchange 事件，判断document.mozFullScreen属性
    document.removeEventListener("mozfullscreenchange", this.mozfullscreenchange, false);
//chrome下监听webkitfullscreenchange事件，判断 document.webkitIsFullScreen 属性
    document.removeEventListener("webkitfullscreenchange", this.webkitfullscreenchange, false);
  }
}

export default intelligentCommunityNormal;