import React, { Component } from 'react';

import {
    Layout, Divider, Row, Col, Form,
    notification, Table, Input, Button,
    DatePicker, Select, Modal
} from 'antd';
import { withRouter } from 'react-router-dom';
import axios from "@/axios";
import $pars from "@/pars/pars.js";
import $util from "@/utils/utils.js";
import moment from 'moment';
import PropTypes from 'prop-types';
const confirm = Modal.confirm;

const { Content } = Layout;
const Option = Select.Option;
const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

//发放记录表单
class SearchForm extends React.Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.getUserList(values,"first");
            }
        });
    }

    render() {
        let { getFieldDecorator } = this.props.form;
        return (
            <Form layout="inline" onSubmit={this.handleSubmit}>

                <FormItem label="根据账号" >
                    {getFieldDecorator('search_name', {
                        initialValue: ""
                    })(
                        <Input style={{ width: 150 }} maxLength="50" placeholder="" />
                    )}
                </FormItem>
                <FormItem label="手机号码" >
                    {getFieldDecorator('mobile', {
                        initialValue: ""
                    })(
                        <Input style={{ width: 150 }} maxLength="50" placeholder="" />
                    )}
                </FormItem>
                <FormItem label="角色" >
                    {getFieldDecorator('role_id', {
                        initialValue: ""
                    })(
                        <Select style={{ width: 130 }}>
                            <Option value={""}>全部</Option>
                            <Option value={5}>代理商</Option>
                            <Option value={4}>城市合伙人</Option>
                        </Select>
                    )}
                </FormItem>

                <FormItem style={{ float: "right" }}>
                    <Button type="primary" htmlType="submit">搜索</Button>
                </FormItem>
            </Form>
        )
    };
};

SearchForm.contextTypes = {
    store: PropTypes.object,
};
let SearchFormNotesComponent = Form.create()(SearchForm);

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [

            ],
            pagination: {
                limit: 5,
                page_now: 1,
                total: 0,
            },
            search_by: {
                search_name:"",
                mobile:"",
                role_id:"",
            }

        };
    };

    //组件挂在之后执行的生命周期函数
    componentDidMount() {
        //初始化搜索
        this.getUserList();
    };

    handleTableChange = (pagination, filters, sorter) => {
        let { current, pageSize } = pagination;
        let paginations = this.state.pagination;
        paginations.page_now = current;
        paginations.limit = pageSize;
        this.setState({
            paginations
        }, () => {
            this.getUserList();
        })
    };

    //获取列表
    getUserList = (values,first) => {
        
        let localPagination = JSON.parse($util.getLocalStorage("pagination"));
        let localSearch_by = JSON.parse($util.getLocalStorage("search_by"));
        let pagination = this.state.pagination;
        let search_by =  this.state.search_by;
        if(first){
            pagination.page_now = 1;
        }
        if(localPagination){
            pagination = localPagination;
            search_by = localSearch_by;
        }

        let data = {};
        data.limit = pagination.limit;
        data.page = pagination.page_now;
        if (values) {
            data.name = values.search_name;
            data.mobile = values.mobile;
            data.role = values.role_id;

        }
        axios.ajaxPost({
            url: "/getUserList",
            data:data
        }, true).then((res) => {
            let pagination = this.state.pagination;
            let { current_page, total } = res.meta;
            pagination.Page = current_page;
            pagination.total = total;

            this.setState({
                data: res.data,
                pagination,
                search_by
            },()=>{
                $util.delLocalStorage("pagination");
                $util.delLocalStorage("search_by");
            });
        }).catch((res) => {
            Modal.error({
                title: "提示",
                content: res.message
            })
        })
    }

    //禁用
    modUserStatus = (record) => {
        let mySelf = this;
        let id = record.id;
        let status = record.status === 1 ? '禁用' : '启用';
        confirm({
            title: `是否要${status}该账号?`,
            onOk() {
                axios.ajaxPost({
                    url: "/modUserStatus",
                    data: {
                        id: id,
                        do_type: status === "禁用" ? 2 : 1,
                    }
                }, true).then((res) => {
                    //请求成功获取下数据
                    mySelf.getUserList();
                }).catch((res) => {
                    Modal.error({
                        title: "提示",
                        content: res.message
                    })
                })
            },
            onCancel() { },
            okText: '确认',
            cancelText: '取消',
        });
    }

    getColumns = () => {
        let columns = [{
            title: '账号',
            dataIndex: "name",

        }, {
            title: '角色',
            dataIndex: 'roles[0].display_name',
        }, {
            title: '手机号',
            dataIndex: 'mobile',
        }, {
            title: '省',
            dataIndex: 'province_code_name',
        }, {
            title: '市',
            dataIndex: 'city_code_name',
        }, {
            title: '区',
            dataIndex: 'district_code_name',
        }, {
            title: '账号状态',
            dataIndex: 'status_name',
        }, {
            title: '最近访问时间',
            dataIndex: 'updated_at',
            // render: (text) => moment(text * 1000).format(DATE_FORMAT)
        }, {
            title: '操作',
            width: '170px',
            render: (text, record) => (
                <span>
                    <a onClick={() => this.handleRouterDetail(record, 'check')}>查看</a>
                    {/*<Divider type="vertical" />*/}
                    {/*<a onClick={() => this.handleRouterDetail(record, "set")}>设置</a>*/}
                    <Divider type="vertical" />
                    <a onClick={() => this.modUserStatus(record)}>{record.status === 1 ? '禁用' : '启用'}</a>
                </span>
            )
        }];
        return columns;
    };

    //跳转详情页
    handleRouterDetail = (record, path) => {
        const { pagination , search_by} = this.state;
        $util.setLocalStorage("pagination",JSON.stringify(pagination))
        $util.setLocalStorage("search_by",JSON.stringify(search_by))
        this.props.history.push({
            pathname: `/user/${path}`,
            state: record,
        });
    };

    render() {
        let { data, pagination } = this.state;
        const columns = this.getColumns();
        return (
            <Content>
                <Row className="margin-bottom-15">
                    <Col span={4}>
                        <h2 className="content-title"><strong>用户一览</strong></h2>
                    </Col>
                </Row>
                <Row className="margin-bottom-15">
                    <Col span={24}>
                        <SearchFormNotesComponent
                            getUserList={this.getUserList}
                            wrappedComponentRef={(form) => this.SearchForm = form}
                        >
                        </SearchFormNotesComponent>
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                    <Col span={24}>
                        <Table
                            rowKey={(record, index) => index}
                            columns={columns}
                            dataSource={data}
                            bordered
                            pagination={{
                                total: parseInt(pagination.total, 0),
                                current: parseInt(pagination.Page, 0),
                                pageSize: parseInt(pagination.limit, 0),

                                pageSizeOptions: ['5', '10', '20', '50', '100'],
                                // onShowSizeChange: this.onPageChange,
                                // onChange:this.onPageChange,
                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
                                // showSizeChanger: true,
                                showQuickJumper: true,
                            }}
                            onChange={this.handleTableChange}
                        />
                    </Col>
                </Row>
            </Content>
        )
    }
}

User.contextTypes = {
    store: PropTypes.object,
};

export default withRouter(User);
