import React, { Component } from "react";
import './montitorVideo.less'
// import { WebControl } from 'web-control'

import {
  Layout,
  Divider,
  Row,
  Col,
  Form,
  notification,
  Table,
  Input,
  Button,
  DatePicker,
  Select,
  Modal,
} from "antd";
import axios from "@/axios";
import PropTypes from "prop-types";

const confirm = Modal.confirm;

const { Content } = Layout;

class Alarm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      // 视频盒子的高度
      playWndHeight: "",
      // 视频盒子的宽度
      playWndWidth: "",
      oWebControl: null,
      initCount: 0,
      pubKey: "",
      cameraIndexCode: "", // 这里面是监控点编号
      objData: {
        appkey: "", //海康平台提供的appkey
        ip: "", //平台地址
        port: 443,
        playMode: 0, // 0 预览 1回放
        layout: "1x1", //页面展示的模块数【16】
        secret: "", //海康平台提供的secret
      },
    };
  }

  //组件挂在之后执行的生命周期函数
  componentDidMount() {
    this.initPlugin();
    
  }
  //组件销毁
  componentWillUnmount(){
    this.oWebControl.JS_DestroyWnd()
  }
  // 创建播放实例
  initPlugin() {
    let that = this;
    that.oWebControl = new window.WebControl({
      szPluginContainer: "playWnd1", // 指定容器id
      iServicePortStart: 15900, // 指定起止端口号，建议使用该值
      iServicePortEnd: 15900,
      szClassId: "23BF3B0A-2C56-4D97-9C03-0CB103AA8F11", // 用于IE10使用ActiveX的clsid
      cbConnectSuccess: function() {
        // 创建WebControl实例成功
        that.oWebControl
          .JS_StartService("window", {
            // WebControl实例创建成功后需要启动服务
            dllPath: "./VideoPluginConnect.dll", // 值"./VideoPluginConnect.dll"写死
          })
          .then(
            function() {
              // 启动插件服务成功
              that.oWebControl.JS_SetWindowControlCallback({
                // 设置消息回调
                cbIntegrationCallBack: that.cbIntegrationCallBack,
              });

              that.oWebControl
                .JS_CreateWnd("playWnd1", 1600, 800)
                .then(function() {
                  //JS_CreateWnd创建视频播放窗口，宽高可设定
                  that.init(); // 创建播放实例成功后初始化
                });
            },
            function() {
              // 启动插件服务失败
            }
          );
      },
      cbConnectError: function() {
        // 创建WebControl实例失败
        that.oWebControl = null;
        window.$("#playWnd1").html("插件未启动，正在尝试启动，请稍候...");
        window.WebControl.JS_WakeUp("VideoWebPlugin://"); // 程序未启动时执行error函数，采用wakeup来启动程序
        that.initCount++;
        if (that.initCount < 3) {
          setTimeout(function() {
            that.initPlugin();
          }, 3000);
        } else {
          window.$("#playWnd1").html("插件启动失败，请检查插件是否安装！");
        }
      },
      cbConnectClose: function(bNormalClose) {
        // 异常断开：bNormalClose = false
        // JS_Disconnect正常断开：bNormalClose = true
        console.log("cbConnectClose");
        that.oWebControl = null;
        window.$("#playWnd1").html("插件未启动，正在尝试启动，请稍候...");
        window.WebControl.JS_WakeUp("VideoWebPlugin://");
        that.initCount++;
        if (that.initCount < 3) {
          setTimeout(function() {
            that.initPlugin();
          }, 3000);
        } else {
          window.$("#playWnd1").html("插件启动失败，请检查插件是否安装！");
        }
      },
    });
    window.oWebControl = that.oWebControl
  }

  // 推送消息
  cbIntegrationCallBack(oData) {
    // showCBInfo(JSON.stringify(oData.responseMsg));
  }
  //初始化
  init() {
    let that = this
    that.getPubKey(function() {
      ////////////////////////////////// 请自行修改以下变量值	////////////////////////////////////
      var appkey = "23427846"; //综合安防管理平台提供的appkey，必填
      var secret = that.setEncrypt("TtSK2w4s7VhpcOoV13uM"); //综合安防管理平台提供的secret，必填
      var ip = "58.214.1.26"; //综合安防管理平台IP地址，必填 https://58.214.1.26:8443
      var playMode = 0; //初始播放模式：0-预览，1-回放
      var port = 8443; //综合安防管理平台端口，若启用HTTPS协议，默认443
      var snapDir = "D:\\SnapDir"; //抓图存储路径
      var videoDir = "D:\\VideoDir"; //紧急录像或录像剪辑存储路径
      var layout = "1x2"; //playMode指定模式的布局
      var enableHTTPS = 1; //是否启用HTTPS协议与综合安防管理平台交互，这里总是填1
      var encryptedFields = "secret"; //加密字段，默认加密领域为secret
      var showToolbar = 1; //是否显示工具栏，0-不显示，非0-显示
      var showSmart = 1; //是否显示智能信息（如配置移动侦测后画面上的线框），0-不显示，非0-显示
      var buttonIDs =
        "0,16,256,257,258,259,260,512,513,514,515,516,517,768,769"; //自定义工具条按钮
      ////////////////////////////////// 请自行修改以上变量值	////////////////////////////////////

      that.oWebControl
        .JS_RequestInterface({
          funcName: "init",
          argument: JSON.stringify({
            appkey: appkey, //API网关提供的appkey
            secret: secret, //API网关提供的secret
            ip: ip, //API网关IP地址
            playMode: playMode, //播放模式（决定显示预览还是回放界面）
            port: port, //端口
            snapDir: snapDir, //抓图存储路径
            videoDir: videoDir, //紧急录像或录像剪辑存储路径
            layout: layout, //布局
            enableHTTPS: enableHTTPS, //是否启用HTTPS协议
            encryptedFields: encryptedFields, //加密字段
            showToolbar: showToolbar, //是否显示工具栏
            showSmart: showSmart, //是否显示智能信息
            buttonIDs: buttonIDs, //自定义工具条按钮
          }),
        })
        .then(function(oData) {
            that.oWebControl.JS_Resize(1600, 800); // 初始化后resize一次，规避firefox下首次显示窗口后插件窗口未与DIV窗口重合问题
            that.preview()
        });
    });
  }
  //获取公钥
  getPubKey(callback) {
    let that = this
    that.oWebControl
      .JS_RequestInterface({
        funcName: "getRSAPubKey",
        argument: JSON.stringify({
          keyLength: 1024,
        }),
      })
      .then(function(oData) {
        console.log(oData);
        if (oData.responseMsg.data) {
            that.pubKey = oData.responseMsg.data;
          callback();
        }
      });
  }
  //RSA加密
  setEncrypt(value) {
    var encrypt = new window.JSEncrypt();
    encrypt.setPublicKey(this.pubKey);
    return encrypt.encrypt(value);
  }
	
    //预览
    preview (){
      var cameraIndexCode  ='"a3e4dd6fde114aac8a2cba00aa264ac2'     //获取输入的监控点编号值，必填
      var streamMode = 0;                                     //主子码流标识：0-主码流，1-子码流
      var transMode = 1;                                      //传输协议：0-UDP，1-TCP
      var gpuMode = 0;                                        //是否启用GPU硬解，0-不启用，1-启用
      var wndId = -1;            
                                   //播放窗口序号（在2x2以上布局下可指定播放窗口）
      cameraIndexCode = cameraIndexCode.replace(/(^\s*)/g, "");
      cameraIndexCode = cameraIndexCode.replace(/(\s*$)/g, "");
      this.oWebControl.JS_RequestInterface({
          funcName: "startPreview",
          argument: JSON.stringify({
              cameraIndexCode:'8d491ba595eb47f592131df04e4e80df',                //监控点编号
              streamMode: streamMode,                         //主子码流标识
              transMode: transMode,                           //传输协议
              gpuMode: gpuMode,                               //是否开启GPU硬解
              wndId:1                                     //可指定播放窗口
          })
      })
      this.oWebControl.JS_RequestInterface({
        funcName: "startPreview",
        argument: JSON.stringify({
            cameraIndexCode:"a3e4dd6fde114aac8a2cba00aa264ac2",                //监控点编号
            streamMode: streamMode,                         //主子码流标识
            transMode: transMode,                           //传输协议
            gpuMode: gpuMode,                               //是否开启GPU硬解
            wndId:2                                   //可指定播放窗口
        })
    })
  };

  render() {
    let { data, pagination } = this.state;
    return (
      <Content>
        <Row className="margin-bottom-15">
          <Col span={4}>
            <h2 className="content-title">
              <strong>监控视频</strong>
            </h2>
          </Col>
        </Row>
        <Row >
          <div class="gridBox">
            <div id="playWnd1" className="playWnd1" />
            <div id="playWnd2" className="playWnd2" />
            <div id="playWnd3" className="playWnd3" />
            <div id="playWnd4" className="playWnd4" />
          </div>

        </Row>
      </Content>
    );
  }
}

Alarm.contextTypes = {
  store: PropTypes.object,
};

export default Alarm;
