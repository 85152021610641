import React,{ Component } from "react";

class OperationalData extends Component{
    render(){
        return (
            <div>
                这是 运营数据asddddddddddddddd
            </div>
        )
    }
}

export default OperationalData;