import React, { Component } from "react";
import EChart from '../../components/echarts'
import MainTitle from '../../components/main_title'
import CommunityRank from '../../components/communityRank'
import GaodeMap from '../../components/gaodeMap'
import $util from "@/utils/utils";
import Warn from '../../components/bottom_warn'
import axios from "@/axios";
import { Alert } from 'antd';

import './index.less';

import PieChartEquipment from "../../components/pieChartEquipment";
import Echo from 'laravel-echo'

window.io = require('socket.io-client');
window.Echo = new Echo({
    broadcaster: 'socket.io',
    host:'https://prism.laichon.com:6001',
    auth: { headers: { 'Authorization': 'bearer ' + $util.getCookie("token") } }
});


let isQp = false;
class chinaMap extends Component {
    constructor(props) {
        super(props);
        let path = this.props.match.path;
        this.state = {
            showElem:true,
            userName:$util.getCookie("login_name"),
            leftChart: {},
            headData: {
                short_num: null,
                charge_times: null,
                overload_num: null,
                smoke_num: null
            },
            homeCountsData:{
                total_active_num:0,//日活
                total_newly_num:0,//新增用户
                total_city_number:0,//城市
                total_gun_number:0,//充电桩数量
                total_online_sum:0,//移动总收入
                total_income:0,//当日收入
                total_power:0,//总用电量
                total_pre_power:0,//昨日电量
                total_charge_times:0,
                online_total_evse_number: 0,//在线桩口数
                offline_total_evse_number: 0,//离线桩数量
                total_evse_number: 0,//枪口数
            },
            equipmentCost:{
                "all_count":0,//设备总数
                "line_count":0,//在线总数
                "offline_count":0,//离线总数
            },
            chargingRankingData:[],
            chargingRankingAxis:[],
            communityRanking:[],
            userData:{
                city_count: 0,
                community_num: 0,
                frequency: 0,
                device_gun_number: 0,
                user_count: 0,
                city_count_unit: '座',
                community_num_unit: '个',
                frequency_unit: 0,
                device_gun_number_unit: '个',
                user_count_unit: '个'
            },

            commonitor:[],
            costData: [],
            shotData: [],
            shotAxis: [],
            smokeData: [],
            smokeAxis: [],
            // costAxis:["1h","2h","3h","4h","5h","6h","7h","8h","9h","10h","11h","12h","13h","14h","15h","16h","17h","18h","19h","20h","21h","22h","23h","24h"],
            costAxis: [],
            reduceCarbon: "0.00",
            communityError: [],
            evseUsageRate: [],
            timeStr: "",
            timeInterval: "",
            alertData: {},
            gaodeMapHeight: '40rem',
            gaodeMapWidth: '48rem',
            rootName:$util.getCookie("login_name") !=="null" ? $util.getCookie("login_name"): "管理员",
            smoke_info:{},
            smoke_list:[]
        };


        this.state.isInner = ~path.indexOf("home")?true:false;
        let width = window.innerWidth;
        if (~path.indexOf("home")) {
            this.state.leftChart.width = "23rem";
            this.state.leftChart.height = "26.16vh";
            this.state.gaodeMapHeight = "29rem";
            this.state.gaodeMapWidth = "48rem";
            if (1750 < width && width < 2250) {
                this.state.leftChart.width = "20rem";
                this.state.leftChart.height = "26.16vh";
            }
            if (1300 < width && width < 1750) {

                this.state.leftChart.width = "17rem";
                this.state.leftChart.height = "26.16vh";
                this.state.gaodeMapHeight = "40rem";
                this.state.gaodeMapWidth = "48rem";
            }
            if (width === 1750) {

                this.state.leftChart.width = "17rem";
                this.state.leftChart.height = "26.16vh";
                this.state.gaodeMapHeight = "40rem";
                this.state.gaodeMapWidth = "48rem";
            }
            if (width <= 1300) {

                this.state.leftChart.width = "14rem";
                this.state.leftChart.height = "26.16vh";
                this.state.gaodeMapHeight = "40rem";
                this.state.gaodeMapWidth = "48rem";
            }
            this.state.domId = "operationalDataInner";
            this.state.gaodeMapId = "mapInner";
        } else {

            this.state.leftChart.width = "25rem";
            this.state.leftChart.height = "30vh";
            this.state.domId = "operationalData";
            this.state.gaodeMapId = "mapOuter";
        }

    }

    //放大全屏
    launchFullscreen = (path) => {
        let element = document.documentElement;
        try {
            if (element.requestFullscreen) {
                isQp = true;
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                isQp = true;
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                isQp = true;
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
                isQp = true;
                element.msRequestFullscreen();
            }
        }catch (e) {

        }
        this.props.history.push(path)
        this.close()
    }
    // 退出全屏
    exitFullscreen = (path) => {
        try {
            if (isQp && document.exitFullscreen) {
                isQp = false;
                document.exitFullscreen();
            } else if ( isQp && document.mozCancelFullScreen) {
                isQp = false;
                document.mozCancelFullScreen();
            } else if (isQp && document.webkitExitFullscreen) {
                isQp = false;
                document.webkitExitFullscreen();
            }
        }catch (e) {

        }
        this.props.history.push(path)
        this.close()
    }


    render() {
        const { isInner } = this.state;
        const { showElem,smoke_info,smoke_list } = this.state;
        return (
            <div className={"operationalData"} id={this.state.domId}>
                <div className={"map_top"}>
                    <div className={"map_top_left"}>
                        <span style={{ "marginRight": "2rem" }}>账号名：public</span>
                        <span id="timeClock"></span>
                    </div>
                    <div className={"map_top_mid"}>运营数据监控系统</div>
                    <div className={"map_top_right"}>

                        <div onClick={()=> this.siteMapDetail()}>站点地图</div>

                        { //有home代表在后台里面，这里不显示 先退出全屏 进入后台按钮
                            !isInner && <div onClick={() => this.exitFullscreen("/home/operationalData")}>进入后台</div>
                        }
                        { //有home代表在后台里面，这里显示放大全屏
                            isInner && <div onClick={()=>this.launchFullscreen('/operationalData')}>全屏展示</div>
                        }
                    </div>
                </div>
                <div className={"map_body"}>
                    <div className={"map_body_left"}>
                        <div className={"map_body_left"} style={{"display":"flex",}}>
                            <div className={"pie_chart_bg"} style={{"whiteSpace":"nowrap"}}>
                                <div className={"left_chart_bg"} style={{"paddingTop":"1rem"}}>
                                    <span className={"chart_title_gold"}>设备状态</span>
                                    <PieChartEquipment width={this.state.leftChart.width} height={this.state.leftChart.height} line_count={Number(this.state.equipmentCost.line_count)+50000} offline_count={this.state.equipmentCost.offline_count} all_count={Number(this.state.equipmentCost.all_count)+50000} />
                                </div>

                                <div className={"left_chart_bg"}>
                                    <span className={"chart_title_green"}>充电桩覆盖排名</span>
                                    <EChart width={this.state.leftChart.width} height={this.state.leftChart.height} listData={this.state.chargingRankingData} costAxis={this.state.chargingRankingAxis} />
                                </div>
                            </div>
                            <div className={"pie_chart_bg"} style={{"padding":"10px","marginLeft":"10px","border":"1px solid #000022"}}>
                                <div>
                                    <MainTitle  color1={"#669900"} color3={"rgb(102, 153, 0)"} title="充电用户" value={this.state.userData.user_count} unit={this.state.userData.user_count_unit} />
                                    <MainTitle  color1={"#9c8638"} color3={"rgb(255, 221, 101)"} title="充电频次" value={this.state.userData.frequency} unit={this.state.userData.frequency_unit} />
                                    <MainTitle  color1={"#3196bc"} color3={"rgb(72, 205, 255)"} title="充电插座" value={this.state.userData.device_gun_number} unit={this.state.userData.device_gun_number_unit} />
                                    <MainTitle  color1={"#0665b1"} color3={"rgb(57, 167, 255)"} title="覆盖城市" value={this.state.userData.city_count} unit={this.state.userData.city_count_unit} />
                                    <MainTitle  color1={"#c45cf3"} color3={"rgb(196,92,243)"} title="覆盖社区" value={this.state.userData.community_num} unit={this.state.userData.community_num_unit} />
                                </div>
                            </div>
                        </div>
                        <div className={"map_body_left"}>
                            <CommunityRank listData={this.state.communityRanking} />
                        </div>
                    </div>
                    <div style={{display: showElem ? 'block' : 'none'}}>
                            <div className={"content flex_box"}>
                                {/*<div className={"pop"} style={{display: smoke_info.status===2 ? 'block' : 'none'}}>*/}
                                    {/*<p style={{"fontWeight":"bolder"}}>警报中</p>*/}
                                    {/*<p>系统检测到烟雾，请及时现场查看</p>*/}
                                    {/*<p className={"bor_bottom"}></p>*/}
                                    {/*<p>{smoke_info.address}</p>*/}
                                    {/*<p>{smoke_info.evse_no}</p>*/}
                                {/*</div>*/}
                                <div className={"flex_box record"} id="review_box" style={{display: smoke_list.length>0 ? 'block' : 'none'}}>
                                    <ul id="comment1" className={"review_box_ul"}>
                                        {
                                            this.state.smoke_list.map((item, index) => {
                                                return <li key = {index + item} className={item.status==2?"red":''}>警报： {item.address} {item.smoke_name} {item.created_at} {item.status==2?'警报':'已解除'}</li>
                                            })
                                        }
                                    </ul>
                                    <ul id="comment2" className={"review_box_ul"}></ul>
                                </div>
                            </div>
                        </div>
                    <div className={"map_body_right"}>
                        <div id={this.state.gaodeMapId}>
                            <GaodeMap alertData={this.state.alertData} height={this.state.gaodeMapHeight} width={this.state.gaodeMapWidth} evseData={this.state.evseData} ref="myMap"/>
                        </div>
                    </div>

                </div>
                <audio className={"audio_warn"} loop>
                    <source src={require("../../assets/audio/alert.mp3")} type="audio/ogg" />
                    <track kind="captions" />
                    您的浏览器不支持 audio 元素。
                </audio>
                <iframe className={"iframe"} style={{display:"none"}} src={require("../../assets/audio/null.mp3")}></iframe>
            </div>
        )
    }
    componentDidMount() {
        document.addEventListener("fullscreenchange", this.fullscreenchange, false);
//moz下监听 mozfullscreenchange 事件，判断document.mozFullScreen属性
        document.addEventListener("mozfullscreenchange", this.mozfullscreenchange, false);
//chrome下监听webkitfullscreenchange事件，判断 document.webkitIsFullScreen 属性
        document.addEventListener("webkitfullscreenchange", this.webkitfullscreenchange, false);
        let ua = navigator.userAgent;

        //this.getEvseCost();
        this.getProvinceCost();
        this.getCommCost();
        this.getMainCost();
        this.getEvseList();
        this.getWarningInfo();

        this.setState({
            "timeInterval": setInterval(() => {
                this.showTime();
            }, 1000)
        })
        this.setState({
            "refresh": setInterval(() => {
                this.getEvseList()
            }, 300000)
        })

        this.setState({
            "refresh": setInterval(() => {
                this.getMainCost();
            }, 600000)
        })
        window.Echo.private('prism.'+$util.getCookie("web_app_id"))
           .listen('ProvinceCostEvent', (e) => {
                //console.log(e)
                let temp = e.object;
                let chargingRankingData = [];
                let chargingRankingAxis = [];
                for (let key in temp) {
                    chargingRankingData.push(temp[key].number);
                    if(temp[key].name.length>3){
                        let name = temp[key].name.slice(0,2)+'..'+temp[key].name.slice(-1)
                        chargingRankingAxis.push(name)
                    }else{
                        chargingRankingAxis.push(temp[key].name)
                    }

                }

                this.setState({ "chargingRankingData": chargingRankingData });
                this.setState({ "chargingRankingAxis": chargingRankingAxis });
            }).listen('CommunityCostEvent', (e) => {
                let data = e.object;
                for (let key in data) {
                    if(data[key].communitiy_name.length>5){
                        data[key].communitiy_name = data[key].communitiy_name.slice(0,3)+'...'+data[key].communitiy_name.slice(-2)
                    }

                }
                this.setState({ "communityRanking": data })
            }).listen('WarningListEvent', (e) => {
                //console.log(e)
                let data  = e.object;
                this.setState({ "smoke_list": data });

            })
    }
    fullscreenchange(){
        isQp = (document.fullscreen);
    }
    mozfullscreenchange(){
        isQp = (document.mozFullScreen);
    }
    webkitfullscreenchange(){
        isQp = (document.webkitIsFullScreen);
    }
    // 警报弹窗
    roll(t) {
        var ul1 = document.getElementById("comment1");
        var ul2 = document.getElementById("comment2");
        var ulbox = document.getElementById("review_box");
        ul2.innerHTML = ul1.innerHTML;
        ulbox.scrollTop = 0; // 开始无滚动时设为0
        //console.log(this.timer)
        this.timer = setInterval(this.rollStart, t); // 设置定时器，参数t用在这为间隔时间（单位毫秒），参数t越小，滚动速度越快
    }
    close(){
        clearInterval(this.timer);
    }
       // 开始滚动函数
    rollStart() {
        // 上面声明的DOM对象为局部对象需要再次声明
        var ul1 = document.getElementById("comment1");
        var ul2 = document.getElementById("comment2");
        var ulbox = document.getElementById("review_box");
        // 正常滚动不断给scrollTop的值+1,当滚动高度大于列表内容高度时恢复为0
        // if (ulbox.scrollTop >= ul1.scrollHeight) {
        //  ulbox.scrollTop = 0;
        // } else {
        //  ulbox.scrollTop++;
        // }
    }

    getMainCost(){

        axios.ajaxPost({
            url: "/getMainCost",
            data: {
                date_type: 'day'
            }
        }).then((res) => {

            if (res.error_code == 0) {
                let data = res.data;
                //设备数
                let equipmentCost = {};
                equipmentCost.all_count = data.device_num;
                equipmentCost.line_count = data.online_conut_number;
                equipmentCost.offline_count = data.offline_count_number;
                this.setState({ "equipmentCost": equipmentCost });

                //头部信息
                let userData = {};
                //充电用户
                if(Number(data.user_count) >= 10000){
                    userData.user_count = parseFloat(data.user_count/10000).toFixed(2);
                    userData.user_count_unit = "万";
                }else{
                    userData.user_count = parseFloat(data.user_count).toFixed(0);
                    userData.user_count_unit = "个";
                }
                //充电频次
                if(Number(data.frequency) >= 10000){
                    userData.frequency = parseFloat(data.frequency/10000).toFixed(2);
                    userData.frequency_unit = "万";
                }else{
                    userData.frequency = parseFloat(data.frequency).toFixed(0);
                    userData.frequency_unit = "次";
                }
                //插口数
                if(Number(data.device_gun_number) >= 10000){
                    userData.device_gun_number = parseFloat(data.device_gun_number/10000).toFixed(2);
                    userData.device_gun_number_unit = "万";
                }else{
                    userData.device_gun_number = parseFloat(data.device_gun_number).toFixed(0);
                    userData.device_gun_number_unit = "个";
                }
                //覆盖城市
                if(Number(data.city_count) >= 10000){
                    userData.city_count = parseFloat(data.city_count/10000).toFixed(2);
                    userData.city_count_unit = "万";
                }else{
                    userData.city_count = parseFloat(data.city_count).toFixed(0);
                    userData.city_count_unit = "座";
                }
                //覆盖社区
                if(Number(data.community_num) >= 10000){
                    userData.community_num = parseFloat(data.community_num/10000).toFixed(2);
                    userData.community_num_unit = "万";
                }else{
                    userData.community_num = parseFloat(data.community_num).toFixed(0);
                    userData.community_num_unit = "个";
                }

                this.setState({ "userData": userData })

            }
        }, true)
    }
    //获取地图桩列表信息
    getEvseList() {
        axios.ajaxGet({
            url: "/getEvseList"
        }).then((res) => {

            if (res.code == 200) {
                this.setState({ "evseData": res.data });
                if(this.refs.myMap){
                    this.refs.myMap.clusterMarker();
                }
            }
        }, true)
    }
    getWarningInfo(){
        axios.ajaxGet({
            url: "/getWarningInfo"
        }).then((res) => {
            if (res.code == 200) {
                this.roll(200);
                this.setState({ "smoke_list": res.data });
            }
        }, true)
    }
    //获取社区充电排名
    getCommCost() {
        axios.ajaxGet({
            url: "/getCommCost"
        }).then((res) => {
            if (res.code == 200) {
                let data = res.data;
                for (let key in data) {

                    if(data[key].communitiy_name.length>5){
                        data[key].communitiy_name = data[key].communitiy_name.slice(0,3)+'...'+data[key].communitiy_name.slice(-2)
                    }

                }
                this.setState({ "communityRanking": data });
            }
        }, true)
    }

    //获取充电桩覆盖排名
    getProvinceCost() {
        axios.ajaxGet({
            url: "/getProvinceCost"
        }).then((res) => {
            if (res.code == 200) {
                let chargingRankingData = [];
                let chargingRankingAxis = [];
                for (let key in res.data) {
                    chargingRankingData.push(res.data[key].number);
                    if(res.data[key].name.length>3){
                        let name = res.data[key].name.slice(0,2)+'..'+res.data[key].name.slice(-1)
                        chargingRankingAxis.push(name)
                    }else{
                        chargingRankingAxis.push(res.data[key].name)
                    }

                }

                this.setState({ "chargingRankingData": chargingRankingData });
                this.setState({ "chargingRankingAxis": chargingRankingAxis });
            }
        }, true)
    }

    //站点地图
    siteMapDetail = () => {
        const { isInner } = this.state;
        let path = "";
        if(!isInner){
            path = "setMap";
        }else{
            path = "home/setMap";
        }

        this.props.history.push({
            pathname: `/${path}`
        });
    };

    //运营数据
    handleRouterDetail = () => {
        const { isInner } = this.state;
        let path = "";
        let is_public = $util.getCookie("is_public");

        if(!isInner){
            if(is_public==1){
                path = "operationalData";
            }else{
                path = "intelligentCommunity";
            }

        }else{
            path = "home/intelligentCommunity";
        }
        this.props.history.push({
            pathname: `/${path}`
        });
    };
    showTime() {
        //创建Date对象
        var today = new Date();
        var year = today.getFullYear();
        var month = today.getMonth() + 1;
        var day = today.getDate();
        var hours = today.getHours();
        var minutes = today.getMinutes();
        var seconds = today.getSeconds();
        month = month < 10 ? "0" + month : month;
        day = day < 10 ? "0" + day : day;
        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        var str = hours + ":" + minutes + ":" + seconds + "   " + year + "-" + month + "-" + day;
        document.getElementById("timeClock").innerText = str;
    }
    bMapTransQQMap(lng, lat) {
        let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
        let x = lng - 0.0065;
        let y = lat - 0.006;
        let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
        let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
        let lngs = z * Math.cos(theta);
        let lats = z * Math.sin(theta);
        return {
            lng: lngs,
            lat: lats
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.timeInterval);
        clearInterval(this.state.refresh);
        document.removeEventListener("fullscreenchange", this.fullscreenchange, false);
//moz下监听 mozfullscreenchange 事件，判断document.mozFullScreen属性
        document.removeEventListener("mozfullscreenchange", this.mozfullscreenchange, false);
//chrome下监听webkitfullscreenchange事件，判断 document.webkitIsFullScreen 属性
        document.removeEventListener("webkitfullscreenchange", this.webkitfullscreenchange, false);
    }
}

export default chinaMap;