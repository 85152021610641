import React,{ Component } from "react";
import './index.less';
import ReactEcharts from 'echarts-for-react';
require('../../utils/macarons');

class pieChart extends Component{
  state={
    height:"",
    width:"",
    initSize:parseInt(document.getElementsByTagName("html")[0].style.fontSize),
    textSize:10
  };
  componentWillMount(){
    this.setState({"height":this.props.height,"width":this.props.width,"text":this.props.text,"textSize":0.64*this.state.initSize});
    this.getOption();
  }
  componentDidMount(){
    this.getOption();
  }
  addEvent(){

  }
  setOptions(){

  }
  componentWillReceiveProps(nextProps, nextState){
    this.getOption();
  }
  getOption(){
    let min = 768;
    let max = 2400;
    let windowWidth = document.body.clientWidth;
    let size = parseInt(windowWidth);
    if (windowWidth > max) {
      size = max;
    } else if (windowWidth < min) {
      size = min;
    }
    let textSize = (size / 96)*0.64;
    textSize = textSize<10? 10:textSize;
    let lineSize = (size / 96)*0.48;
    let options = {
      color:this.props.legendColor,
      tooltip: {
        trigger: 'item',
        formatter: "{b}: {c} ({d}%)"
      },
      title: {
        text:this.props.text,
        subtext:"比例",
        left:'center',
        top:'37%',
        textStyle:{
          color:this.props.color,
          fontSize:textSize,
          align:'center'
        },
        subtextStyle:{
          color:this.props.color,
          fontSize:textSize,
          align:'center',
          x:"center"
        }
      },
      legend: {
        orient: 'vertical',
        right: 5,
        top: 5,
        show:false,
        data:this.props.legendData,
        selectedMode:false,
        itemHeight:10,
        itemWidth:10,
        textStyle: {
          color:"#fff"
        }
      },
      series: [
        {
          hoverAnimation:false,
          type:'pie',
          radius: ['45%', '55%'],
          center: ['50%', '50%'],
          avoidLabelOverlap: true,
          label:{
            fontSize:textSize,
            align:"left"
          },
          labelLine: {
            length:lineSize,
            length2:lineSize
          },
          data:this.props.pieData
        }
      ]
    };
    this.setState({"options":options});
  }
  render(){
    return (
      <ReactEcharts  option={this.state.options} theme={"macarons"}  style={{ width:this.state.width, height:this.state.height }}>
      </ReactEcharts>
    )
  }
}

export default pieChart;