import React, { Component } from 'react';

import {
    Layout, Row, Col, Form, Button, Modal, Select, Input, Divider, Table
} from 'antd';
import axios from "@/axios";
import PropTypes from 'prop-types';

const { Content } = Layout;
const Option = Select.Option;
const FormItem = Form.Item;
let deviceId = "";
let do_type = "";

//烟感设备搜索条件
class SearchForm extends React.Component {
    //搜索
    searchD = () => {
        let forms = this.props.form.getFieldsValue(),
            params = {
                evse_name: forms.evse_name ? forms.evse_name : "",
                evse_no: forms.evse_no ? forms.evse_no : "",
                limit:10
            };
        this.props.state.params = params;
        if(do_type === 'bind'){
            this.props.getUserList(params);
        }else {
            this.props.getUnList(params);
        }
    };
    //绑定方法
    handleSubmit = (e) => {
        e.preventDefault();
        let evse_ids = this.props.evse_ids;
        axios.ajaxPost({
            url: "/bindSmokeDevice",
            data:{
                smoke_id: deviceId,
                evse_ids: evse_ids,
                do_type: do_type
            }
        }, true).then((res) => {
            window.history.back(-1);
        }).catch((res) => {
            Modal.error({
                title: "提示",
                content: res.message
            })
        })
    };
    render() {
        let { getFieldDecorator } = this.props.form;
        return (
            <Form layout="inline" onSubmit={this.handleSubmit}>
                <FormItem label="设备名称" >
                    {getFieldDecorator('evse_name', {
                        initialValue: ""
                    })(
                        <Input style={{ width: 200 }} maxLength="50" placeholder="" />
                    )}
                </FormItem>
                <FormItem label="设备编号" >
                    {getFieldDecorator('evse_no', {
                        initialValue: ""
                    })(
                        <Input style={{ width: 200 }} maxLength="50" placeholder="" />
                    )}
                </FormItem>
                <FormItem>
                    <Button type="primary" onClick={this.searchD}>搜索</Button>&nbsp;&nbsp;&nbsp;
                    <Button type="primary" htmlType="submit">{do_type == 'bind' ? '绑定' : '解绑'}</Button>
                </FormItem>
            </Form>
        )
    };
};

SearchForm.contextTypes = {
    store: PropTypes.object,
};
let SearchFormNotesComponent = Form.create()(SearchForm);

class Add extends Component {
    constructor(props) {
        super(props);
        deviceId = this.props.location.state.id;//烟感设备id
        do_type = this.props.location.state.type;//烟感设备id
        this.state = {
            dataList: [],
            pagination: {
                limit: 10,
                page_now: 1,
                total: 0,
            },
            evse_ids:"",
            selectedRowKeys:[],
            params:{
                evse_name:"",
                evse_no:"",
                limit:10
            }
        };
    };
    //组件挂在之后执行的生命周期函数
    componentDidMount() {
        //初始化搜索
        let params = {
            evse_name:"",
            evse_no:"",
            limit:10
        }
        if(do_type === 'bind'){
            this.getUserList(params);
        }else {
            this.getUnList(params);
        }
    };
    //获取列表
    getUserList = (params) => {
        axios.ajaxPost({
            url: "/getEvseName?page=" + this.state.pagination.page_now,
            data:params
        }, true).then((res) => {
            let pagination = this.state.pagination;
            let { current_page, total } = res.meta;
            pagination.Page = current_page;
            pagination.total = total;
            this.setState({
                dataList: res.data,
                selectedRowKeys: [],
                pagination
            })
        }).catch((res) => {
            Modal.error({
                title: "提示",
                content: res.message
            })
        })
    };
    getUnList = (params) => {
        params.smoke_id = deviceId;
        axios.ajaxPost({
            url: "/getEvseBySmokeId?page=" + this.state.pagination.page_now,
            data:params
        }, true).then((res) => {
            if(res.data.length > 0){
                let pagination = this.state.pagination;
                let { current_page, total } = res.meta;
                pagination.Page = current_page;
                pagination.total = total;
                this.setState({
                    dataList: res.data,
                    selectedRowKeys: [],
                    pagination
                })
            }else{
                this.setState({
                    dataList: res.data,
                    selectedRowKeys: [],
                })
            }
        }).catch((res) => {
            Modal.error({
                title: "提示",
                content: res.message
            })
        })
    };
    //设置表头
    getColumns = () => {
        let columns = [{
            title: '设备名称',
            dataIndex: "evse_name",
        }, {
            title: '设备编号',
            dataIndex: 'evse_no',
        }, {
            title: '设备状态',
            dataIndex: 'net_status_name',
        }];
        return columns;
    };
    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowIds : selectedRowKeys.map(Number),
            selectedRowKeys
        });
        if(selectedRows.length > 0){
            let str = "";
            for(let i = 0;i < selectedRows.length;i++){
                if(i){
                    str = str + "," + selectedRows[i].id;
                }else{
                    str = selectedRows[i].id;
                }
            }
            this.setState({
                evse_ids: str
            })
        }
        this.setState({ selectedRowKeys });
    };
    handleTableChange = (pagination, filters, sorter) => {
        let { current, pageSize } = pagination,
            paginations = this.state.pagination;
        paginations.page_now = current;
        paginations.limit = pageSize;
        this.setState({
            paginations
        }, () => {
            if(do_type === 'bind'){
                this.getUserList(this.state.params);
            }else {
                this.getUnList(this.state.params);
            }
        })
    };

    render() {
        let { dataList, pagination } = this.state;
        const columns = this.getColumns();
        const {selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <Content>
                <Row className="margin-bottom-15">
                    <Button
                        style={{ float: "left", marginRight: 20 }}
                        onClick={() => this.props.history.goBack()}
                        type="primary"
                        icon="left"
                    />
                    <Col span={4}>
                        <h2 className="content-title"><strong>{do_type == 'bind' ? '绑定设备' : '解绑设备'}</strong></h2>
                    </Col>
                </Row>
                <Row className="margin-bottom-15">
                    <Col span={24}>
                        <SearchFormNotesComponent
                            getUserList={this.getUserList}
                            getUnList={this.getUnList}
                            evse_ids={this.state.evse_ids}
                            state={this.state}
                            wrappedComponentRef={(form) => this.SearchForm = form}
                        >
                        </SearchFormNotesComponent>
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                    <Col span={24}>
                        <Table
                            rowSelection={rowSelection}
                            rowKey={(record, index) => index}
                            columns={columns}
                            dataSource={dataList}
                            bordered
                            pagination={{
                                total: parseInt(pagination.total, 0),
                                current: parseInt(pagination.page_now, 0),
                                pageSize: parseInt(pagination.limit, 0),

                                pageSizeOptions: ['5', '10', '20', '50', '100'],
                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
                                showQuickJumper: true,
                            }}
                            onChange={this.handleTableChange}
                        />
                    </Col>
                </Row>
            </Content>
        )
    }
}

Add.contextTypes = {
    store: PropTypes.object,
};

export default Add;