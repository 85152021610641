import React,{ Component } from "react";
import './index.less';

class rank extends Component{
  state={

    borderColor:this.props.color1
  };
  componentWillMount(){
  }
  componentDidMount(){
  }
  render(){
    return (
      <div className={"main_title"} style={{"borderColor":this.props.color1,"background":`linear-gradient(to right, ${this.props.color2}, transparent)`,"marginBottom":"18px"}}>
        <div className={"main_title_name"} style={{"color":this.props.color3}}>{this.props.title}<span>({this.props.unit})</span></div>
        <div className={"main_title_value"}>{this.props.value}</div>
      </div>
    )
  }
}

export default rank;