import React, { Component } from "react";

import {
    Layout, Row, notification, Select, Form, Button, Modal, Checkbox
} from 'antd';
import axios from "@/axios";
const { Content } = Layout;
const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;

const plainOptions = ['运营数据监控', '智慧社区数据监控', '用户管理'];
const map = {
    "user": "用户管理",
    "operate": "运营数据监控",
    "smart_community": "智慧社区数据监控"
}
const mapChange = {
    "用户管理": "user",
    "运营数据监控": "operate",
    "智慧社区数据监控": "smart_community"
}

class HorizontalLoginForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            checkedList: [],
            // indeterminate: true,
            checkAll: false,
        }
    }

    componentWillMount() {
        const { record } = this.props;
        const { checkedList } = this.state;
        let arr = record.permsession_module.split(",");
        arr.map((item) => {
            if (map[item]) {
                return checkedList.push(map[item]);
            }
        })
        this.setState({
            id: record.web_user_id,
            checkedList
        })
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { id, checkedList } = this.state;
                let permsessionArr = [];
                checkedList.map((item)=>{
                    if(mapChange[item]){
                        permsessionArr.push(mapChange[item])
                    }
                })
                this.setUserInfo(id,permsessionArr.toString())
            }
        });
    };

    setUserInfo = (id,permsession) => {
        axios.ajaxPost({
            url: "/modUserPermsession",
            data: {
                web_user_id: id,
                permsession:permsession
            }
        }, true).then((res) => {
            notification["success"]({
                message: '提示',
                description: '设置成功！',
            });
        }).catch((res) => {
            Modal.error({
                title: "提示",
                content: res.message
            })
        })
    };

    onChange = (checkedList) => {
        this.setState({
            checkedList,
            // indeterminate: !!checkedList.length && (checkedList.length < plainOptions.length),
            checkAll: checkedList.length === plainOptions.length,
        });

    }

    onCheckAllChange = (e) => {
        this.setState({
            checkedList: e.target.checked ? plainOptions : [],
            // indeterminate: false,
            checkAll: e.target.checked,
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { record } = this.props;
        const { indeterminate, checkAll, checkedList } = this.state;
        const popoverFormItemLayout = {
            labelCol: {
                sm: { span: 3 },
            },
            wrapperCol: {
                sm: { span: 6, offset: 1 }
            }
        }
        return (
            <Form onSubmit={this.handleSubmit}>
                {/* <Form.Item
                    {...popoverFormItemLayout}
                    style={{ marginBottom: 10 }}
                    label="上级"
                >
                    <span> 系统管理员</span>
                </Form.Item> */}
                <Form.Item
                    {...popoverFormItemLayout}
                    style={{ marginBottom: 10 }}
                    label="账号"
                >
                    <span>{record.login_name}</span>
                </Form.Item>
                <Form.Item
                    {...popoverFormItemLayout}
                    style={{ marginBottom: 10 }}
                    label="手机号码"
                >
                    <span>{record.mobile_no}</span>
                </Form.Item>
                <Form.Item
                    {...popoverFormItemLayout}
                    style={{ marginBottom: 10 }}
                    label="角色"
                >
                    <span>{record.role_id_name}</span>
                </Form.Item>
                <Form.Item
                    {...popoverFormItemLayout}
                    style={{ marginBottom: 10 }}
                    label="区域"
                >
                    <span>{record.province_code_name + record.city_code_name + record.district_code_name}</span>
                </Form.Item>
                <Form.Item
                    {...popoverFormItemLayout}
                    style={{ marginBottom: 10 }}
                    label="账号状态"
                >
                    <span>{record.status_name}</span>
                </Form.Item>
                <Form.Item
                    {...popoverFormItemLayout}
                    style={{ marginBottom: 10 }}
                    label="权限"
                >
                    {getFieldDecorator("checkbox-group", {
                        initialValue: [],
                        // rules: [{ required: true, message: '' }],
                    })(
                        <Checkbox.Group style={{ width: "100%" }}>
                            <Row>
                                <Checkbox
                                    // indeterminate={indeterminate}
                                    onChange={this.onCheckAllChange}
                                    checked={checkAll}
                                >全选</Checkbox>
                            </Row>
                            <Row>
                                <CheckboxGroup options={plainOptions} value={checkedList} onChange={this.onChange} />
                            </Row>
                            {/* <Row>
                                <Checkbox value="smart_community">智慧社区数据监控</Checkbox>
                            </Row>
                            <Row>
                                <Checkbox value="user">用户管理</Checkbox>
                            </Row> */}
                            {/* <Row>
                                <Checkbox value="D">系统设置</Checkbox>
                            </Row> */}
                        </Checkbox.Group>
                    )}
                </Form.Item>
                <Form.Item
                    wrapperCol={{ span: 12, offset: 4 }}
                >
                    <Button type="primary" htmlType="submit">提交</Button>
                </Form.Item>
            </Form>
        );
    }
}
const WrappedHorizontalLoginForm = Form.create()(HorizontalLoginForm);


class Check extends Component {

    constructor(props) {
        super(props);
        let record = this.props.location.state;
        this.state = {
            tabPosition: 1,
            record
        }
    }

    componentDidMount() {

    }

    changeTabPosition = (tabPosition) => {
        this.setState({ tabPosition });
    }
    render() {
        const { tabPosition, record } = this.state;
        return (
            <Content>
                <Row className="margin-bottom-15" style={{ marginBottom: 50 }}>
                    <Button
                        style={{ float: "left", marginRight: 20 }}
                        onClick={() => this.props.history.goBack()}
                        type="primary"
                        icon="left"
                    />
                    <h2 className="content-title"><strong>权限设置</strong></h2>
                </Row>
                <WrappedHorizontalLoginForm record={record} />
            </Content>
        )
    }
}

export default Check;