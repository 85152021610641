import React, { Component } from "react";
import moment from 'moment';
import {
    Layout, Row, Form, Button, Modal, Divider
} from 'antd';
import axios from "@/axios";

const { Content } = Layout;


class HorizontalLoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            mobile: "",
            //permsession_module_name: {},
            roles_name: "",
            status_name: "",
            updated_at: "",
        }
    }

    componentDidMount() {
        this.getUserInfo();
    }

    getUserInfo = () => {
        axios.ajaxPost({
            url: "/getUserInfo",
            data: {
                id: this.props.userId
            }
        }, true).then((res) => {
            //console.log(res);
            const { name, mobile, roles,
                status_name,
                updated_at, province_code_name, city_code_name, district_code_name,land_num } = res.data;
            this.setState({
                name,
                mobile,
                roles_name:roles[0].display_name,
                status_name,
                updated_at:updated_at,
                province_code_name,
                city_code_name,
                district_code_name,
                land_num,

            })

        }).catch((res) => {
            Modal.error({
                title: "提示",
                content: res.message
            })
        })
    }
    render() {
        const popoverFormItemLayout = {
            labelCol: {
                sm: { span: 3 },
            },
            wrapperCol: {
                sm: { span: 12, offset: 1 }
            }
        }
        const { name, mobile, roles_name,
            status_name,
            updated_at, province_code_name, city_code_name, district_code_name,land_num } = this.state;
        let area = province_code_name + city_code_name + district_code_name;

        return (
            <Form onSubmit={this.handleSubmit}>
                {/* <Form.Item
                    {...popoverFormItemLayout}
                    style={{ marginBottom: 10 }}
                    label="上级"
                >
                    <span>{login_name}</span>
                </Form.Item> */}
                <Form.Item
                    {...popoverFormItemLayout}
                    style={{ marginBottom: 10 }}
                    label="账号"
                >
                    <span>{name}</span>
                </Form.Item>
                <Form.Item
                    {...popoverFormItemLayout}
                    style={{ marginBottom: 10 }}
                    label="手机号码"
                >
                    <span>{mobile}</span>
                </Form.Item>
                <Form.Item
                    {...popoverFormItemLayout}
                    style={{ marginBottom: 10 }}
                    label="角色"
                >
                    <span>{roles_name}</span>
                </Form.Item>
                <Form.Item
                    {...popoverFormItemLayout}
                    style={{ marginBottom: 10 }}
                    label="区域"
                >
                    <span>{province_code_name ? area : ""}</span>
                </Form.Item>
                <Form.Item
                    {...popoverFormItemLayout}
                    style={{ marginBottom: 10 }}
                    label="账号状态"
                >
                    <span>{status_name}</span>
                </Form.Item>
                {/*<Form.Item*/}
                    {/*{...popoverFormItemLayout}*/}
                    {/*style={{ marginBottom: 10 }}*/}
                    {/*label="权限"*/}
                {/*>*/}
                    {/*{*/}
                        {/*Object.keys(permsession_module_name).map((item, key) => {*/}
                            {/*return <span key={key}>{item}<Divider type="vertical" /></span>*/}
                        {/*})*/}
                    {/*}*/}
                {/*</Form.Item>*/}
                <Form.Item
                    {...popoverFormItemLayout}
                    style={{ marginBottom: 10 }}
                    label="上线登录次数"
                >
                    <span>{land_num}次</span>
                </Form.Item>
                <Form.Item
                    {...popoverFormItemLayout}
                    style={{ marginBottom: 10 }}
                    label="最近访问时间"
                >
                    <span>{updated_at}</span>
                </Form.Item>
            </Form>
        );
    }
}
const WrappedHorizontalLoginForm = Form.create()(HorizontalLoginForm);


class Check extends Component {

    constructor(props) {
        super(props);
        let id = this.props.location.state.id;
        this.state = {
            id
        }
    }

    render() {
        const { id } = this.state;
        return (
            <Content>
                <Row className="margin-bottom-15" style={{ marginBottom: 50 }}>
                    <Button
                        style={{ float: "left", marginRight: 20 }}
                        onClick={() => this.props.history.goBack()}
                        type="primary"
                        icon="left"
                    />
                    <h2 className="content-title"><strong>查看账户详情</strong></h2>
                </Row>
                <WrappedHorizontalLoginForm
                    userId={id}
                />
            </Content>
        )
    }
}

export default Check;