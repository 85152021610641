import React from 'react';
import ReactDOM from 'react-dom';
// import Admin from './admin';
import Router from './router';
import * as serviceWorker from './serviceWorker';
import {fontSizeToRem} from './utils/utils'
import Echo from 'laravel-echo'

// window.io = require('socket.io-client');
// window.Echo = new Echo({
//     broadcaster: 'socket.io',
//     host: window.location.hostname + ':6001'
// });
ReactDOM.render(<Router />, document.getElementById('root'));
fontSizeToRem();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
