import $util from "@/utils/utils.js";
const is_public = $util.getCookie("is_public");
let url = '';

if(is_public){
    url = "/home/operationalData";
}else{
    url = "/home/IntelligentCommunity";
}
const menuList = [
    {
        title:'首页',
        key:url,
        type:"home",

    },
    {
        title:'用户管理',
        key:'sub2',
        type:"user",
        children:[
            {
                title:'用户一览',
                key:'/user',
            }
        ]
    },
    {
        title:'系统设置',
        key:'/setting',
        type:"setting",
    },
    {
        title:'烟感设备',
        key:'/device',
        type:"device",
    },
    {
        title:'报警日志',
        key:'/alramLog',
        type:"alramLog",
    },
];
export default menuList;