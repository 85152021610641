import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Modal } from 'antd';
import './index.less';
import axios from "@/axios";
import $pars from "@/pars/pars.js";
import $util from "@/utils/utils.js";
import { Layout, Form, Icon, Input, notification, Checkbox, Row } from 'antd';

const {
    Sider, Content,
} = Layout;

class NormalLoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
        }
    }

    componentDidMount() {
        
        this.clickGraphCaptcha();
        this.getCookieUser();
        //组件挂载时候，注册keypress事件
        document.addEventListener('keypress', this.handleEnterKey)
    }

    componentWillUmount() {
        //组件卸载时候，注销keypress事件
        document.removeEventListener("keypress", this.handleEenterKey)
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.doLogin(values);
            }
        });
    }

    //回车登录
    handleEnterKey = (e) => {
        if (e.keyCode === 13) { //主要区别就是这里，可以直接获取到keyCode的值
            this.handleSubmit(e);
        }
    }

    //是否记录过密码
    getCookieUser = () => {
        let username = $util.getLocalStorage("This is username");
        if (username) {
            let password = $util.getLocalStorage("user_name");
            this.props.form.setFieldsValue({
                user_name: username,
                password: password,
            })
        }
    };

    //登录
    doLogin = (values) => {
        // let reg = /^[-\u4e00-\u9fa5—_a-zA-Z0-9]+$/;
        // if (!reg.test(values.user_name)) {
        //     alert("请输入正确的用户名");
        //     return false
        // }
        axios.ajaxPost({
            url: "/logging",
            data: {
                user_name: values.user_name,
                password: values.password,
                validate_code: values.validate_code
            }
        }, false).then((res) => {

            //设置token
            // $pars.token = res.data.token;
            //记录账号密码
            if (values.checked) {
                $util.setLocalStorage("This is username", values.user_name);
                $util.setLocalStorage("user_name", values.password);
            } else {
                $util.delLocalStorage("This is username");
                $util.delLocalStorage("user_name");
            }


            for (let key in res.data) {
                if (key === 'permsession_module_name') {
                    $util.setCookie(key, JSON.stringify(res.data[key]), 1);
                } else {
                    if(key =='id'){
                        $util.setCookie('web_app_id', res.data[key], 1);
                    }else if(key =='name'){
                        $util.setCookie('login_name', res.data[key], 1);
                    }else if(key =='roles'){
                        $util.setCookie('role_name', res.data[key][0].display_name, 1);
                    }else{
                        $util.setCookie(key, res.data[key], 1);
                    }

                }
            }

            //设置权限存储

            notification["success"]({
                message: '提示',
                description: '登录成功！',
            });
            let is_public = $util.getCookie("is_public");
            if(is_public){
                $util.setCookie('permsession_module_name', JSON.stringify('operate'), 1);
                $util.setCookie('permsession_module', 'operate', 1);
                window.location.href = "home/operationalData";
            }else{
                //设置登录态
                $util.setCookie('permsession_module_name', JSON.stringify('common,user,operate,smart_community,system'), 1);
                $util.setCookie('permsession_module', 'common,user,operate,smart_community,system', 1);
                window.location.href = "home/IntelligentCommunity";
            }

        }).catch((res) => {
            Modal.error({
                title: "提示",
                content: res.message
            })
        })
    }

    //切换图片验证码的ajax
    clickGraphCaptcha = () => {
        let captchaUrl = "";
        if (window.location.origin.indexOf("localhost") !== -1) {
            captchaUrl = $pars.ajaxBaseUrl + "/api/getIdentifyingCode";
        } else {
            captchaUrl = window.location.origin + "/api/getIdentifyingCode"
        }
        this.refs.graphCaptchaImg.src = captchaUrl;
    };

    //手否记住密码
    handleChangeChecked = (e) => {
        this.setState({
            checked: e.target.checked,
        }, () => {
            this.props.form.validateFields(['checked'], { force: true });
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { checked } = this.state;
        return (
            <Form className="login-form">
                <Form.Item className="inpbox">
                    {getFieldDecorator('user_name', {
                        rules: [{ required: true, message: '请输入账号' }],
                    })(
                        <Input width={254} prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="请输入账号" />
                    )}
                </Form.Item>
                <Form.Item className="inpbox">
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: '请输入密码' }],
                    })(
                        <Input width={254} prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="请输入密码" />
                    )}
                </Form.Item>
                <Form.Item className="inpbox" width={150}>
                    {getFieldDecorator('validate_code', {
                        rules: [{ required: true, message: '请输入验证码' }],
                    })(
                        <Input
                            className="graphCaptchaImg"
                            prefix={<Icon type="check-circle" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            maxLength="5"
                            placeholder="请输入验证码" />
                    )}
                    <img
                        className="captcha-img"
                        ref="graphCaptchaImg"
                        src=""
                        alt="验证码"
                        onClick={() => this.clickGraphCaptcha()}
                    />
                </Form.Item>

                <a onClick={this.handleSubmit} className="login-form-button">登录</a>
                <Form.Item>
                    {getFieldDecorator('checked', {
                        valuePropName: 'checked',
                    })(
                        <div>
                            <Checkbox
                                checked={checked}
                                onChange={this.handleChangeChecked}
                            >记住密码</Checkbox>
                            <Link style={{ float: "right" }} to="/forgetpwd">忘记密码</Link>
                            {/* <a onClick={()=>this.props.history.push("/reset")} style={{ float: "right" }}>忘记密码</a> */}
                        </div>
                    )}
                </Form.Item>

            </Form>
        );
    }
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    };

    componentDidMount() {

    };

    render() {
        let url = window.location.href
        let  user = url.split("=")[1]
        let is_pts = false
        if(user=="PTS"){
            is_pts= true
        }
        return (
            <Layout className="body">
                <Layout style={{ "height": "100%" }}>
                    <Content width="70%" className="container">
                        {!is_pts && <div className="banner">
                            <div className="title">
                                <h1>Wuhu Laichong Operations</h1>
                                <h1>Monitoring Platform</h1>
                                <p>芜湖来充运营数据监控平台</p>
                            </div>
                        </div> }
                        {is_pts && <div className="banner_pts">
                            <div className="title">
                            </div>
                        </div>}
                    </Content>
                    <Sider className="login" width="30%" style={{ background: '#fff' }}>
                        <div className="form">
                        {!is_pts && <img className="icon"
                                style={{ marginTop: 80, marginBottom: 100 }}
                                src={require("../../assets/newlogobyred.png")} alt="" /> }
                        { is_pts &&   <img className="icon"
                                style={{ marginTop: 80, marginBottom: 100 }}
                                src={require("../../assets/images/login_icon.png")} alt="" />}
                            <WrappedNormalLoginForm
                                {...this.props}
                            />
                        </div>
                        <p className="footer">如需修改密码，请登录boss.laichon.com进行修改</p>
                    </Sider>
                </Layout>
            </Layout>
        )
    }
}

export default Login;