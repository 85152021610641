import React, { Component } from "react";
import GaodeMap from '../../components/gaodeMap'

import $util from "@/utils/utils.js";
import axios from "@/axios";
import './setMap.less';
import Echo from 'laravel-echo'

window.io = require('socket.io-client');
window.Echo = new Echo({
    broadcaster: 'socket.io',
    host:'https://prism.laichon.com:6001',
    auth: { headers: { 'Authorization': 'bearer ' + $util.getCookie("token") } }
});


let isQp = false;
class setMap extends Component {
    constructor(props) {
        super(props);
        let path = this.props.match.path;
        let isInner = ~path.indexOf("home") ? true : false;
        this.state = {
            showElem:true,
            isInner:isInner,
            leftChart:{
                width: "25rem",
                height: "29vh"
            },
            barChart:{
                width: "25rem",
                height: "27vh"
            },
            pirChart:{
                width: "12rem",
                height: "15.3vh"
            },
            commonitor:[],
            chargeShow:3,
            totalPower:[],
            totalIncome:[],
            incomexAxis:[],
            totalPowerShow:3,
            totalPowerxAxis:[],
            overloadData: [],
            overloadAxis: [],
            shotData: [],
            shotAxis: [],
            costData:[],
            costAxis:[],
            payPieData:[],
            alertData:{},
            chargeColor:["#ffb4ca","#22bffc","#12d297","#c155f8","#9597f0"],
            timeInterval:"",
            refresh:"",
            evseData:[],
            rootName:$util.getCookie("login_name") !=="null" && $util.getCookie("is_public") !=1 ? $util.getCookie("login_name"): "public",
            smoke_info:{},
            smoke_list:[],
            smoke_list_history:[],
            smokeAlert:false

        };
        let width = window.innerWidth;
        if (!!~path.indexOf("home")) {
            this.state.leftChart.width = "23rem";
            this.state.leftChart.height = "26.16vh";
            this.state.gaodeMapHeight = "45rem";
            this.state.gaodeMapWidth = "70rem";
            this.state.barChart.width = '24rem';
            this.state.barChart.height = '24vh';
            this.state.pirChart.width = '11.5rem';
            this.state.pirChart.height = '15.3vh';
            if (1750 < width && width < 2250) {
                this.state.leftChart.width = "20rem";
                this.state.leftChart.height = "26.16vh";
                this.state.barChart.width = '20rem';
                this.state.barChart.height = '24vh';
                this.state.pirChart.width = '10rem';
            }
            if (1300 < width && width < 1750) {
                this.state.leftChart.width = "18rem";
                this.state.leftChart.height = "26.16vh";
                this.state.gaodeMapHeight = "45rem";
                this.state.gaodeMapWidth = "70rem";
                this.state.barChart.width = '18rem';
                this.state.pirChart.width = '8.5rem';
            }
            if (width === 1750) {
                this.state.leftChart.width = "18rem";
                this.state.leftChart.height = "26.16vh";
                this.state.gaodeMapHeight = "45rem";
                this.state.gaodeMapWidth = "70rem";
                this.state.barChart.width = '17rem';
                this.state.pirChart.width = '8.5rem';
            }
            if (width <= 1300) {
                this.state.pirChart.width = '7.5rem';
                this.state.barChart.width = '16rem';
                this.state.leftChart.width = "16rem";
                this.state.leftChart.height = "26.16vh";
                this.state.gaodeMapHeight = "45rem";
                this.state.gaodeMapWidth = "70rem";
            }
            this.state.domId = "intelligentCommunityInner";
            this.state.gaodeMapId = "mapInner";
        } else {
            this.state.gaodeMapHeight = "45rem";
            this.state.gaodeMapWidth = "70rem";
            this.state.leftChart.width = "25rem";
            this.state.leftChart.height = "29vh";
            this.state.domId = "setMap";
            this.state.gaodeMapId = "mapOuter";
        }
    }

    //放大全屏
    launchFullscreen = (path) => {
        let element = document.documentElement;
        try {
            if (element.requestFullscreen) {
                isQp = true;
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                isQp = true;
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                isQp = true;
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
                isQp = true;
                element.msRequestFullscreen();
            }
        }catch (e) {

        }
        this.props.history.push(path)
        this.close()
    }
    // 退出全屏
    exitFullscreen = (path) => {
        try {
            if (isQp && document.exitFullscreen) {
                isQp = false;
                document.exitFullscreen();
            } else if ( isQp && document.mozCancelFullScreen) {
                isQp = false;
                document.mozCancelFullScreen();
            } else if (isQp && document.webkitExitFullscreen) {
                isQp = false;
                document.webkitExitFullscreen();
            }
        }catch (e) {

        }
        this.props.history.push(path)
        this.close()
    }

    render() {
        const { isInner } = this.state;
        const { showElem,smoke_info,smoke_list } = this.state;
        return (
            <div className={"setMap"}  id={this.state.domId}>
                <div className={"map_top"}>
                    <div className={"map_top_left"}>
                        <span style={{ "marginRight": "2rem" }}>账号名：{this.state.rootName}</span>
                        <span id="timeClock"></span>
                    </div>
                    <div className={"map_top_mid"}>运营数据监控系统</div>
                    <div className={"map_top_right"}>

                        {
                            <div onClick={() => this.handleRouterDetail()}>返回</div>
                        }

                        { //有home代表在后台里面，这里不显示 先退出全屏 进入后台按钮
                            !isInner && <div onClick={() => this.exitFullscreen("/home/setMap")}>进入后台</div>
                        }
                        { //有home代表在后台里面，这里显示放大全屏
                            isInner && <div onClick={()=>this.launchFullscreen('/setMap')}>全屏展示</div>
                        }
                    </div>
                </div>
                <div className={"map_body"}>
                    <div className={"map_titles"}>
                    <div style={{display: showElem ? 'block' : 'none'}}>
                            <div className={"content3 flex_box"}>
                                <div className={"pop"} style={{display: smoke_info.status===2 ? 'block' : 'none'}}>
                                    <img className={"image_close"} onClick={() => this.closeWarning()} src={[require("../../assets/close.png")]} alt=""/>
                                    <p style={{"fontWeight":"bolder"}}>警报中</p>
                                    <p>系统检测到烟雾，请及时现场查看</p>
                                    <p className={"bor_bottom"}></p>
                                    <p>{this.state.smoke_info.address}</p>
                                    <p>{this.state.smoke_info.evse_no}</p>
                                </div>
                                <div className={"flex_box record bottom"} id="review_box" style={{display: smoke_info.status===2 ? 'block' : 'none'}}>
                                    <ul id="comment1" className={"review_box_ul"}>
                                        {
                                            this.state.smoke_list.map((item, index) => {
                                                return <li key = {index + item} className={item.status==2?"red":''}>警报： {item.address} 烟雾报警 {item.created_at} {item.status==2?'警报':'已解除'}</li>
                                            })
                                        }
                                    </ul>
                                    <ul id="comment2" className={"review_box_ul"}></ul>
                                </div>
                                {/* 历史报警显示固定显示 */}
                                <div className={"flex_box record font_weight"} id="review_box2" style={{display:this.state.smoke_list_history.length>0?'block':'none'}}>
                                    <ul id="comment3" className={"review_box_ul"}>
                                        {
                                            this.state.smoke_list_history.map((item, index) => {
                                                return <li key = {index + item} className={item.status==2?"red":''}>警报： {item.address} {item.smoke_name} {item.times}次 {item.updated_at.substring(0,10)} {item.status==2?'警报':'已处理'}</li>
                                            })
                                        }
                                    </ul>
                                    <ul id="comment4" className={"review_box_ul"}></ul>
                                </div>
                            </div>
                        </div>
                        <div id={this.state.gaodeMapId}>
                            <GaodeMap alertData={this.state.alertData} width={this.state.gaodeMapWidth}  height={this.state.gaodeMapHeight}  evseData={this.state.evseData} ref="myMap"/>
                        </div>

                    </div>

                </div>
                <audio className={"audio_warn"} loop>
                    <source src={require("../../assets/audio/alert.mp3")} type="audio/ogg" />
                    <track kind="captions" />
                    您的浏览器不支持 audio 元素。
                </audio>
                <iframe className={"iframe"} style={{display:"none"}} src={require("../../assets/audio/null.mp3")}></iframe>
            </div>
        )
    }

    componentDidMount() {
        document.addEventListener("fullscreenchange", this.fullscreenchange, false);
        //moz下监听 mozfullscreenchange 事件，判断document.mozFullScreen属性
        document.addEventListener("mozfullscreenchange", this.mozfullscreenchange, false);
        //chrome下监听webkitfullscreenchange事件，判断 document.webkitIsFullScreen 属性
        document.addEventListener("webkitfullscreenchange", this.webkitfullscreenchange, false);
        this.setState({
            "timeInterval": setInterval(() => {
                this.showTime();
            }, 1000)
        })
        this.setState({
            "refresh": setInterval(() => {
                this.getEvseList()
            }, 300000)
        })
        this.getEvseList();
        this.getWarningInfo();
        //报警已处理列表
        this.getWarningCostList();


        window.Echo.private('prism.'+$util.getCookie("web_app_id"))
            .listen('WarningEvent', (e) => {
                if($util.getCookie("is_public") !=1){
                    let data  = e.object;
                    this.setState({ "smoke_info": data });
                    //console.log(e)
                    let audio = document.getElementsByClassName('audio_warn')[0];
                    //调用方法
                    if(data.status==2  && audio !=undefined){
                        if(audio.paused){
                            audio.load();
                        }
                        audio.play();
                    }
                }


            }).listen('WarningListEvent', (e) => {
                let data  = e.object;
                this.setState({ "smoke_list": data });
            })


    }
    handleRouterDetail = (path) => {
        const { isInner } = this.state;
        let is_public = $util.getCookie("is_public");

        if(!isInner){
            if(is_public==1){
                path = "operationalData"
            }else{
                path = "intelligentCommunity";
            }

        }else{
            if(is_public==1){
                path = "home/operationalData"
            }else{
                path = "home/intelligentCommunity";
            }
        }


        this.props.history.push({
            pathname: `/${path}`
        });
        this.close();
    };
    closeWarning(){
        let audio = document.getElementsByClassName('audio_warn')[0];
        if(audio != undefined && audio.played){
            audio.pause();
        }
        this.setState({ "smoke_info": {} });
        this.getWarningCostList();
    }
    // 警报弹窗
    roll(t) {
        var ul1 = document.getElementById("comment1");
        var ul2 = document.getElementById("comment2");
        var ul3 = document.getElementById("comment3");
        var ul4 = document.getElementById("comment4");
        var ulbox = document.getElementById("review_box");
        ul2.innerHTML = ul1.innerHTML;
        ul4.innerHTML = ul3.innerHTML;
        ulbox.scrollTop = 0; // 开始无滚动时设为0
        //console.log(this.timer)
        this.timer = setInterval(this.rollStart, t); // 设置定时器，参数t用在这为间隔时间（单位毫秒），参数t越小，滚动速度越快
    }
    close(){
        clearInterval(this.timer);
    }
       // 开始滚动函数
    rollStart() {
        // 上面声明的DOM对象为局部对象需要再次声明
        var ul1 = document.getElementById("comment1");
        var ul2 = document.getElementById("comment3");
        var ulbox = document.getElementById("review_box");
        var ulbox2 = document.getElementById("review_box2");
        // 正常滚动不断给scrollTop的值+1,当滚动高度大于列表内容高度时恢复为0
        if (ulbox !== null && ul1 !== null && ulbox2 !== null && ul2 !== null) {
            if (ulbox.scrollTop >= ul1.scrollHeight) {
             ulbox.scrollTop = 0;
            } else {
             ulbox.scrollTop++;
            }
            if (ulbox2.scrollTop >= ul2.scrollHeight) {
             ulbox2.scrollTop = 0;
            } else {
             ulbox2.scrollTop++;
            }
        }
    }
    //获取地图桩列表信息
    getEvseList() {
        axios.ajaxGet({
            url: "/getEvseList"
        }).then((res) => {
            if (res.code == 200) {
                this.setState({ "evseData": res.data });
                if(this.refs.myMap){
                    this.refs.myMap.clusterMarker();
                }

            }
        }, true)
    }
    getWarningInfo(){
        axios.ajaxGet({
            url: "/getWarningInfo"
        }).then((res) => {
            if (res.code == 200) {
                this.roll(200);
                this.setState({ "smoke_list": res.data });
            }
        }, true)
    }
    //烟感报警已处理列表
    getWarningCostList(){
        var user_id = $util.getCookie("web_app_id")
        //console.log(user_id)
        axios.ajaxPost({
            url: "/getWarningCostList",
            data: {
                user_id: user_id
            }
        }, true).then((res) => {
            console.log(res)
            if(res.code == 200){
                this.setState((state) => {
                    return { smoke_list_history: state.smoke_list_history.concat(res.data)};
                });
            }
        })
    }

    showTime() {
        //创建Date对象
        var today = new Date();
        var year = today.getFullYear();
        var month = today.getMonth() + 1;
        var day = today.getDate();
        var hours = today.getHours();
        var minutes = today.getMinutes();
        var seconds = today.getSeconds();
        month = month < 10 ? "0" + month : month;
        day = day < 10 ? "0" + day : day;
        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        var str = hours + ":" + minutes + ":" + seconds + "   " + year + "-" + month + "-" + day;
        document.getElementById("timeClock").innerText = str;
    }

    componentWillUnmount() {
        clearInterval(this.state.timeInterval);
        clearInterval(this.state.refresh);
        document.removeEventListener("fullscreenchange", this.fullscreenchange, false);
//moz下监听 mozfullscreenchange 事件，判断document.mozFullScreen属性
        document.removeEventListener("mozfullscreenchange", this.mozfullscreenchange, false);
//chrome下监听webkitfullscreenchange事件，判断 document.webkitIsFullScreen 属性
        document.removeEventListener("webkitfullscreenchange", this.webkitfullscreenchange, false);
    }
}

export default setMap;