import React, { Component } from "react";
import MenuConfig from "@/config/menuConfig";
import { Menu, Icon, Layout } from 'antd';
import { NavLink } from 'react-router-dom';
import $util from "@/utils/utils.js";
import './index.less';
const SubMenu = Menu.SubMenu;
const {
    Sider
} = Layout;

class NavLeft extends Component {
    constructor(props) {
        super(props);
        //"common,user,operate,smart_community,system"
        // let permsession_module_name = $util.getCookie("permsession_module_name") ? Object.keys(JSON.parse($util.getCookie("permsession_module_name"))) : "";
        let permsession_module_name = $util.getCookie("permsession_module") ? $util.getCookie("permsession_module").split(",") : "";
        let roleName = $util.getCookie("role_name");
        let userName = $util.getCookie("login_name");
        let is_pts_user = userName=="江阴电信"?true:false
        // console.log(permsession_module_name);
        this.state = {
            isHome: permsession_module_name.includes("operate") || permsession_module_name.includes("smart_community"),
            isUser: permsession_module_name.includes("user"),
            isDevice: permsession_module_name.includes("user"),
            isSeting: permsession_module_name.includes("system"),
            isAlarm:permsession_module_name.includes("user"),
            current: "1",
            collapsed: false,
            roleName: roleName,
            is_public:$util.getCookie("is_public"),
            is_pts_user:is_pts_user
        }
    }
    componentDidMount() {


    }
    componentWillMount() {

    }


    onCollapse = (collapsed) => {
        // console.log(collapsed);
        this.setState({ collapsed });
    }

    handleClick = (e) => {
        // console.log('click ', e);
        this.setState({
            current: e.key,
        });
    }
    goTo(path){       
        this.props.history.push(path)
    }
    render() {
        const { isHome, isUser, isSeting, isDevice,is_public,isAlarm,roleName,is_pts_user } = this.state;
        const url = is_public?'/home/operationalData':'/home/IntelligentCommunity';
        return (
            <div>
                <Sider
                    collapsible
                    collapsed={this.state.collapsed}
                    onCollapse={this.onCollapse}
                    style={{height:'100%'}}
                >
                    {
                        is_pts_user &&<div className="logo">
                        <img src={require("../../assets/images/pts.png")} alt="" />
                    </div>
                    }
                    {
                        !is_pts_user&&<div className="logo_pts">
                          <img src={require("../../assets/newlogobyred.png")} alt="" />
                       </div>
                    }

                    <Menu theme="dark"
                        mode="inline"
                        onClick={this.handleClick}
                        defaultOpenKeys={['sub1']}
                        selectedKeys={[this.state.current]}
                    >
                        {/* {this.state.mentTreeNode} */}
                        {
                            isHome && <Menu.Item key="{url}" onClick={()=>this.goTo(url)}>
                                <Icon type="home" />
                                <span >首页</span>
                                {/* <NavLink to="/home/operationalData"> <Icon type="home" />首页</NavLink> */}
                            </Menu.Item>
                        }
                        {
                            roleName !=='商户' &&isUser && <SubMenu title={<span><Icon type="user" /><span>用户管理</span></span>} key="sub2">
                                <Menu.Item key="/user" onClick={()=> this.goTo("/user")}>
                                    <Icon type="user" />
                                    <span >用户一览</span>
                                </Menu.Item>
                            </SubMenu>
                        }
                        {
                            roleName ==='管理员' && isSeting && <Menu.Item key="/setting" onClick={()=> this.goTo("/setting")}>
                                <Icon type="setting" />
                                <span >系统设置</span>
                            </Menu.Item>
                        }
                        {
                            (roleName ==='管理员' || roleName ==='代理商'|| roleName ==='城市合伙人') && isDevice && <Menu.Item key="/device" onClick={()=> this.goTo("/device")}>
                                <Icon type="tool" />
                                <span >烟感设备</span>
                            </Menu.Item>
                        }
                        {
                            (roleName ==='管理员' || roleName ==='代理商' || roleName ==='城市合伙人') && isAlarm && <Menu.Item key="/alarmLog" onClick={()=> this.goTo("/alarmLog")}>
                                <Icon type="tool" />
                                <span >报警日志</span>
                            </Menu.Item>
                        }
                        {
                            (roleName ==='管理员' || roleName ==='代理商' || roleName ==='城市合伙人') && isAlarm && is_pts_user && <Menu.Item key="/video" onClick={()=> this.goTo("/video")}>
                                <Icon type="video-camera" />
                                <span >监控视频</span>
                            </Menu.Item>
                        }
                    </Menu>
                </Sider>

            </div>
        )
    }
}

export default NavLeft;