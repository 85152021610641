import React,{ Component } from "react";

class IntelligentCommunity extends Component{
    render(){
        return (
            <div>
                这是 图片设置
            </div>
        )
    }
}

export default IntelligentCommunity;